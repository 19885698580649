import { AxiosInstance } from "axios"
import { AlertType, Settings, Badge, QuantityDiscount } from "./../types"
import { notifyUser } from "./../state"

export const createGeneralFetch = (
  axios: AxiosInstance,
  dispatch: Function
) => {
  return {
    getSettings: async () => {
      return await axios
        .get<Settings>("/general/all")
        .then((response) => {
          return response.data as Settings
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading settings. Please try it later.",
            })
          )
          console.error("Settings loading error.", e)
          throw e
        })
    },
    listBadges: async () => {
      return await axios
        .get<Badge[]>("/badge/desktop/")
        .then((response) => {
          return response.data as Badge[]
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading badges. Please try it later.",
            })
          )
          console.error("Badge loading error.", e)
          throw e
        })
    },
    listDiscounts: async () => {
      return await axios
        .get<QuantityDiscount[]>("/quantity-discount//")
        .then((response) => {
          return response.data as QuantityDiscount[]
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading discounts. Please try it later.",
            })
          )
          console.error("Discount loading error.", e)
          throw e
        })
    },
  }
}
