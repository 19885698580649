import React from "react"
import Popper from "@mui/material/Popper"
import Fade from "@mui/material/Fade"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import { Trans } from "@lingui/macro"
import { v4 as uuid } from "uuid"
import styled from "styled-components"
import { PriceSummaryV2, CartCard, GoldButton, GoldDivider } from "./"
import { OrderedItem } from "./../types"

const PopperBox = styled.div`
  width: calc(100% - 16px);
  max-height: 300px;
  overflow-y: auto;
  margin: 8px;
  padding-right: 8px;
`

const EmptyCart = styled.div`
  font-family: ${(props) => props.theme.fonts.family.regular};
  color: ${(props) => props.theme.colors.DARK_GOLD};
  font-size: ${(props) => props.theme.fonts.size.medium};
`

const StyledCard = styled(Card)`
  &.MuiCard-root {
    background-color: ${(props) => props.theme.colors.DARK_BLACK};
    color: ${(props) => props.theme.colors.DARK_GOLD};
  }
`

interface CartPopupProps {
  open: boolean
  anchorEl: any
  items: OrderedItem[]
  onItemChange: Function
  onItemRemove: Function
  onGoToCart: Function
  onGoToCheckout: Function
  onMouseEnter: Function
  onMouseLeave: Function
}

export const CartPopup: React.FunctionComponent<CartPopupProps> = ({
  open,
  anchorEl,
  items,
  onItemChange,
  onItemRemove,
  onGoToCart,
  onGoToCheckout,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Popper
      placement="bottom-end"
      anchorEl={anchorEl}
      transition
      open={open}
      style={{ zIndex: 1300 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div
            onMouseEnter={() => onMouseEnter()}
            onMouseLeave={() => onMouseLeave()}
          >
            <StyledCard sx={{ width: 600, maxHeight: 505 }}>
              <CardContent sx={{ padding: "4px" }}>
                <PopperBox>
                  {items.map((orderedItem: OrderedItem) => (
                    <CartCard
                      orderedItem={orderedItem}
                      key={uuid()}
                      onAmountChange={(amount: number) => {
                        if (amount !== orderedItem.orderedAmount) {
                          onItemChange(orderedItem, amount)
                        }
                      }}
                      onRemoveClick={(id: number) => onItemRemove(id)}
                    />
                  ))}
                  {items.length === 0 && (
                    <EmptyCart>
                      <Trans>Your shopping cart is empty!</Trans>
                    </EmptyCart>
                  )}
                </PopperBox>
                <PriceSummaryV2 orderedItems={items} />
                <GoldDivider />
              </CardContent>
              <CardActions>
                <GoldButton
                  onClick={() => onGoToCart()}
                  disabled={items.length === 0}
                >
                  <Trans>Go to cart</Trans>
                </GoldButton>
                <GoldButton
                  onClick={() => onGoToCheckout()}
                  disabled={items.length === 0}
                >
                  <Trans>Go to checkout</Trans>
                </GoldButton>
              </CardActions>
            </StyledCard>
          </div>
        </Fade>
      )}
    </Popper>
  )
}
