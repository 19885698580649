import React, { useEffect, useState, FunctionComponent } from "react"
import styled from "styled-components"
import { FlexColumnBox, FlexBox, size } from "./../styles"
import { lowestPrice, SettingsContext, getFormattedPrice } from "./../services"
import { OrderedItem } from "./../types"
import { Trans } from "@lingui/macro"
import {
  useAppSelector,
  selectDiscounts,
  selectCanApplyCategoryPromotion,
} from "./../state"

const PriceContainer = styled(FlexBox)`
  width: 100%;
  padding: 6px 12px;
  background-color: transparent;
  font-family: ${(props) => props.theme.fonts.family.regular};
`
const PriceColumnContainer = styled(FlexColumnBox)`
  flex-grow: 1;
  row-gap: 6px;
`
const PriceColumns = `
  align-items: baseline;
  justify-content: space-between;
  .first {
  }
  .second:not(.spaced) {
    font-weight: bold;
  }
`
const TotalPrice = styled(FlexBox)`
  ${PriceColumns}
  font-size: ${(props) => props.theme.fonts.size.medium};
  color: ${(props) => props.theme.colors.white};

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.small};
  }

  .first {
    font-family: ${(props) => props.theme.fonts.family.bold};
  }

  .second {
    font-size: ${(props) => props.theme.fonts.size.small};
    font-family: ${(props) => props.theme.fonts.family.bold};

    @media only screen and (min-width: ${size.tablet}px) {
      font-size: ${(props) => props.theme.fonts.size.small};
    }
  }
`
const Discount = styled(TotalPrice)`
  color: ${(props) => props.theme.colors.DARK_GOLD};
  font-size: ${(props) => props.theme.fonts.size.small};

  .first {
    font-family: ${(props) => props.theme.fonts.family.regular};
  }

  .second {
    font-size: ${(props) => props.theme.fonts.size.extraSmall};
    font-family: ${(props) => props.theme.fonts.family.regular};

    @media only screen and (min-width: ${size.tablet}px) {
      font-size: ${(props) => props.theme.fonts.size.small};
    }
  }
`
interface PriceSummaryProps {
  orderedItems: OrderedItem[]
}
export const PriceSummaryV2: FunctionComponent<PriceSummaryProps> = ({
  orderedItems,
}) => {
  const { settings } = React.useContext(SettingsContext)
  const canApplyUserCategoryPromotion = useAppSelector(
    selectCanApplyCategoryPromotion
  )
  const [price, setPrice] = useState({
    totalPrice: 0,
    discountPrice: 0,
    discount: 0,
  })
  const discounts = useAppSelector(selectDiscounts)

  useEffect(() => {
    let promotedPrice = orderedItems
      .map((orderedItem) => {
        const discount = orderedItem.item.discount
        const price = orderedItem.item.price
        return lowestPrice(price, discount || price) * orderedItem.orderedAmount
      })
      .reduce((accumulator, curr) => accumulator + curr, 0)
    const totalPrice = orderedItems
      .map((orderedItem) => {
        const price = orderedItem.item.price
        return price * orderedItem.orderedAmount
      })
      .reduce((accumulator, curr) => accumulator + curr, 0)
    if (!canApplyUserCategoryPromotion) {
      const quantityDiscount =
        (discounts.find((discount) => discount.applyFrom <= totalPrice)
          ?.discount || 0) / 100
      const quantityDiscountPrice = Math.round(
        totalPrice * (1 - quantityDiscount)
      )
      promotedPrice = lowestPrice(
        promotedPrice,
        lowestPrice(totalPrice, quantityDiscountPrice)
      )
    }
    setPrice({
      totalPrice,
      discountPrice: promotedPrice,
      discount: totalPrice - promotedPrice,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedItems])
  return (
    <PriceContainer>
      <PriceColumnContainer>
        <TotalPrice>
          <div className="first">
            <Trans>Subtotal:</Trans>
          </div>{" "}
          <div className="second">
            {getFormattedPrice(price.discountPrice, settings)}
          </div>
        </TotalPrice>
        <Discount>
          <div className="first">
            <Trans>Discount:</Trans>
          </div>{" "}
          <div className="second">
            {price.discount > 0
              ? getFormattedPrice(price.discount, settings)
              : 0}
          </div>
        </Discount>
      </PriceColumnContainer>
    </PriceContainer>
  )
}
