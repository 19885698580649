import React from "react"
import styled from "styled-components"
import { Button, size } from "./../../styles"
import {
  Container,
  TitleRow,
  Divider,
  ProfileEditRow,
} from "../../styles/profile"
import { Trans } from "@lingui/macro"
import Typography from "@mui/material/Typography"
import ResetPasswordForm from "./../../components/profile/ResetPasswordForm"
import {
  emptyResetPassword,
  validatePasswordReset,
  ResetPassword as ResetPasswordType,
  AlertType,
} from "./../../types"
import { useFormik } from "formik"
import { useLingui } from "@lingui/react"
import Box from "@mui/material/Box"
import MediaQuery from "react-responsive"
import { useNavigate, useParams } from "react-router-dom"
import { routes } from "./../../Routes"
import { HttpContext, submitLogin } from "./../../services"
import { useAppDispatch, notifyUser } from "./../../state"

const Form = styled.form`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

export const ResetPassword = () => {
  const { i18n } = useLingui()
  const navigate = useNavigate()
  const { token, email } = useParams()
  const fetch = React.useContext(HttpContext)
  const dispatch = useAppDispatch()

  const onSubmit = (values: ResetPasswordType) => {
    fetch?.profile
      .resetPasswordWithToken(values)
      .then((result: boolean) => {
        if (result) {
          submitLogin(
            { email, password: values.newPassword },
            dispatch,
            fetch,
            navigate
          )
        }
        navigate(routes.login.path)
      })
      .catch(() =>
        dispatch(
          notifyUser({
            type: AlertType.ERROR,
            message: "We couldn't change your password. Please try it again!",
          })
        )
      )
  }

  const formik = useFormik({
    initialValues: emptyResetPassword as ResetPasswordType,
    onSubmit,
    validate: (values: ResetPasswordType) =>
      validatePasswordReset(values, i18n),
    validateOnChange: false,
    validateOnBlur: false,
  })

  const newPassword = React.useMemo(
    () => formik.values.newPassword,
    [formik.values.newPassword]
  )
  const confirmPassword = React.useMemo(
    () => formik.values.confirmPassword,
    [formik.values.confirmPassword]
  )
  const newPasswordError = React.useMemo(
    () => formik.errors?.newPassword,
    [formik.errors?.newPassword]
  )
  const confirmPasswordError = React.useMemo(
    () => formik.errors?.confirmPassword,
    [formik.errors?.confirmPassword]
  )

  React.useEffect(() => {
    if (token) {
      formik.setFieldValue("token", token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flexStart",
            width: "100%",
          }}
        >
          <TitleRow>
            <Typography component="h5" className="title">
              <Trans>Password reset</Trans>
            </Typography>
          </TitleRow>
          <Divider />
          <ProfileEditRow>
            <ResetPasswordForm
              newPassword={newPassword}
              confirmPassword={confirmPassword}
              setFieldValue={formik.setFieldValue}
              newPasswordError={newPasswordError}
              confirmPasswordError={confirmPasswordError}
            />
          </ProfileEditRow>
          <TitleRow>
            <Typography component="p" variant="subtitle1" className="subtitle">
              <Trans>
                After you've reset your password, you'll be redirected to login
                page!
              </Trans>
            </Typography>
          </TitleRow>
          <MediaQuery minWidth={size.tablet}>
            <Button type="submit" className="margin">
              Save
            </Button>
          </MediaQuery>
          <MediaQuery maxWidth={size.tablet}>
            <Button type="submit" className="full-width margin">
              Save
            </Button>
          </MediaQuery>
        </Box>
      </Form>
    </Container>
  )
}
