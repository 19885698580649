import {
  signIn,
  notifyUser,
  registerAddress,
  registerInvoice,
  clearOrder,
} from "./../state"
import { CustomerDetails, AlertType, Address, VatAddress } from "./../types"
import { routes } from "./../Routes"

export const submitLogin = (
  values: any,
  dispatch: any,
  fetch: any,
  navigate: any
) => {
  fetch?.profile
    .login(values.email, values.password)
    .then(() => {
      fetch?.customer
        .get(0)
        .then((customer: CustomerDetails) => {
          dispatch(signIn(customer))
          dispatch(
            notifyUser({
              type: AlertType.SUCCESS,
              message: "Login succeed",
            })
          )
          if (customer && customer.id && customer.email) {
            dispatch(signIn(customer))
            dispatch(clearOrder())

            fetch?.customer
              .getAddress(customer.id)
              .then((address: Address | null) => {
                if (address && address.id) {
                  dispatch(registerAddress(address))
                }
              })

            fetch?.customer
              .getInvoice(customer.id)
              .then((invoice: VatAddress | null) => {
                if (invoice && invoice.id) {
                  dispatch(registerInvoice(invoice))
                }
              })
          }
          navigate(routes.profile.path)
        })
        .catch(() =>
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message: "Login failed. ",
            })
          )
        )
    })
    .catch(() =>
      dispatch(
        notifyUser({
          type: AlertType.ERROR,
          message: "Login failed. ",
        })
      )
    )
}
