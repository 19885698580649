import React from "react"
import styled from "styled-components"
import { FlexColumnBox, size, FlexBox } from "./../styles"
import { useNavigate } from "react-router-dom"
import {
  CartCard,
  BackButton,
  PriceSummaryV2,
  GoldButton,
} from "./../components"
import { GoldDivider } from "../components/GoldDivider"
import { v4 as uuid } from "uuid"
import {
  useAppSelector,
  selectOrder,
  useAppDispatch,
  removeItemFromOrder,
  updateItemInOrder,
} from "./../state"
import { Order, OrderedItem } from "./../types"
import { routes } from "./../Routes"
import { Trans } from "@lingui/macro"
import MediaQuery from "react-responsive"

const PageContainer = styled(FlexColumnBox)`
  padding: 8px 27px;
  height: calc(100% - ${(props) => props.theme.menu.height.mobile});
`

const DesktopPageContainer = styled(FlexBox)`
  margin: 0 auto;
  width: ${(props) => props.theme.window.desktop};
  min-height: calc(100% - ${(props) => props.theme.menu.height.desktop});
  padding: 20px;
`

const CartContainer = styled(FlexColumnBox)`
  width: 100%;
  margin: 0 auto;
  padding-top: 4px;
  height: calc(100% - 10px);

  @media only screen and (min-width: ${size.tablet}px) {
    width: 65%;
    max-width: 860px;
  }
`
const CartBody = styled(FlexColumnBox)`
  overflow-y: auto;
  padding: 0 8px;
  align-items: center;
  height: calc(100% - 145px);
  row-gap: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`
const Footer = styled.div`
  position: absolute;
  bottom: 30px;
  width: calc(100% - 54px);
`

const DesktopPriceBox = styled(FlexColumnBox)`
  width: 35%;
  max-width: 600px;
`

const EmptyCart = styled.div`
  font-family: ${(props) => props.theme.fonts.family.regular};
  color: ${(props) => props.theme.colors.DARK_GOLD};
  font-size: ${(props) => props.theme.fonts.size.medium};
`

const Divider = styled(GoldDivider)`
  margin-bottom: 8px;
`

export const Cart = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const order: Order = useAppSelector(selectOrder)

  return (
    <>
      <MediaQuery maxWidth={size.tablet}>
        <PageContainer>
          <CartContainer>
            <BackButton />
            <CartBody>
              {order.items.map((orderedItem: OrderedItem) => (
                <CartCard
                  orderedItem={orderedItem}
                  key={uuid()}
                  onAmountChange={(amount: number) => {
                    if (amount !== orderedItem.orderedAmount) {
                      dispatch(
                        updateItemInOrder({
                          ...orderedItem,
                          orderedAmount: amount,
                        })
                      )
                    }
                  }}
                  onRemoveClick={(id: number) =>
                    dispatch(removeItemFromOrder(id))
                  }
                />
              ))}
            </CartBody>
            <Footer>
              <PriceSummaryV2 orderedItems={order.items} />
              <Divider />
              <GoldButton onClick={() => navigate(routes.checkout.path)}>
                <Trans>Check Out</Trans>
              </GoldButton>
            </Footer>
          </CartContainer>
        </PageContainer>
      </MediaQuery>
      <MediaQuery minWidth={size.tablet}>
        <DesktopPageContainer>
          <CartContainer>
            <CartBody>
              {order.items.map((orderedItem: OrderedItem) => (
                <CartCard
                  orderedItem={orderedItem}
                  key={uuid()}
                  onAmountChange={(amount: number) => {
                    if (amount !== orderedItem.orderedAmount) {
                      dispatch(
                        updateItemInOrder({
                          ...orderedItem,
                          orderedAmount: amount,
                        })
                      )
                    }
                  }}
                  onRemoveClick={(id: number) =>
                    dispatch(removeItemFromOrder(id))
                  }
                />
              ))}
              {order.items.length === 0 && (
                <EmptyCart>
                  <Trans>Your shopping cart is empty!</Trans>
                </EmptyCart>
              )}
            </CartBody>
          </CartContainer>
          <DesktopPriceBox>
            <PriceSummaryV2 orderedItems={order.items} />
            <GoldButton
              onClick={() => navigate(routes.checkout.path)}
              disabled={order.items.length === 0}
            >
              <Trans>Check Out</Trans>
            </GoldButton>
          </DesktopPriceBox>
        </DesktopPageContainer>
      </MediaQuery>
    </>
  )
}
