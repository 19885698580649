import { AxiosInstance } from "axios"
import {
  ItemPreview,
  AlertType,
  CustomerCategory,
  QuantityDiscount,
  General,
  Settings,
  RegionPreview,
  Manufacturer,
  MarketingWelcome,
  MarketingMediaMessages,
  MarketingMessage,
  Region,
  MarketingMessageByLanguage,
  Language,
  MarketingImage,
} from "./../types"
import { notifyUser } from "./../state"

export const createRegionFetch = (axios: AxiosInstance, dispatch: Function) => {
  return {
    listRegionPreviews: async () => {
      return await axios
        .get<RegionPreview[]>("/region/active/")
        .then((response) => {
          return response.data as RegionPreview[]
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading region previews. Please try it later.",
            })
          )
          console.error("Region previews loading error.", e)
          throw e
        })
    },
    listLanguages: async (regionId: number) => {
      return await axios
        .get<Language[]>(`/region/${regionId}/languages/`)
        .then((response) => {
          return response.data as Language[]
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading languages. Please try it later.",
            })
          )
          console.error("Languages loading error.", e)
          throw e
        })
    },
    getRegion: async (regionId: number) => {
      return await axios
        .get<Region>(`/region/${regionId}/`)
        .then((response) => {
          return response.data as Region
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading region. Please try it later.",
            })
          )
          console.error("Region loading error.", e)
          throw e
        })
    },
    listItems: async (regionId: number) => {
      return await axios
        .get<ItemPreview[]>(`/region/${regionId}/items/`)
        .then((response) => {
          return response.data as ItemPreview[]
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading items. Please try it later.",
            })
          )
          console.error("Item loading error.", e)
          throw e
        })
    },
    listCategories: async (regionId: number) => {
      return await axios
        .get<CustomerCategory[]>(`/region/${regionId}/customer-category/`)
        .then((response) => {
          return response.data as CustomerCategory[]
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading categories. Please try it later.",
            })
          )
          console.error("Category loading error.", e)
          throw e
        })
    },
    listDiscounts: async (regionId: number) => {
      return await axios
        .get<QuantityDiscount[]>(`/region/${regionId}/quantity-discount/`)
        .then((response) => {
          return response.data as QuantityDiscount[]
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading discounts. Please try it later.",
            })
          )
          console.error("Discount loading error.", e)
          throw e
        })
    },
    getGeneral: async (regionId: number) => {
      return await axios
        .get<General>(`/region/${regionId}/settings/`)
        .then((response) => {
          return response.data as General
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading general. Please try it later.",
            })
          )
          console.error("General loading error.", e)
          throw e
        })
    },
    getSettings: async (regionId: number) => {
      return await axios
        .get<Settings>(`/region/${regionId}/settings/all/user/`)
        .then((response) => {
          return response.data as Settings
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading settings. Please try it later.",
            })
          )
          console.error("Settings loading error.", e)
          throw e
        })
    },
    listManufacturers: async (regionId: number) => {
      return await axios
        .get<Manufacturer[]>(`/region/${regionId}/manufacturer/`)
        .then((response) => {
          return response.data as Manufacturer[]
        })
        .catch((e) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading manufacturers. Please try it later.",
            })
          )
          console.error("Manufacturer loading error.", e)
          throw e
        })
    },
    getMarketingHome: (regionId: number) =>
      axios
        .get<MarketingWelcome>(`/region/${regionId}/marketing/home/`)
        .then((response: any) => {
          return response.data as MarketingWelcome
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing welcome. Please try it later.",
            })
          )
          console.error("Marketing welcome loading error.", e)
          throw e
        }),
    getWelcome: (regionId: number) =>
      axios
        .get<MarketingWelcome>(`/region/${regionId}/marketing/welcome/`)
        .then((response: any) => {
          return response.data as MarketingWelcome
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing welcome. Please try it later.",
            })
          )
          console.error("Welcome loading error.", e)
          throw e
        }),
    listMarketingHomeImageMessages: (regionId: number) =>
      axios
        .get<MarketingMediaMessages[]>(
          `/region/${regionId}/marketing/home/image-messages/`
        )
        .then((response: any) => {
          return response.data as MarketingMediaMessages[]
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing media messages. Please try it later.",
            })
          )
          console.error("Marketing media messages loading error.", e)
          return []
        }),
    getMarketingAboutUs: (regionId: number) =>
      axios
        .get<MarketingMessageByLanguage>(
          `/region/${regionId}/marketing/about-us/by-language/`
        )
        .then((response: any) => {
          return response.data as MarketingMessageByLanguage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing about us. Please try it later.",
            })
          )
          console.error("Marketing about us loading error.", e)
          throw e
        }),
    getMarketingContacts: (regionId: number) =>
      axios
        .get<MarketingMessageByLanguage>(
          `/region/${regionId}/marketing/contacts/by-language/`
        )
        .then((response: any) => {
          return response.data as MarketingMessageByLanguage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing contacts. Please try it later.",
            })
          )
          console.error("Marketing contacts loading error.", e)
          throw e
        }),
    getMarketingTerms: (regionId: number) =>
      axios
        .get<MarketingMessageByLanguage>(
          `/region/${regionId}/marketing/terms/by-language/`
        )
        .then((response: any) => {
          return response.data as MarketingMessageByLanguage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing terms. Please try it later.",
            })
          )
          console.error("Marketing terms loading error.", e)
          throw e
        }),
    getMarketingPolicy: (regionId: number) =>
      axios
        .get<MarketingMessageByLanguage>(
          `/region/${regionId}/marketing/policy/by-language/`
        )
        .then((response: any) => {
          return response.data as MarketingMessageByLanguage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing policy. Please try it later.",
            })
          )
          console.error("Marketing policy loading error.", e)
          throw e
        }),
    getMarketingPayment: (regionId: number) =>
      axios
        .get<MarketingMessageByLanguage>(
          `/region/${regionId}/marketing/payment/by-language/`
        )
        .then((response: any) => {
          return response.data as MarketingMessageByLanguage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing payment. Please try it later.",
            })
          )
          console.error("Marketing payment loading error.", e)
          throw e
        }),
    getMarketingDelivery: (regionId: number) =>
      axios
        .get<MarketingMessageByLanguage>(
          `/region/${regionId}/marketing/delivery/by-language/`
        )
        .then((response: any) => {
          return response.data as MarketingMessageByLanguage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing delivery. Please try it later.",
            })
          )
          console.error("Marketing delivery loading error.", e)
          throw e
        }),
    getMarketingPaymentCard: (regionId: number) =>
      axios
        .get<MarketingMessage>(`/region/${regionId}/marketing/card/`)
        .then((response: any) => {
          return response.data as MarketingMessage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing payment with card. Please try it later.",
            })
          )
          console.error("Marketing payment with card loading error.", e)
          throw e
        }),
    getMarketingPaymentOnDelivery: (regionId: number) =>
      axios
        .get<MarketingMessage>(`/region/${regionId}/marketing/on-delivery/`)
        .then((response: any) => {
          return response.data as MarketingMessage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing payment with on delivery. Please try it later.",
            })
          )
          console.error("Marketing payment with on delivery loading error.", e)
          throw e
        }),
    getMarketingPaymentBankTransfer: (regionId: number) =>
      axios
        .get<MarketingMessage>(`/region/${regionId}/marketing/bank-transfer/`)
        .then((response: any) => {
          return response.data as MarketingMessage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing payment with bank transfer. Please try it later.",
            })
          )
          console.error(
            "Marketing payment with bank transfer loading error.",
            e
          )
          throw e
        }),
    getMarketingPaymentQr: (regionId: number) =>
      axios
        .get<MarketingMessage>(`/region/${regionId}/marketing/qr/`)
        .then((response: any) => {
          return response.data as MarketingMessage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing payment with qr. Please try it later.",
            })
          )
          console.error("Marketing payment with qr loading error.", e)
          throw e
        }),
    listWelcomeImages: (regionId: number, isDesktop: boolean) =>
      axios
        .get<MarketingImage[]>(
          `/region/${regionId}/welcome-images/${
            isDesktop ? "desktop" : "mobile"
          }/`
        )
        .then((response: any) => {
          return response.data as MarketingImage[]
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing images. Please try it later.",
            })
          )
          console.error("Marketing images loading error.", e)
          throw e
        }),
  }
}
