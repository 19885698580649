import React from "react"
import GoldInput from "./GoldInput"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import IconButton from "@mui/material/IconButton"
import { theme } from "../styles"

interface GoldPasswordInputProps {
  placeholder?: string
  value?: string | number
  onBlur: Function
  error?: string | undefined | null
}

export const GoldPasswordInput: React.FC<GoldPasswordInputProps> = ({
  placeholder,
  value,
  onBlur,
  error,
}) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <GoldInput
      placeholder={placeholder}
      error={error}
      value={value}
      onBlur={onBlur}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOff style={{ color: theme.colors.DARK_GOLD }} />
              ) : (
                <Visibility style={{ color: theme.colors.DARK_GOLD }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default GoldPasswordInput
