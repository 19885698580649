import { AxiosInstance } from "axios"
import {
  Order,
  AlertType,
  OrderedItem,
  OrderSummary,
  emptyOrder,
  RegionOrder,
} from "./../types"
import { notifyUser } from "./../state"

export const createOrderFetch = (axios: AxiosInstance, dispatch: Function) => {
  return {
    save: async (order: Order) => {
      const newOrder: Order = { ...order }
      newOrder.items = newOrder.items.map((orderedItem: OrderedItem) => {
        const newOrderedItem = {
          ...orderedItem,
          item: { ...orderedItem.item, images: [] },
        }
        return newOrderedItem
      })
      return await axios.post(`/order/`, newOrder).catch((e: any) => {
        throw e
      })
    },
    listActive: (userId: number) =>
      axios
        .get<Order[]>(`customer/orders/active`)
        .then((response: any) => {
          return response.data as Order[]
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading active orders. Please try it later.",
            })
          )
          console.error("Active orders loading error.", e)
          return []
        }),
    listCompleted: (userId: number) =>
      axios
        .get<Order[]>(`customer/orders/completed`)
        .then((response: any) => {
          return response.data as Order[]
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading completed orders. Please try it later.",
            })
          )
          console.error("Completed orders loading error.", e)
          return []
        }),
    listAll: (userId: number) =>
      axios
        .get<Order[]>(`customer/orders/all`)
        .then((response: any) => {
          return response.data as Order[]
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading orders. Please try it later.",
            })
          )
          console.error("Orders loading error.", e)
          return []
        }),
    listSummary: () =>
      axios
        .get<OrderSummary[]>(`/order/summary`)
        .then((response: any) => {
          return response.data as OrderSummary[]
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading order summaries. Please try it later.",
            })
          )
          console.error("Order summary loading error.", e)
          return []
        }),
    get: (id: number) =>
      axios
        .get<Order>(`/order/${id}`)
        .then((response: any) => {
          return response.data as Order
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading order. Please try it later.",
            })
          )
          console.error("Order loading error.", e)
          return emptyOrder as Order
        }),
    checkPrice: async (order: RegionOrder) => {
      const priceCheckObject = {
        userId: order.customer.id > 0 ? order.customer.id : undefined,
        deliveryId: order.delivery.id,
        totalPrice: order.totalPrice,
        regionId: order.regionId,
        items: order.items.map((orderedItem) => ({
          itemId: orderedItem.item.id,
          amount: orderedItem.orderedAmount,
        })),
      }
      return await axios
        .post(`/order/price-check`, priceCheckObject)
        .then((response) => response.data)
    },
  }
}
