import {
  Address,
  VatAddress,
  Customer,
  Payment,
  emptyPayment,
  Item,
  emptyAddress,
  emptyVatAddress,
  emptyCustomer,
  Delivery,
  emptyDelivery,
  QuantityDiscount,
} from "."

export interface OrderedItem {
  id: number
  item: Item
  orderedAmount: number
  totalPrice: number
}

export const emptyOrderStatus: Partial<OrderStatus> = {
  name: "New",
}

export interface OrderStatus {
  id: number
  name: string
  icon?: string
}

export interface Order {
  id: number
  orderNumber: string
  shipping: Address
  invoice: VatAddress
  customer: Customer
  totalPrice: number
  status: OrderStatus
  items: OrderedItem[]
  orderDate: string | number
  shippingDate?: string | number
  payment: Payment
  delivery: Delivery
  invoiceSameAsShipping: boolean
  conditionsConfirmed: boolean
  quantityDiscount?: QuantityDiscount
}

export interface RegionOrder extends Order {
  regionId: number
}

export const emptyOrder: Partial<Order> = {
  orderNumber: "",
  shipping: emptyAddress,
  invoice: emptyVatAddress,
  customer: emptyCustomer as Customer,
  totalPrice: 0,
  status: emptyOrderStatus as OrderStatus,
  items: [],
  orderDate: 0,
  shippingDate: 0,
  payment: emptyPayment as Payment,
  delivery: emptyDelivery as Delivery,
  invoiceSameAsShipping: true,
  conditionsConfirmed: false,
}

export enum OrderProcessStatus {
  NOT_STARTED,
  IN_PROGRESS,
  SUCCESS,
  FAILED,
}

export enum OrderPage {
  ACTIVE = "active",
  COMPLETED = "completed",
  ALL = "all",
}

export interface OrderSummary {
  id: number
  orderNumber: string
  totalPrice: number
  status: OrderStatus
  orderDate: string | number
}

export interface CheckoutFormProps extends Order {
  isSignUp: boolean
  password: string
  confirmPassword: string
}
