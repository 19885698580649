import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { Badge } from "./../types"
import { FlexBox, size } from "../styles"
import { v4 as uuid } from "uuid"
import { Trans } from "@lingui/macro"
import { ReactComponent as NewIcon } from "./../assets/new.svg"

const icon = `
  width: 25px;
  height: 20px;
  color: unset;
  background-color: unset;
`

const Container = styled(FlexBox)`
  column-gap: 26px;
  align-items: center;

  @media only screen and (min-width: ${size.tablet}px) {
    flex-direction: column;
    row-gap: 16px;
    position: absolute;
    right: 0;
    top: 0;
  }
`

const Image = styled.img`
  ${icon}
  border-radius: ${(props) => props.theme.borders.radius};

  @media only screen and (min-width: ${size.tablet}px) {
    width: 40px;
    height: 35px;
  }
`

const NewBadge = styled.div`
  border: 1px solid ${(props) => props.theme.colors.DARK_GOLD};
  color: ${(props) => props.theme.colors.DARK_GOLD};
  text-transform: uppercase;
  text-align: center;
  height: 20px;
  width: 30px;
  font-size: 10px;
  line-height: 22px;

  &[title="new"] {
    border: none;
    color: ${(props) => props.theme.colors.DARK_GOLD};
    font-family: ${(props) => props.theme.fonts.family.bold};
    position: relative;
    height: 30px;

    @media only screen and (min-width: ${size.tablet}px) {
      height: 40px;
      width: 40px;
      font-size: ${(props) => props.theme.fonts.size.small};
    }

    .text {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 30px;
      top: 5px;

      @media only screen and (min-width: ${size.tablet}px) {
        width: 30px;
        top: 10px;
      }
    }
  }
`

interface BadgesProps {
  itemBadges: Badge[]
  badges: Badge[]
  className?: string
}

const getBadge = (badge: Badge, key: string, badges: Badge[]) => {
  const fullBadge = badges.find((selector: Badge) => selector.id === badge.id)
  if (fullBadge && fullBadge.image) {
    return (
      <Image
        src={fullBadge.image}
        alt={badge.name}
        key={key}
        style={{ fill: "grey" }}
      />
    )
  } else {
    return (
      <NewBadge title={badge?.name.toLowerCase()} key={key}>
        {badge.name.toLowerCase() === "new" ? (
          <>
            <NewIcon />
            <div className="text">
              <Trans>New</Trans>
            </div>
          </>
        ) : (
          badge.name
        )}
      </NewBadge>
    )
  }
}

export const Badges: FunctionComponent<BadgesProps> = ({
  itemBadges,
  badges,
  className,
}) => {
  return (
    <Container className={className}>
      {itemBadges.map((badge) => getBadge(badge, uuid(), badges))}
    </Container>
  )
}
