import axios, { AxiosRequestConfig, AxiosInstance } from "axios"
import React from "react"
import {
  createGeneralFetch,
  createMarketingFetch,
  createItemFetch,
  createOrderFetch,
  createAddressFetch,
  createInvoiceFetch,
  createCustomerFetch,
  createProfileFetch,
  getDefaultLanguage,
  removeAgeConfirmation,
  getAgeConfirmation,
  createQuantityDiscountFetch,
} from "."
import { createRegionFetch } from "./region-http"
import { NavigateFunction, Location } from "react-router-dom"
import { getTimeZoneOffset, getIANATimezone, getZoneOffset } from "."

export const createHttpService = (
  url: string | undefined,
  navigate: NavigateFunction,
  location: Location,
  onUnauthorize: Function
): AxiosInstance => {
  const client = axios.create({
    baseURL: url || process?.env?.REACT_APP_BASE_URL || "http://localhost:8080",
    headers: {
      "Content-type": "application/json",
      "The-Timezone-IANA": getIANATimezone(),
    },
    withCredentials: true,
  } as AxiosRequestConfig)
  client.interceptors.request.use(
    (config) => {
      const defaultLanguage = getDefaultLanguage()
      const ageConfirmed = getAgeConfirmation()
      config.headers = {
        ...config.headers,
        "Use-Default-Language": defaultLanguage || false,
        "AGE-OK": ageConfirmed || false,
        "The-Timezone-Offset": getTimeZoneOffset(),
        "The-Timezone-Offset-Minutes": getZoneOffset(),
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  client.interceptors.response.use(
    (response) => {
      if (
        response.status === 200 &&
        response.headers["content-type"] === "text/html"
      ) {
        throw new Error("Invalid reponse content!")
      }
      return response
    },
    (error) => {
      if (error.response && location.pathname !== "/") {
        if (error.response.status === 401) {
          // Age restriction error code
          removeAgeConfirmation()
          navigate("/")
        } else if (error.response.status === 403) {
          // Login failure / unauthorized user / user token expired
          onUnauthorize()
        }
      }
      throw error
    }
  )
  return client
}

export const createServices = (axios: AxiosInstance, dispatch: Function) => {
  return {
    settings: createGeneralFetch(axios, dispatch),
    marketing: createMarketingFetch(axios, dispatch),
    item: createItemFetch(axios, dispatch),
    order: createOrderFetch(axios, dispatch),
    address: createAddressFetch(axios, dispatch),
    invoice: createInvoiceFetch(axios, dispatch),
    customer: createCustomerFetch(axios, dispatch),
    profile: createProfileFetch(axios, dispatch),
    quantityDiscount: createQuantityDiscountFetch(axios, dispatch),
    region: createRegionFetch(axios, dispatch),
  }
}

export type Service = ReturnType<typeof createServices>

export const HttpContext = React.createContext<Service | null>(null)

export const CACHED_QUERY_PARAMS: any = {
  cacheTime: 24 * 60 * 60 * 1000,
  retry: false,
  enabled: true,
  retryOnMount: false,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
}
