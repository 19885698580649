export interface Address {
  id: number
  country: string
  postCode: string
  city: string
  address: string
  isDefault: boolean
}

export interface VatAddress extends Address {
  company: string
}

export const emptyAddress: Address = {
  id: 0,
  country: "",
  postCode: "",
  city: "",
  address: "",
  isDefault: true,
}

export const emptyVatAddress: VatAddress = {
  id: 0,
  country: "",
  postCode: "",
  city: "",
  address: "",
  company: "",
  isDefault: true,
}
