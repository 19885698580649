export const enum BadgeTypes {
  NEW = 1,
  BIO_CONTROL = 2,
  ORGANIC = 3,
  VILLANY = 4,
}

export interface Badge {
  id: number
  name: string
  image: string
}
