import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { v4 as uuid } from "uuid"
import { routePath, routes } from "./../Routes"
import { useNavigate, useLocation } from "react-router-dom"

const HamburgerButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;

  .line {
    fill: none;
    stroke: ${(props) => props.theme.colors.white};
    stroke-width: 4;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

    &.line1 {
      stroke-dasharray: 60 207;
    }
    &.line2 {
      stroke-dasharray: 60 60;
    }
    &.line3 {
      stroke-dasharray: 60 207;
    }
  }
  &.opened {
    .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
    }
    .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
    }
    .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
    }
  }
`

const SVG = styled.svg`
  width: 32px;
  height: 32px;
`

interface HamburgerProps {
  onClick: Function
  isOpened: boolean
}

export const Hamburger: FunctionComponent<HamburgerProps> = ({
  onClick,
  isOpened,
}) => {
  return (
    <HamburgerButton
      className={isOpened ? "menu opened" : "menu"}
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation()
        onClick(!isOpened)
      }}
      aria-label="Main Menu"
    >
      <SVG viewBox="0 0 100 100">
        <path
          className="line line1"
          d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
        />
        <path className="line line2" d="M 20,50 H 80" />
        <path
          className="line line3"
          d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
        />
      </SVG>
    </HamburgerButton>
  )
}

const Menu = styled.ul`
  position: absolute;
  right: -8px;
  height: 100vh;
  box-shadow: -3px 15px 10px 0px #85888c;
  margin: 8px 0 0 0;
  padding: 0;
  background-color: ${(props) => props.theme.colors.solidBlack};
  width: 180px;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(0, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  list-style-type: none;
}

  &.closed {
    transform: translateX(195px);
`

const MenuItem = styled.li`
  margin: 0 10px;
  padding: 10px 0;
  transition-delay: 2s;
  color: ${(props) => props.theme.colors.white};
  line-height: 24px;
  font-size: ${(props) => props.theme.fonts.size.extraGreat};
  font-weight: bold;
  border-bottom: 1px solid white;
  -webkit-transition-delay: 2s;
  text-align: center;
  box-sizing: border-box;
  position: relative;

  &.active {
    color: ${(props) => props.theme.colors.red};
    border-color: ${(props) => props.theme.colors.red};
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    visibility: hidden;
    border-radius: 5px;
    transform: scaleX(0);
    transition: 0.1s linear;
    background: ${(props) => props.theme.colors.red};
  }

  &:hover:before,
  &:focus:before,
  &:active:before,
  &:active:after {
    visibility: visible;
    transform: scaleX(1);
  }
`

interface HamburgerMenuProps {
  items: routePath[]
  isOpened: boolean
  onClick: Function
  itemsInCart: number
}

export const HamburgerMenu: FunctionComponent<HamburgerMenuProps> = ({
  items,
  isOpened,
  onClick,
  itemsInCart,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <Menu className={!isOpened ? "closed" : ""}>
      {items.map((item) => (
        <MenuItem
          key={uuid()}
          onClick={(e: React.SyntheticEvent) => {
            e.stopPropagation()
            onClick()
            navigate(item.path)
          }}
          className={location.pathname === item.path ? "active" : ""}
        >
          {item.menu}{" "}
          {item.path === routes.cart.path && itemsInCart > 0
            ? "(" + itemsInCart + ")"
            : ""}
        </MenuItem>
      ))}
    </Menu>
  )
}
