import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { ReactComponent as CartIcon } from "./../assets/profile-icons/3_menu.svg"
import { size } from "./../styles"

interface CartProps {
  numberOfItems: number
}

interface CartButtonProps extends CartProps {
  onClick: Function
}

const RoundButton = styled.button`
  border: 0;
  border-radius: 50%;
  min-width: 48px;
  height: 48px;
  background-image: linear-gradient(45deg, white, #f0f0f0);
  position: fixed;
  bottom: 4%;
  right: 4%;
`

const AmountBadge = styled.div`
  border-radius: 50%;
  min-width: 16px;
  height: 16px;
  font-weight: 600;
  background: ${(props) => props.theme.colors.DARK_GOLD};
  position: absolute;
  bottom: -4px;
  right: -4px;
  color: ${(props) => props.theme.colors.DARK_BLACK};
  font-size: ${(props) => props.theme.fonts.size.extraSmall};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;

  @media only screen and (min-width: ${size.tablet}px) {
    right: -8px;
    bottom: -6px;
    font-size: 10px;
    height: 14px;
    min-width: 14px;
  }
`

const StyledCartIcon = styled(CartIcon)`
  z-index: 100;
  display: relative !important;
`

export const Cart: FunctionComponent<CartProps> = ({ numberOfItems }) => {
  return (
    <>
      <StyledCartIcon />
      {numberOfItems > 0 && <AmountBadge>{numberOfItems}</AmountBadge>}
    </>
  )
}

export const CartButton: FunctionComponent<CartButtonProps> = ({
  numberOfItems,
  onClick,
}) => {
  return (
    <RoundButton onClick={() => onClick()}>
      <Cart numberOfItems={numberOfItems} />
    </RoundButton>
  )
}
