import { confirmConditions, saveOrderThunk } from "./../state"
import { PaymentType } from "./../types"
import { routes } from "./../Routes"
import { submitLogin } from "./login-submit"

export const submitOrder = (
  values: any,
  dispatch: any,
  fetch: any,
  navigate: any
) => {
  if (values.conditionsConfirmed && fetch?.order) {
    dispatch(confirmConditions(values.conditionsConfirmed))

    if (values.payment.type === PaymentType.ON_DELIVERY) {
      const saveMethod = values.isSignUp
        ? fetch.profile.registerAndOrder
        : fetch.order.save

      dispatch(
        saveOrderThunk(
          saveMethod,
          {
            ...values,
            conditionsConfirmed: values.conditionsConfirmed,
          },
          () =>
            values.isSignUp &&
            setTimeout(
              () =>
                submitLogin(
                  { email: values.customer.email, password: values.password },
                  dispatch,
                  fetch,
                  navigate
                ),
              1500
            )
        )
      )
      setTimeout(() => navigate(routes.orderCompleted.path), 800)
    } else {
      setTimeout(() => navigate(routes.qrPayment.path), 800)
    }
  }
}
