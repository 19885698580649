import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  Settings,
  emptySettings,
  Badge,
  PriceSettings,
  QuantityDiscount,
  RegionPreview,
  Language,
} from "./../types"
import { AppState } from "./store"
import { getAgeConfirmation } from "../services"

export interface SettingsState {
  ageConfirmed: boolean
  settings: Settings
  badges: Badge[]
  defaultLanguage: boolean
  discounts: QuantityDiscount[]
  regions: RegionPreview[]
  selectedRegion: RegionPreview | null
  languages: Language[]
  activeLanguage: Language | null
  appNotificationOver: boolean
}

export const initialSettingsState: SettingsState = {
  ageConfirmed: getAgeConfirmation(),
  settings: emptySettings,
  badges: [],
  defaultLanguage: true,
  discounts: [{ id: 1, name: "min", applyFrom: 10000, discount: 5 }],
  regions: [],
  selectedRegion: null,
  languages: [],
  activeLanguage: null,
  appNotificationOver: false,
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {
    initSettings: (state, action: PayloadAction<Settings>) => {
      return { ...state, settings: action.payload }
    },
    confirmAge: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        ageConfirmed: action.payload,
      }
    },
    initBadges: (state, action: PayloadAction<Badge[]>) => {
      return { ...state, badges: action.payload }
    },
    setDefaultLanguage: (state, action: PayloadAction<boolean>) => {
      return { ...state, defaultLanguage: action.payload }
    },
    initDiscounts: (state, action: PayloadAction<QuantityDiscount[]>) => {
      return {
        ...state,
        discounts: action.payload.sort((a, b) =>
          a.applyFrom > b.applyFrom ? -1 : 1
        ),
      }
    },
    initRegions: (state, action: PayloadAction<RegionPreview[]>) => {
      return { ...state, regions: action.payload }
    },
    initActiveRegion: (state, action: PayloadAction<RegionPreview>) => {
      return { ...state, selectedRegion: action.payload }
    },
    initLanguages: (state, action: PayloadAction<Language[]>) => {
      return { ...state, languages: action.payload }
    },
    initActiveLanguage: (state, action: PayloadAction<Language>) => {
      return { ...state, activeLanguage: action.payload }
    },
    setAppNotification: (state, action: PayloadAction<boolean>) => {
      return { ...state, appNotificationOver: action.payload }
    },
  },
})

export const {
  initSettings,
  confirmAge,
  initBadges,
  setDefaultLanguage,
  initDiscounts,
  initRegions,
  initActiveRegion,
  initLanguages,
  initActiveLanguage,
  setAppNotification,
} = settingsSlice.actions

export const selectSettings = (state: AppState) => state.settings
export const selectAgeConfirmed = (state: AppState) =>
  state.settings.ageConfirmed
export const selectBadges = (state: AppState) => state.settings.badges
export const selectPayments = (state: AppState) =>
  state.settings.settings.payments
export const selectDeliveries = (state: AppState) =>
  state.settings.settings.delivery
export const selectPriceSettings = (state: AppState): PriceSettings => {
  const settings = state.settings.settings
  return {
    valuta: settings.valuta || "",
    prefix: settings.valutaPrefix || true,
  }
}
export const selectDefaultLanguage = (state: AppState) =>
  state.settings.defaultLanguage
export const selectDiscounts = (state: AppState) => state.settings.discounts
export const selectRegions = (state: AppState) => state.settings.regions
export const selectSelectedRegion = (state: AppState) =>
  state.settings.selectedRegion
export const selectLanguages = (state: AppState) => state.settings.languages
export const selectActiveLanguage = (state: AppState) =>
  state.settings.activeLanguage
export const selectAppNotification = (state: AppState) =>
  state.settings.appNotificationOver
export const selectAppIsAvilable = (state: AppState) =>
  state.settings.settings.appStoreActive ||
  state.settings.settings.googlePlayActive

export default settingsSlice.reducer
