import React from "react"
import { Line } from "./../styles"
import GoldPasswordInput from "./GoldPasswordInput"
import { useLingui } from "@lingui/react"

interface PasswordProps {
  password: string
  confirmationPassword: string
  passwordError?: string
  confirmationPasswordError?: string
  setFieldValue: Function
}

export const Password: React.FunctionComponent<PasswordProps> = ({
  password,
  confirmationPassword,
  passwordError,
  confirmationPasswordError,
  setFieldValue,
}) => {
  const { i18n } = useLingui()

  return (
    <>
      <Line className="line">
        <GoldPasswordInput
          placeholder={i18n._("Password")}
          error={passwordError}
          value={password}
          onBlur={(value: string) => setFieldValue("password", value)}
        />
      </Line>
      <Line className="line">
        <GoldPasswordInput
          placeholder={i18n._("Password confirm")}
          error={confirmationPasswordError}
          value={confirmationPassword}
          onBlur={(value: string) =>
            setFieldValue("confirmationPassword", value)
          }
        />
      </Line>
    </>
  )
}
