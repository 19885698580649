import React, { FunctionComponent } from "react"
import {
  SpacedFlexBox,
  size,
  FlexibleDekstopContainer,
  theme,
} from "./../styles"
import styled from "styled-components"
import { ReactComponent as LogoIcon } from "./../assets/profile-icons/2_menu.svg"
import { routes } from "./../Routes"
import {
  useAppSelector,
  selectNoItemsInOrder,
  selectOrder,
  updateItemInOrder,
  removeItemFromOrder,
  useAppDispatch,
  selectUserSignIn,
  signOut,
  selectSelectedRegion,
  clearOrder,
} from "./../state"
import { Cart, Language, CartPopup } from "."
import { GoldDivider } from "./GoldDivider"
import ProfilePopup from "./profile/ProfilePopup"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { ReactComponent as BagIcon } from "./../assets/bag.svg"
import { ReactComponent as MenuIcon } from "./../assets/menu.svg"
import { ReactComponent as WineLoginIcon } from "./../assets/profile-icons/1_menu.svg"
import { ReactComponent as DropperIcon } from "./../assets/dropper.svg"
import LiquorIcon from "@mui/icons-material/Liquor"
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import LogoutIcon from "@mui/icons-material/Logout"
import LoginIcon from "@mui/icons-material/Login"
import { Trans } from "@lingui/macro"
import MediaQuery from "react-responsive"
import { OrderedItem } from "./../types"
import { HttpContext } from "./../services"
import { clearRememberUser } from "../hooks"

const MOUSE_LEAVE_TIMEOUT = 100

const MenuContainer = styled(SpacedFlexBox)`
  height: ${(props) => props.theme.menu.height.mobile};
  background-color: ${(props) => props.theme.colors.DARK_BLACK};
  align-items: center;
  z-index: 1300;
  column-gap: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0;
  justify-content: center;

  &.fixed {
    position: fixed;
    top: 0;
    width: 100%;
  }

  @media only screen and (min-width: ${size.tablet}px) {
    height: ${(props) => props.theme.menu.height.desktop};
  }
`

const Logo = styled(LogoIcon)`
  height: inherit;
  height: 20px;
  width: 150px;
  margin-top: 3px;
  position: relative;

  @media only screen and (min-width: ${size.tablet}px) {
    height: 15px;
    width: 100px;
    margin-top: -7px;
  }
`

const Bag = styled(BagIcon)`
  fill: ${(props) => props.theme.colors.DARK_GOLD};
  height: ${(props) => props.theme.menu.drawerIcon};
`

const Shop = styled(LiquorIcon)`
  &.MuiSvgIcon-root {
    fill: ${(props) => props.theme.colors.DARK_GOLD};
    height: ${(props) => props.theme.menu.drawerIcon};
    width: ${(props) => props.theme.menu.drawerIcon};
  }
`

const Login = styled(WineLoginIcon)`
  fill: ${(props) => props.theme.colors.DARK_GOLD};
  height: ${(props) => props.theme.menu.drawerIcon};
`

const LoginDesktop = styled(WineLoginIcon)`
  fill: ${(props) => props.theme.colors.DARK_GOLD};
  height: 16px;
  path.winelab-icon.cls-1 {
    fill: ${(props) => props.theme.colors.DARK_GOLD};
  }
`

const Dropper = styled(DropperIcon)`
  position: absolute;
  height: 8px;
  top: 0;
  right: 30px;
`

//TODO: refactor to use common CSS
const MenuActions = styled.div`
  position: relative;
  margin-right: 22px;
  display: flex;
  justify-content: right;
  column-gap: 6px;
  color: white;
  height: inherit;
  align-items: center;

  &.position-left {
    margin-left: 22px;
    margin-right: 0;
    justify-content: left;
  }
`

const MenuActionsDesktop = styled(MenuActions)`
  column-gap: 24px;
`

const MenuButton = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(props) => props.theme.menu.iconSize};
  height: ${(props) => props.theme.menu.iconSize};
  border: 0;
  background: transparent;
  text-decoration: none;
  &.active {
    color: #bf0d0d;
  }

  > svg {
    width: 18px;

    @media only screen and (min-width: ${size.tablet}px) {
      width: 14px;
      height: 14px;
    }
  }

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.extraSmall};
  }
`

const MenuButtonDesktop = styled(Link)`
  border: 0;
  background: transparent;
  text-decoration: none;
  color: ${(props) => props.theme.colors.DARK_GOLD};
  text-transform: uppercase;
  position: relative;
  font-size: ${(props) => props.theme.fonts.size.extraSmall};

  > svg {
    width: 16px;
  }
`

const MenuIconButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(props) => props.theme.menu.iconSize};
  height: ${(props) => props.theme.menu.iconSize};
  border: 0;
  background: transparent;
  text-decoration: none;
  &.active {
    color: #bf0d0d;
  }

  > svg {
    width: 18px;
  }
`

const DrawerWrapper = styled(Drawer)`
  width: 100%;
  height: fit-content;
  top: ${(props) => props.theme.menu.height.mobile};

  .MuiBackdrop-root {
    top: ${(props) => props.theme.menu.height.mobile};
    height: calc(100% - ${(props) => props.theme.menu.height.mobile});
  }

  .MuiDrawer-paper {
    top: ${(props) => props.theme.menu.height.mobile};

    .MuiBox-root {
      background-color: ${(props) => props.theme.colors.DARK_GREY};
    }
  }

  .MuiListItem-root {
    padding: 8px 54px;
    column-gap: 20px;
    align-items: center;

    .MuiListItemIcon-root {
      min-width: 12px;
    }

    .MuiListItemText-primary,
    .MuiListItemText-root {
      color: ${(props) => props.theme.colors.DARK_GOLD};
      font-family: ${(props) => props.theme.fonts.family.regular};
      font-size: 10px;
      margin: 0;
    }
  }
`

const Divider = styled(GoldDivider)`
  margin-top: 46px;

  &.mobile {
    margin-top: 70px;
  }
`

const FlexibleDekstopContainerWrapper = styled(FlexibleDekstopContainer)`
  position: absolute;
`

interface MenuDrawerProps {
  open: boolean
  callback: Function
  numberOfItems: number
  navigate: Function
  isSignedIn: boolean
  onSignOut: Function
}

const MenuDrawer: React.FunctionComponent<MenuDrawerProps> = ({
  open,
  callback,
  numberOfItems,
  navigate,
  isSignedIn,
  onSignOut,
}) => {
  const [state, setState] = React.useState(open)

  React.useEffect(() => {
    open !== state && setState(open)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return
      }

      setState(open)
      callback(open)
    }

  const closeDrawer = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    setState(false)
    callback(false)
  }

  const openBag = (e: React.SyntheticEvent) => {
    closeDrawer(e)
    navigate(routes.cart.path)
  }

  const openAboutUs = (e: React.SyntheticEvent) => {
    closeDrawer(e)
    navigate(routes.aboutUs.path)
  }

  const openLogin = (e: React.SyntheticEvent) => {
    closeDrawer(e)
    navigate(routes.login.path)
  }

  const openProfile = (e: React.SyntheticEvent) => {
    closeDrawer(e)
    navigate(routes.profile.path)
  }

  const openOrders = (e: React.SyntheticEvent) => {
    closeDrawer(e)
    navigate("/profile/orders/active")
  }

  const openShop = (e: React.SyntheticEvent) => {
    closeDrawer(e)
    navigate(routes.list.path)
  }

  const signOut = (e: React.SyntheticEvent) => {
    closeDrawer(e)
    onSignOut()
  }

  const changeRegion = (e: React.SyntheticEvent) => {
    closeDrawer(e)
    navigate(routes.region.path)
  }

  return (
    <DrawerWrapper
      anchor="top"
      open={state}
      onClose={toggleDrawer(false)}
      sx={{ top: "68px" }}
      classes={{ root: "winelab-menu-drawer" }}
    >
      <Box
        sx={{ width: "auto", top: "68px" }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem onClick={(e) => openAboutUs(e)}>
            <ListItemIcon></ListItemIcon>
            <ListItemText>
              <Trans>About us</Trans>
            </ListItemText>
          </ListItem>
          <ListItem onClick={(e) => openShop(e)}>
            <ListItemIcon>
              <Shop />
            </ListItemIcon>
            <ListItemText>
              <Trans>Shop</Trans>
            </ListItemText>
          </ListItem>
          <ListItem onClick={(e) => openBag(e)}>
            <ListItemIcon>
              <Bag />
            </ListItemIcon>
            <ListItemText>
              <Trans>Bag</Trans>
              {numberOfItems > 0 && ` (${numberOfItems})`}
            </ListItemText>
          </ListItem>
          {!isSignedIn ? (
            <ListItem onClick={(e) => openLogin(e)}>
              <ListItemIcon>
                <LoginIcon
                  sx={{ fontSize: "12px", fill: theme.colors.DARK_GOLD }}
                />
              </ListItemIcon>
              <ListItemText>
                <Trans>Sign in</Trans>
              </ListItemText>
            </ListItem>
          ) : (
            <>
              <ListItem onClick={(e) => openProfile(e)}>
                <ListItemIcon>
                  <Login />
                </ListItemIcon>
                <ListItemText>
                  <Trans>Profile</Trans>
                </ListItemText>
              </ListItem>
              <ListItem onClick={(e) => openOrders(e)}>
                <ListItemIcon>
                  <EditIcon sx={{ fontSize: "12px", fill: "#EECC6D" }} />
                </ListItemIcon>
                <ListItemText>
                  <Trans>Orders</Trans>
                </ListItemText>
              </ListItem>
              <ListItem onClick={(e) => signOut(e)}>
                <ListItemIcon>
                  <LogoutIcon sx={{ fontSize: "12px", fill: "#EECC6D" }} />
                </ListItemIcon>
                <ListItemText>
                  <Trans>Sign out</Trans>
                </ListItemText>
              </ListItem>
            </>
          )}
          <ListItem onClick={(e) => changeRegion(e)}>
            <ListItemIcon></ListItemIcon>
            <ListItemText>
              <Trans>Regions</Trans>
            </ListItemText>
          </ListItem>
          <ListItem onClick={(e) => e.stopPropagation()}>
            <ListItemIcon></ListItemIcon>
            <ListItemText>
              <Language />
            </ListItemText>
          </ListItem>
        </List>
        <Dropper />
      </Box>
    </DrawerWrapper>
  )
}

interface MenuProps {
  className?: string
}

export const Menu: FunctionComponent<MenuProps> = ({ className }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const itemsInOrder = useAppSelector(selectNoItemsInOrder)
  const [state, setState] = React.useState(false)
  const [cartPopup, setCartPopup] = React.useState<any>(null)
  const [cartPopupVisible, setCartPopupVisible] = React.useState(false)
  const [cartPopupHover, setCartPopupHover] = React.useState(false)
  const [profilePopup, setProfilePopup] = React.useState<any>(null)
  const [profilePopupVisible, setProfilePopupVisible] = React.useState(false)
  const [profilePopupHover, setProfilePopupHover] = React.useState(false)
  const order = useAppSelector(selectOrder)
  const dispatch = useAppDispatch()
  const isSignedIn = useAppSelector(selectUserSignIn)
  const fetch = React.useContext(HttpContext)
  const selectedRegion = useAppSelector(selectSelectedRegion)

  const handleSignOut = () => {
    fetch?.profile.logout().then(() => {
      clearRememberUser()
      dispatch(signOut())
      dispatch(clearOrder())
      navigate(routes.list.path)
    })
  }

  return (
    <>
      <MediaQuery maxWidth={size.tablet}>
        <MenuContainer className={"fixed " + (className || "")}>
          <FlexibleDekstopContainerWrapper>
            <MenuActions className="position-left">
              <MenuIconButton
                onClick={() => selectedRegion && setState(!state)}
              >
                <MenuIcon />
              </MenuIconButton>
            </MenuActions>
            <Logo
              onClick={() => selectedRegion && navigate(routes.home.path)}
            />
            <MenuActions>
              <MenuButton
                to={routes.cart.path}
                className={
                  location.pathname === routes.cart.path ? "active" : ""
                }
                onClick={(e) =>
                  selectedRegion ? setState(false) : e.preventDefault()
                }
              >
                <Cart numberOfItems={itemsInOrder} />
              </MenuButton>
            </MenuActions>
          </FlexibleDekstopContainerWrapper>
          <Divider className="mobile" />
        </MenuContainer>
        <MenuDrawer
          open={state}
          callback={setState}
          numberOfItems={itemsInOrder}
          navigate={navigate}
          isSignedIn={isSignedIn}
          onSignOut={handleSignOut}
        />
      </MediaQuery>
      <MediaQuery minWidth={size.tablet}>
        <MenuContainer className={"fixed desktop " + (className || "")}>
          <FlexibleDekstopContainerWrapper>
            <MenuActionsDesktop className="position-left">
              <Logo
                onClick={() => selectedRegion && navigate(routes.home.path)}
              />
              <MenuButtonDesktop
                to={routes.aboutUs.path}
                className={
                  location.pathname === routes.aboutUs.path ? "active" : ""
                }
                onClick={(e) => !selectedRegion && e.preventDefault()}
              >
                <Trans>About us</Trans>
              </MenuButtonDesktop>
              <MenuButtonDesktop
                to={routes.list.path}
                className={
                  location.pathname === routes.list.path ? "active" : ""
                }
                onClick={(e) => !selectedRegion && e.preventDefault()}
              >
                <Trans>Shop</Trans>
              </MenuButtonDesktop>
              <MenuButtonDesktop
                to={routes.contacts.path}
                className={
                  location.pathname === routes.contacts.path ? "active" : ""
                }
                onClick={(e) => !selectedRegion && e.preventDefault()}
              >
                <Trans>Contact</Trans>
              </MenuButtonDesktop>
            </MenuActionsDesktop>
            <MenuActionsDesktop>
              <Language />
              <MenuButtonDesktop
                to={isSignedIn ? routes.profile.path : routes.login.path}
                onMouseEnter={(event) => {
                  if (selectedRegion) {
                    setProfilePopup(event.currentTarget)
                    setProfilePopupVisible(true)
                  }
                }}
                onMouseLeave={() =>
                  setTimeout(
                    () => setProfilePopupVisible(false),
                    MOUSE_LEAVE_TIMEOUT
                  )
                }
                onClick={(e) => !selectedRegion && e.preventDefault()}
              >
                <LoginDesktop />
              </MenuButtonDesktop>
              <MenuButtonDesktop
                to={routes.cart.path}
                className={
                  location.pathname === routes.cart.path ? "active" : ""
                }
                onMouseEnter={(event) => {
                  if (selectedRegion) {
                    setCartPopup(event.currentTarget)
                    setCartPopupVisible(true)
                  }
                }}
                onMouseLeave={() =>
                  setTimeout(
                    () => setCartPopupVisible(false),
                    MOUSE_LEAVE_TIMEOUT
                  )
                }
                onClick={(e) => !selectedRegion && e.preventDefault()}
              >
                <Cart numberOfItems={itemsInOrder} />
              </MenuButtonDesktop>
              {(cartPopupVisible || cartPopupHover) && (
                <CartPopup
                  open={cartPopupVisible || cartPopupHover}
                  anchorEl={cartPopup}
                  items={order.items}
                  onItemChange={(orderedItem: OrderedItem, amount: number) => {
                    dispatch(
                      updateItemInOrder({
                        ...orderedItem,
                        orderedAmount: amount,
                      })
                    )
                  }}
                  onItemRemove={(id: number) => {
                    dispatch(removeItemFromOrder(id))
                  }}
                  onGoToCart={() => {
                    setCartPopupVisible(false)
                    setCartPopupHover(false)
                    navigate(routes.cart.path)
                  }}
                  onGoToCheckout={() => {
                    setCartPopupVisible(false)
                    setCartPopupHover(false)
                    navigate(routes.checkout.path)
                  }}
                  onMouseEnter={() => setCartPopupHover(true)}
                  onMouseLeave={() =>
                    setTimeout(
                      () => setCartPopupHover(false),
                      MOUSE_LEAVE_TIMEOUT
                    )
                  }
                />
              )}
              {(profilePopupVisible || profilePopupHover) && (
                <ProfilePopup
                  open={profilePopupVisible || profilePopupHover}
                  anchorEl={profilePopup}
                  navigate={navigate}
                  isLogin={isSignedIn}
                  onMouseEnter={() => setProfilePopupHover(true)}
                  onMouseLeave={() =>
                    setTimeout(
                      () => setProfilePopupHover(false),
                      MOUSE_LEAVE_TIMEOUT
                    )
                  }
                  onClose={() => setProfilePopupHover(false)}
                  onSignOut={() => handleSignOut()}
                />
              )}
            </MenuActionsDesktop>
          </FlexibleDekstopContainerWrapper>
          <Divider />
        </MenuContainer>
      </MediaQuery>
    </>
  )
}
