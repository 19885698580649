import {
  Customer,
  Address,
  PasswordChange,
  VatAddress,
  CustomerDetails,
  ResetPassword,
} from "."

export const VALIDATOR: any = {
  NAME: /[A-Za-zÀ-ÖØ-öø-ÿ]/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE: /^[0-9 ()+]+$/,
  COUNTRY: /[A-Za-zÀ-ÖØ-öø-ÿ]/,
  CITY: /[A-Za-zÀ-ÖØ-öø-ÿ]/,
  ADDRESS: /[A-Za-z0-9'.\-\s,]/,
  COMPANY: /[A-Za-zÀ-ÖØ-öø-ÿ ().]/,
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*:?.-_'"+//=()])(?=.{8,})/,
}

interface LoginValidatorProps {
  email: string
  password: string
}

export const validateLogin = (values: LoginValidatorProps, i18n: any) => {
  let errors: any = {}

  if (!values.email.match(VALIDATOR.EMAIL)) {
    errors = {
      ...errors,
      email: i18n._("Email is incorrect"),
    }
  }
  if (!values.password.match(VALIDATOR.PASSWORD)) {
    errors = {
      ...errors,
      password: i18n._("Password is incorrect"),
    }
  }
  return errors
}

interface UserDetailsValidatorProps {
  customer: Customer
  shipping: Address
}

export const validateUserDetails = (
  values: UserDetailsValidatorProps,
  i18n: any
) => {
  let errors: any = {}
  if (!values.customer.firstName.match(VALIDATOR.NAME)) {
    if (!errors?.customer) errors = { ...errors, customer: {} }
    errors = {
      ...errors,
      customer: { ...errors.customer, firstName: i18n._("Provide first name") },
    }
  }
  if (!values.customer.lastName.match(VALIDATOR.NAME)) {
    if (!errors.customer) errors = { ...errors, customer: {} }
    errors = {
      ...errors,
      customer: { ...errors.customer, lastName: i18n._("Provide last name") },
    }
  }
  if (!values.customer.email.match(VALIDATOR.EMAIL)) {
    if (!errors.customer) errors = { ...errors, customer: {} }
    errors = {
      ...errors,
      customer: {
        ...errors.customer,
        email: i18n._("Provide correct email address"),
      },
    }
  }
  if (!values.customer.phone.match(VALIDATOR.PHONE)) {
    if (!errors.customer) errors = { ...errors, customer: {} }
    errors = {
      ...errors,
      customer: {
        ...errors.customer,
        phone: i18n._("Provide correct phone number"),
      },
    }
  }

  if (!values.shipping.country.match(VALIDATOR.COUNTRY)) {
    if (!errors.shipping) errors = { ...errors, shipping: {} }
    errors = {
      ...errors,
      shipping: { ...errors.shipping, country: i18n._("Provide country") },
    }
  }
  if (!values.shipping.postCode) {
    if (!errors.shipping) errors = { ...errors, shipping: {} }
    errors = {
      ...errors,
      shipping: { ...errors.shipping, postCode: i18n._("Provide post code") },
    }
  }
  if (!values.shipping.city.match(VALIDATOR.CITY)) {
    if (!errors.shipping) errors = { ...errors, shipping: {} }
    errors = {
      ...errors,
      shipping: { ...errors.shipping, city: i18n._("Provide city") },
    }
  }
  if (!values.shipping.address.match(VALIDATOR.ADDRESS)) {
    if (!errors.shipping) errors = { ...errors, shipping: {} }
    errors = {
      ...errors,
      shipping: { ...errors.shipping, address: i18n._("Provide address") },
    }
  }
  return errors
}

export const validateUserMinimum = (values: CustomerDetails, i18n: any) => {
  let errors: any = {}
  if (!values.firstName.match(VALIDATOR.NAME)) {
    errors = {
      ...errors,
      firstName: i18n._("Provide first name"),
    }
  }
  if (!values.lastName.match(VALIDATOR.NAME)) {
    errors = {
      ...errors,
      lastName: i18n._("Provide last name"),
    }
  }
  if (!values.email.match(VALIDATOR.EMAIL)) {
    errors = {
      ...errors,
      email: i18n._("Provide correct email address"),
    }
  }
  if (!values.phone.match(VALIDATOR.PHONE)) {
    errors = {
      ...errors,
      phone: i18n._("Provide correct phone number"),
    }
  }
  return errors
}

export const validateUserRegister = (values: CustomerDetails, i18n: any) => {
  let errors: any = {}
  if (!values.firstName.match(VALIDATOR.NAME)) {
    errors = {
      ...errors,
      firstName: i18n._("Provide first name"),
    }
  }
  if (!values.lastName.match(VALIDATOR.NAME)) {
    errors = {
      ...errors,
      lastName: i18n._("Provide last name"),
    }
  }
  if (!values.email.match(VALIDATOR.EMAIL)) {
    errors = {
      ...errors,
      email: i18n._("Provide correct email address"),
    }
  }
  return errors
}

interface Password {
  password: string
  confirmPassword: string
}

export const validatePassword = (values: Password, i18n: any) => {
  let errors: any = {}

  if (!values.password.match(VALIDATOR.PASSWORD)) {
    errors = {
      ...errors,
      password: i18n._("Password is empty or incorrect"),
    }
  }
  if (!values.confirmPassword.match(VALIDATOR.PASSWORD)) {
    errors = {
      ...errors,
      confirmPassword: i18n._("Confirmation password is empty or incorrect"),
    }
  }
  if (values.password !== values.confirmPassword) {
    errors = {
      ...errors,
      confirmPassword: i18n._("Confirmation password is differ to password!"),
    }
  }
  return errors
}

export const validatePasswordChange = (values: PasswordChange, i18n: any) => {
  let errors: any = {}

  if (!values.currentPassword.match(VALIDATOR.PASSWORD)) {
    errors = {
      ...errors,
      currentPassword: i18n._("Current password is empty or incorrect"),
    }
  }
  if (!values.newPassword.match(VALIDATOR.PASSWORD)) {
    errors = {
      ...errors,
      newPassword: i18n._("New password is empty incorrect"),
    }
  }
  if (!values.confirmPassword.match(VALIDATOR.PASSWORD)) {
    errors = {
      ...errors,
      confirmPassword: i18n._("Confirmation password is empty incorrect"),
    }
  }
  if (values.newPassword !== values.confirmPassword) {
    errors = {
      ...errors,
      confirmPassword: i18n._(
        "Confirmation password is differ to new password!"
      ),
    }
  }
  return errors
}

export const validatePasswordReset = (values: ResetPassword, i18n: any) => {
  let errors: any = {}

  if (!values.newPassword.match(VALIDATOR.PASSWORD)) {
    errors = {
      ...errors,
      newPassword: i18n._("New password is empty incorrect"),
    }
  }
  if (!values.confirmPassword.match(VALIDATOR.PASSWORD)) {
    errors = {
      ...errors,
      confirmPassword: i18n._("Confirmation password is empty incorrect"),
    }
  }
  if (values.newPassword !== values.confirmPassword) {
    errors = {
      ...errors,
      confirmPassword: i18n._(
        "Confirmation password is differ to new password!"
      ),
    }
  }
  return errors
}

export const validateAddress = (values: Address, i18n: any) => {
  let errors: any = {}
  if (!values.country.match(VALIDATOR.COUNTRY)) {
    errors = {
      ...errors,
      country: i18n._("Provide country"),
    }
  }
  if (!values.postCode) {
    errors = {
      ...errors,
      postCode: i18n._("Provide post code"),
    }
  }
  if (!values.city.match(VALIDATOR.CITY)) {
    errors = {
      ...errors,
      city: i18n._("Provide city"),
    }
  }
  if (!values.address.match(VALIDATOR.ADDRESS)) {
    errors = {
      ...errors,
      address: i18n._("Provide address"),
    }
  }
  return errors
}

export const validateInvoice = (values: VatAddress, i18n: any) => {
  let errors: any = {}

  if (!values.company.match(VALIDATOR.COMPANY)) {
    errors = {
      ...errors,
      country: i18n._("Provide company"),
    }
  }
  if (!values.country.match(VALIDATOR.COUNTRY)) {
    errors = {
      ...errors,
      country: i18n._("Provide country"),
    }
  }
  if (!values.postCode) {
    errors = {
      ...errors,
      postCode: i18n._("Provide post code"),
    }
  }
  if (!values.city.match(VALIDATOR.CITY)) {
    errors = {
      ...errors,
      city: i18n._("Provide city"),
    }
  }
  if (!values.address.match(VALIDATOR.ADDRESS)) {
    errors = {
      ...errors,
      address: i18n._("Provide address"),
    }
  }
  return errors
}
