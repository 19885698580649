import React from "react"
import { Line } from "./../../styles"
import { Trans } from "@lingui/macro"
import { PasswordInput } from "./.."

interface ResetPasswordFormProps {
  newPassword: string
  confirmPassword: string
  setFieldValue: Function
  newPasswordError?: string
  confirmPasswordError?: string
}

export const ResetPasswordForm: React.FunctionComponent<
  ResetPasswordFormProps
> = ({
  newPassword,
  confirmPassword,
  setFieldValue,
  newPasswordError,
  confirmPasswordError,
}) => {
  const [state, setState] = React.useState({
    newPassword,
    confirmPassword,
  })
  const [errors, setErrors] = React.useState({
    newPasswordError,
    confirmPasswordError,
  })

  React.useEffect(() => {
    newPassword !== state.newPassword && setState({ ...state, newPassword })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword])

  React.useEffect(() => {
    confirmPassword !== state.confirmPassword &&
      setState({ ...state, confirmPassword })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPassword])

  React.useEffect(() => {
    setErrors({ newPasswordError, confirmPasswordError })
  }, [newPasswordError, confirmPasswordError])

  return (
    <>
      <Line className="line">
        <PasswordInput
          label={<Trans>New password</Trans>}
          setFieldValue={setFieldValue}
          value={state.newPassword}
          error={errors.newPasswordError}
          fieldName="newPassword"
        />
      </Line>
      <Line className="line">
        <PasswordInput
          label={<Trans>Confirmation password</Trans>}
          setFieldValue={setFieldValue}
          value={state.confirmPassword}
          error={errors.confirmPasswordError}
          fieldName="confirmPassword"
        />
      </Line>
    </>
  )
}

export default ResetPasswordForm
