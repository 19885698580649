import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState } from "./store"
import { Alert } from "../types"
import { v4 as uuidv4 } from "uuid"

export interface AlertState {
  alert: Alert | null
}

const initialState: AlertState = {
  alert: null,
}

export const alertSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    notifyUser: (state, action: PayloadAction<Partial<Alert>>) => {
      return { ...state, alert: { ...action.payload, id: uuidv4() } as Alert }
    },
    dissmissAlert: (state) => {
      return { ...state, alert: null }
    },
  },
})

export const { notifyUser, dissmissAlert } = alertSlice.actions

export const selectAlert = (state: AppState) => state.alerts.alert

export default alertSlice.reducer
