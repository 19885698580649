import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState } from "./store"
import { ItemPreview } from "../types"

type ItemState = {
  preview: ItemPreview | null
}

const initialState: ItemState = {
  preview: null,
}

export const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    selectItemForPreview: (state, action: PayloadAction<ItemPreview>) => {
      return { ...state, preview: action.payload }
    },
    removeItemFromPreview: (state, action: PayloadAction<ItemPreview>) => {
      return { ...state, preview: null }
    },
  },
})

export const { selectItemForPreview, removeItemFromPreview } = itemSlice.actions

export const selectItemPreview = (state: AppState) => state.items.preview

export default itemSlice.reducer
