import React from "react"
import { ReactComponent as LogoIcon } from "./../assets/logo.svg"
import styled from "styled-components"
import { size } from "../styles"
import { Trans } from "@lingui/macro"

const Logo = styled(LogoIcon)`
  display: block;
  height: 60px;
`
const ConstructionContainer = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.solidBlack};

  @media only screen and (min-width: ${size.tablet}px) {
    z-index: 1500;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const ConstructionMessage = styled.div`
  color: ${(props) => props.theme.colors.white};
  margin: 4px 0;
`

export const Construction = () => {
  return (
    <ConstructionContainer>
      <Logo />
      <ConstructionMessage>
        <Trans>Webshop is under construction.</Trans>
      </ConstructionMessage>
      <ConstructionMessage>
        <Trans>Please try on mobile, or come back later!</Trans>
      </ConstructionMessage>
      <ConstructionMessage>
        <Trans>Cheers!</Trans>
      </ConstructionMessage>
    </ConstructionContainer>
  )
}
