export const formattedText = `
    .formatted-text {
        p {
        margin-block-start: 0px;
        margin-block-end: 0px;

        &.ql-align-justify {
            text-align: justify;
        }

        &.ql-align-center {
            text-align: center;
        }
        }

        strong {
        font-family: "RobotoBold", Fallback, sans-serif;
        }
    }
`
