import React, { useEffect, useState, FunctionComponent } from "react"
import styled from "styled-components"
import { FlexColumnBox, FlexBox, Button, device } from "./../styles"
import {
  lowestPrice,
  SettingsContext,
  roundPrice,
  getFormattedPrice,
} from "./../services"
import { OrderedItem, Delivery } from "./../types"
import { Trans } from "@lingui/macro"
import {
  useAppSelector,
  selectDiscounts,
  selectCanApplyCategoryPromotion,
} from "./../state"
import { GoldDivider } from "./GoldDivider"

const PriceContainer = styled(FlexBox)`
  padding: 8px;
  background-color: transparent;
  color: black;
  font-family: ${(props) => props.theme.fonts.family.regular};
  margin: 8px;

  @media ${device.tablet} {
    font-size: ${(props) => props.theme.fonts.size.medium};
  }
`
const PriceColumnContainer = styled(FlexColumnBox)`
  flex-grow: 1;
`
const PriceColumns = `
  align-items: baseline;
  .first {
    width: 40%;
  }
  .second:not(.spaced) {
    width: 60%;
    font-weight: bold;
  }
`
const FinalPrice = styled(FlexBox)`
  ${PriceColumns}
  color: ${(props) => props.theme.colors.white};
  &.spaced {
    justify-content: space-between;
  }
`
const TotalPrice = styled(FlexBox)`
  ${PriceColumns}
  font-size: ${(props) => props.theme.fonts.size.extraSmall};
  color: ${(props) => props.theme.colors.DARK_GOLD};
  &.spaced {
    justify-content: space-between;
  }
`
const Discount = styled(TotalPrice)`
  color: ${(props) => props.theme.colors.DARK_GOLD};
  font-size: ${(props) => props.theme.fonts.size.small};
  &.spaced {
    justify-content: space-between;
  }
`
const CheckoutButton = styled(Button)`
  height: auto;
`
interface PriceSummaryProps {
  orderedItems: OrderedItem[]
  onClick?: Function
  justFinalPrice?: boolean
  delivery: Delivery
}
export const PriceSummary: FunctionComponent<PriceSummaryProps> = ({
  orderedItems,
  onClick,
  justFinalPrice,
  delivery,
}) => {
  const { settings } = React.useContext(SettingsContext)
  const canApplyUserCategoryPromotion = useAppSelector(
    selectCanApplyCategoryPromotion
  )
  const [price, setPrice] = useState({
    totalPrice: 0,
    discountPrice: 0,
    discount: 0,
    vat: 0,
  })
  const discounts = useAppSelector(selectDiscounts)

  useEffect(() => {
    let promotedPrice = orderedItems
      .map((orderedItem) => {
        const discount = orderedItem.item.discount
        const price = orderedItem.item.price
        return lowestPrice(price, discount || price) * orderedItem.orderedAmount
      })
      .reduce((accumulator, curr) => accumulator + curr, 0)
    const totalPrice = orderedItems
      .map((orderedItem) => {
        const price = orderedItem.item.price
        return price * orderedItem.orderedAmount
      })
      .reduce((accumulator, curr) => accumulator + curr, 0)
    if (!canApplyUserCategoryPromotion) {
      const quantityDiscount =
        (discounts.find((discount) => discount.applyFrom <= totalPrice)
          ?.discount || 0) / 100
      const quantityDiscountPrice = Math.round(
        totalPrice * (1 - quantityDiscount)
      )
      promotedPrice = lowestPrice(
        promotedPrice,
        lowestPrice(totalPrice, quantityDiscountPrice)
      )
    }

    setPrice({
      totalPrice,
      discountPrice: promotedPrice,
      discount: totalPrice - promotedPrice,
      vat: (totalPrice * settings.vat) / 100,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedItems])

  const getDeliveryFee = () => {
    if (delivery.freeAbove) {
      return price.discountPrice >= delivery.freeAbove
        ? 0
        : delivery.deliveryFee
    } else {
      return delivery.deliveryFee
    }
  }

  return justFinalPrice ? (
    <PriceContainer>
      <PriceColumnContainer>
        <TotalPrice className="spaced">
          <div className="first">
            <Trans>Delivery fee:</Trans>
          </div>
          <div className="second spaced">
            {getFormattedPrice(getDeliveryFee() || 0, settings)}
          </div>
        </TotalPrice>
        <Discount className="spaced">
          <div className="first">
            <Trans>Discount:</Trans>
          </div>{" "}
          <div className="second spaced">
            {price.discount > 0
              ? "-" + getFormattedPrice(roundPrice(price.discount), settings)
              : 0}
          </div>
        </Discount>
        <FinalPrice className="spaced">
          <div className="first">
            <Trans>Total price:</Trans>
          </div>{" "}
          <div className="second spaced">
            {getFormattedPrice(
              roundPrice(price.discountPrice + (getDeliveryFee() || 0)),
              settings
            )}
          </div>
        </FinalPrice>
        <GoldDivider />
      </PriceColumnContainer>
    </PriceContainer>
  ) : (
    <PriceContainer>
      <PriceColumnContainer>
        <TotalPrice>
          <div className="first">
            <Trans>Partial price:</Trans>
          </div>{" "}
          <div className="second">
            {getFormattedPrice(
              Math.round(price.discountPrice - price.vat),
              settings
            )}
          </div>
        </TotalPrice>
        <TotalPrice>
          <div className="first">
            <Trans>VAT</Trans> {settings.vat}%:
          </div>{" "}
          <div className="second">
            {getFormattedPrice(Math.round(price.vat), settings)}
          </div>
        </TotalPrice>
        <Discount>
          <div className="first">
            <Trans>Discount:</Trans>
          </div>{" "}
          <div className="second">
            {price.discount > 0
              ? "-" + getFormattedPrice(roundPrice(price.discount), settings)
              : 0}
          </div>
        </Discount>
        <TotalPrice>
          <div className="first">
            <Trans>Total price:</Trans>
          </div>{" "}
          <div className="second">
            {getFormattedPrice(roundPrice(price.discountPrice), settings)}
          </div>
        </TotalPrice>
      </PriceColumnContainer>
      <CheckoutButton onClick={() => onClick && onClick()}>
        <Trans>Check Out</Trans>
      </CheckoutButton>
    </PriceContainer>
  )
}
