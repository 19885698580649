import React from "react"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import IconButton from "@mui/material/IconButton"

interface PasswordInputProps {
  label: any
  setFieldValue: Function
  value: string
  error?: string | undefined | null
  fieldName: string
  ref?: any
}

export const PasswordInput: React.FunctionComponent<PasswordInputProps> = ({
  label,
  setFieldValue,
  value,
  error,
  fieldName,
  ref,
}) => {
  const [state, setState] = React.useState<string>(value || "")
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  React.useEffect(() => {
    setState(value)
  }, [value])

  return (
    <TextField
      fullWidth
      label={label}
      size="small"
      variant="outlined"
      type={showPassword ? "text" : "password"}
      error={Boolean(error)}
      helperText={error || ""}
      value={state}
      onChange={(e) => {
        setState(e.target.value)
      }}
      onBlur={() => setFieldValue(fieldName, state)}
      margin="normal"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
