import React, { FunctionComponent } from "react"
import { Button, size } from "./../styles"
import styled from "styled-components"
import { Trans } from "@lingui/macro"

interface ButtonProps {
  onClick: Function
  children?: React.ReactNode
  disabled?: boolean | null | undefined
}

const StyledButton = styled(Button)`
  width: 100%;
  max-width: unset;
  height: 40px;
  text-transform: uppercase;
  background-color: ${(props) => props.theme.colors.yellow};
  color: ${(props) => props.theme.colors.white};
  border: none;

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.extraSmall};
    height: 30px;
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.summaryGrey};
    color: ${(props) => props.theme.colors.lightGrey};
  }
`

export const AddButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <StyledButton
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        props.onClick(event)
      }
      disabled={props.disabled || false}
    >
      {props.children || <Trans>Add to cart</Trans>}
    </StyledButton>
  )
}
