import React from "react"
import {
  useAppSelector,
  selectSettings,
  SettingsState,
  initialSettingsState,
} from "./../state"

export const SettingsContext =
  React.createContext<SettingsState>(initialSettingsState)

export const SettingsProvider: React.FunctionComponent = ({ children }) => {
  const settings = useAppSelector(selectSettings)

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  )
}
