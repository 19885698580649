export const setDefaultLanguage = (defaultLanguage: boolean) => {
  localStorage.setItem("shop-default-language", defaultLanguage.toString())
}

export const getDefaultLanguage = (): boolean | null => {
  const defaultLanguage = localStorage.getItem("shop-default-language")
  return defaultLanguage === null ? defaultLanguage : defaultLanguage === "true"
}

export const setAgeConfirmation = (ageConfirmed: boolean) => {
  localStorage.setItem("shop-age-confirmed", ageConfirmed.toString())
}

export const getAgeConfirmation = (): boolean => {
  const ageConfirmed = localStorage.getItem("shop-age-confirmed")
  return ageConfirmed === "true"
}

export const removeAgeConfirmation = () => {
  localStorage.removeItem("shop-age-confirmed")
}

export const setAppNotificationIsOver = (appIsOver: boolean) => {
  localStorage.setItem("shop-app-notification-is-over", appIsOver.toString())
}

export const getAppNotificationIsOver = (): boolean => {
  const appIsOver = localStorage.getItem("shop-app-notification-is-over")
  return appIsOver === "true"
}

export const getRegionByDomain = () => {
  // eslint-disable-next-line no-restricted-globals
  const domain = location.host.split(".")
  if (domain.length === 2) {
    return domain[1].toLocaleLowerCase()
  } else {
    return null
  }
}
