import React from "react"
import styled from "styled-components"
import { size } from "../styles/device"
import CircularProgress from "@mui/material/CircularProgress"
import { Trans } from "@lingui/macro"
import { theme } from "../styles/theme"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${size.tablet}px) {
    min-height: calc(100% - ${(props) => props.theme.menu.height.mobile});
  }

  @media only screen and (min-width: ${size.tablet}px) {
    min-height: calc(100% - ${(props) => props.theme.menu.height.desktop});
  }
`

const Text = styled.div`
  margin-top: 8px;
`

export const LoadingFallback = () => (
  <Container>
    <CircularProgress sx={{ color: theme.colors.DARK_GOLD }} />
    <Text>
      <Trans>Loading</Trans>...
    </Text>
  </Container>
)

export default LoadingFallback
