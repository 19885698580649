import React from "react"
import {
  HttpContext,
  getAgeConfirmation,
  CACHED_QUERY_PARAMS,
  getRegionByDomain,
  SettingsContext,
} from "./../services"
import { CustomerDetails, Address, VatAddress, RegionPreview } from "../types"
import {
  useAppDispatch,
  initSettings,
  useAppSelector,
  initBadges,
  confirmAge,
  signIn,
  registerAddress,
  registerInvoice,
  initDiscounts,
  selectRegions,
  selectSelectedRegion,
  selectLanguages,
  selectActiveLanguage,
  initRegions,
  initActiveRegion,
  initLanguages,
  initActiveLanguage,
  selectDefaultLanguage,
  setDefaultLanguage as setDefaultLanguageStore,
  selectUser,
  clearOrder,
} from "../state"
import { useQuery } from "react-query"
import { getDeviceLanguage } from "./language"
import { getDefaultLanguage, setDefaultLanguage } from "./storage"
import { useLingui } from "@lingui/react"
import { useRememberUser } from "../hooks"
import { useNavigate } from "react-router-dom"
import { routes } from "../Routes"

const getRegion = (regions: RegionPreview[], domain: string) =>
  regions.find((region) => region.regionCode.toLocaleLowerCase() === domain)

export const LoadDefaultData = () => {
  const fetch = React.useContext(HttpContext)
  const dispatch = useAppDispatch()
  const regions = useAppSelector(selectRegions)
  const selectedRegion = useAppSelector(selectSelectedRegion)
  const languages = useAppSelector(selectLanguages)
  const activeLanguage = useAppSelector(selectActiveLanguage)
  const { settings } = React.useContext(SettingsContext)
  const usingDefaultLanguage = useAppSelector(selectDefaultLanguage)
  const { i18n } = useLingui()
  const user = useAppSelector(selectUser)
  const rememberUser = useRememberUser()
  const navigate = useNavigate()
  const [regionId, setRegionId] = React.useState(selectedRegion?.id || 0)

  React.useEffect(() => {
    if (regions.length > 0 && !selectedRegion) {
      let regionByDomain = getRegionByDomain()
      if (!regionByDomain) {
        const urlParts = window.location.hostname.split(".")
        const domain = urlParts[urlParts.length - 1]

        if (getRegion(regions, domain)) {
          regionByDomain = domain
        }
      }
      if (!regionByDomain) {
        navigate(routes.region.path)
      } else {
        const region = getRegion(regions, regionByDomain)
        dispatch(initActiveRegion(region as RegionPreview))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions])

  const regionsQuery = useQuery(
    ["regions"],
    () => fetch?.region.listRegionPreviews(),
    { ...CACHED_QUERY_PARAMS, refetchOnMount: true }
  )

  const languagesQuery = useQuery(
    ["languages", regionId],
    () => fetch?.region.listLanguages(regionId),
    {
      ...CACHED_QUERY_PARAMS,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!regionId,
    }
  )

  const settingsQuery = useQuery(
    ["settings", regionId],
    () => fetch?.region.getSettings(regionId),
    {
      ...CACHED_QUERY_PARAMS,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!regionId,
    }
  )

  const badgeQuery = useQuery(
    ["badges", regionId],
    () => fetch?.settings.listBadges(),
    { ...CACHED_QUERY_PARAMS, enabled: !!regionId }
  )

  const quantityDiscountQuery = useQuery(
    ["quantity-discounts", regionId],
    () => fetch?.region.listDiscounts(regionId),
    {
      ...CACHED_QUERY_PARAMS,
      cacheTime: 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!regionId,
    }
  )

  React.useEffect(() => {
    if (regionsQuery.data && regionsQuery.data.length > 0) {
      dispatch(initRegions(regionsQuery.data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionsQuery.data])

  React.useEffect(() => {
    if (languagesQuery.data && languagesQuery.data.length > 0) {
      dispatch(initLanguages(languagesQuery.data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languagesQuery.data])

  React.useEffect(() => {
    if (quantityDiscountQuery.data && quantityDiscountQuery.data.length > 0) {
      dispatch(initDiscounts(quantityDiscountQuery.data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityDiscountQuery.data])

  React.useEffect(() => {
    if (settingsQuery.data && settingsQuery.data.id) {
      dispatch(initSettings(settingsQuery.data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsQuery.data])

  React.useEffect(() => {
    if (badgeQuery.data && badgeQuery.data.length > 0) {
      dispatch(initBadges(badgeQuery.data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badgeQuery.data])

  React.useEffect(() => {
    dispatch(confirmAge(getAgeConfirmation()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (
      user === null &&
      rememberUser &&
      rememberUser.email &&
      rememberUser.password
    ) {
      fetch?.profile
        .login(rememberUser.email, rememberUser.password)
        .then(() => {
          fetch?.customer.get(0).then((customer: CustomerDetails) => {
            if (customer && customer.id && customer.email) {
              dispatch(signIn(customer))
              dispatch(clearOrder())

              fetch?.customer
                .getAddress(customer.id)
                .then((address: Address | null) => {
                  if (address && address.id) {
                    dispatch(registerAddress(address))
                  }
                })

              fetch?.customer
                .getInvoice(customer.id)
                .then((invoice: VatAddress | null) => {
                  if (invoice && invoice.id) {
                    dispatch(registerInvoice(invoice))
                  }
                })
            }
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rememberUser])

  // Default language setup
  React.useEffect(() => {
    if (settings.id && languages.length > 0) {
      const browserLanguage: string = getDeviceLanguage()
      const languagePrefix = browserLanguage.split("-")[0]
      let defaultLanguage: boolean | null = null
      let languageCode: string = ""

      if (activeLanguage) {
        defaultLanguage =
          activeLanguage.languageCode.toLocaleLowerCase() !==
          settings.secondaryLanguage.toLowerCase()
        languageCode = defaultLanguage
          ? settings.defaultLanguage.toLocaleLowerCase()
          : settings.secondaryLanguage.toLocaleLowerCase()
      } else {
        defaultLanguage = getDefaultLanguage()
        if (defaultLanguage === null) {
          defaultLanguage =
            languagePrefix.toLowerCase() !==
            settings.secondaryLanguage.toLowerCase()
        }
        languageCode = defaultLanguage
          ? settings.defaultLanguage.toLocaleLowerCase()
          : settings.secondaryLanguage.toLocaleLowerCase()
        const language = languages.find(
          (language) =>
            language.languageCode.toLocaleLowerCase() === languageCode
        )
        language && dispatch(initActiveLanguage(language))
      }
      setDefaultLanguage(defaultLanguage)
      defaultLanguage !== usingDefaultLanguage &&
        dispatch(setDefaultLanguageStore(defaultLanguage))

      console.log("selected language code", languageCode)
      i18n.activate(languageCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.id, languages])

  React.useEffect(() => {
    console.log("localization object changed", i18n._locale, i18n)
  }, [i18n, i18n._locale])

  React.useEffect(() => {
    if (selectedRegion) {
      setRegionId(selectedRegion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion])

  return <></>
}
