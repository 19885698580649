import React from "react"
import {
  useAppSelector,
  selectAgeConfirmed,
  useAppDispatch,
  confirmAge,
  selectSelectedRegion,
} from "./../state"
import {
  setAgeConfirmation,
  HttpContext,
  SettingsContext,
  getAgeConfirmation,
  CACHED_QUERY_PARAMS,
} from "./../services"
import { size } from "../styles"
import { useNavigate } from "react-router-dom"
import "react-gallery-carousel/dist/index.css"
import { MarketingImage, MarketingWelcomeMessage } from "../types"
import { useQuery } from "react-query"
import MediaQuery, { useMediaQuery } from "react-responsive"
import { MobileHome, DesktopHome, MobileAvailableDialog } from "./../components"
import styled from "styled-components"

const EmptyPage = styled.div`
  height: calc(100% - ${(props) => props.theme.menu.height.mobile});

  @media only screen and (min-width: ${size.tablet}px) {
    height: calc(100% - ${(props) => props.theme.menu.height.desktop});
  }
`

export const Home = () => {
  const fetch = React.useContext(HttpContext)
  const { defaultLanguage } = React.useContext(SettingsContext)
  const navigate = useNavigate()
  const isAgeConfirmed = useAppSelector(selectAgeConfirmed)
  const dispatch = useAppDispatch()
  const onConfirm = () => {
    dispatch(confirmAge(true))
    setAgeConfirmation(true)
  }
  const isDekstop = useMediaQuery({ query: `(min-width: ${size.tablet}px)` })
  const selectedRegion = useAppSelector(selectSelectedRegion)
  const imageQuery = useQuery(
    ["home-images", selectedRegion?.id || 1],
    () => fetch?.region.listWelcomeImages(selectedRegion?.id || 1, isDekstop),
    { ...CACHED_QUERY_PARAMS, enabled: false }
  )
  const [images, setImages] = React.useState<MarketingImage[]>(
    imageQuery.data || []
  )
  const messageQuery = useQuery(
    ["home-message", selectedRegion?.id || 1],
    () => fetch?.region.getWelcome(selectedRegion?.id || 1),
    { ...CACHED_QUERY_PARAMS, enabled: false }
  )
  const [welcome, setWelcome] = React.useState<MarketingWelcomeMessage>(
    messageQuery.data?.id
      ? messageQuery.data
      : {
          id: 0,
          defaultMessage: "",
          secondaryMessage: "",
        }
  )
  const [message, setMessage] = React.useState<string>(
    (defaultLanguage
      ? messageQuery.data?.defaultMessage
      : messageQuery.data?.secondaryMessage) || ""
  )

  React.useEffect(() => {
    if (selectedRegion) {
      imageQuery.refetch()
      messageQuery.refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion, isDekstop])

  React.useEffect(() => {
    if (
      imageQuery.data &&
      JSON.stringify(imageQuery.data) !== JSON.stringify(images)
    ) {
      setImages(imageQuery.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageQuery.data])

  React.useEffect(() => {
    if (
      messageQuery.data &&
      JSON.stringify(messageQuery.data) !== JSON.stringify(welcome)
    ) {
      setWelcome({
        ...welcome,
        defaultMessage: messageQuery.data.defaultMessage,
        secondaryMessage: messageQuery.data.secondaryMessage,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageQuery.data])

  React.useEffect(() => {
    setMessage(
      defaultLanguage ? welcome.defaultMessage : welcome.secondaryMessage
    )
  }, [welcome, defaultLanguage])

  React.useEffect(() => {
    dispatch(confirmAge(getAgeConfirmation()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const imagesMemo = React.useMemo(() => images, [images])
  const messageMemo = React.useMemo(() => message, [message])

  return (
    <>
      <MediaQuery maxWidth={size.tablet}>
        {(imagesMemo && imagesMemo.length > 0) || messageMemo ? (
          <MobileHome
            images={imagesMemo}
            message={messageMemo}
            isAgeConfirmed={isAgeConfirmed}
            navigate={navigate}
            onConfirm={onConfirm}
          />
        ) : (
          <EmptyPage />
        )}
      </MediaQuery>
      <MediaQuery minWidth={size.tablet}>
        {(imagesMemo && imagesMemo.length > 0) || messageMemo ? (
          <DesktopHome
            images={imagesMemo}
            message={messageMemo}
            defaultLanguage={defaultLanguage}
            navigate={navigate}
            isAgeConfirmed={isAgeConfirmed}
            onConfirm={onConfirm}
          />
        ) : (
          <EmptyPage />
        )}
      </MediaQuery>
      <MobileAvailableDialog />
    </>
  )
}
