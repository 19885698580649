import {
  startOrderProcess,
  successOrderProcess,
  failOrderProcess,
} from "./../state"
import { Order } from "./../types"

export const saveOrderThunk =
  (saveOrder: Function, order: Order, callback?: Function) =>
  async (dispatch: Function) => {
    dispatch(startOrderProcess())
    try {
      const response = await saveOrder(order)
      if (response.status && response.status === 200) {
        setTimeout(() => dispatch(successOrderProcess()), 2000)
        callback && callback()
      } else {
        dispatch(failOrderProcess())
      }
    } catch (e) {
      dispatch(failOrderProcess())
    }
  }
