import React from "react"
import styled from "styled-components"
import DividerPng from "../assets/icons/csik.png"

const Divider = styled.img`
  width: 100%;
`

interface GoldDividerProps {
  className?: string | any
  style?: React.CSSProperties
}

export const GoldDivider: React.FC<GoldDividerProps> = ({
  className,
  style,
}) => <Divider className={className} src={DividerPng} style={style} />
