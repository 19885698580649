import React, { useState } from "react"
import styled from "styled-components"
import { FlexColumnBox, Button, CloseButton, Close } from "../styles"
import {
  DialogContent as MuiDialogContent,
  Slide,
  Dialog as MuiDialog,
} from "@mui/material"
import { Trans } from "@lingui/macro"
import { TransitionProps } from "@mui/material/transitions"
import { ReactComponent as AppStoreIcon } from "./../assets/appstore-badge.svg"
import { ReactComponent as GooglePlayIcon } from "./../assets/google-play-badge.svg"
import {
  selectAppNotification,
  useAppSelector,
  selectAgeConfirmed,
  useAppDispatch,
  setAppNotification,
  selectAppIsAvilable,
} from "../state"
import {
  getAppNotificationIsOver,
  setAppNotificationIsOver,
  SettingsContext,
} from "../services"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paper {
    background-color: ${(props) => props.theme.colors.DARK_GREY};
  }
`

const ContentTitle = styled.div`
  font-size: ${(props) => props.theme.fonts.size.medium};
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.colors.DARK_GOLD};
`
const ContentSubTitle = styled.div`
  font-size: ${(props) => props.theme.fonts.size.extraSmall};
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white};
`
const DialogContent = styled(MuiDialogContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
`
const GooglePlay = styled(GooglePlayIcon)`
  height: 55px;
  width: 140px;
`

const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.horizontal {
    flex-direction: row;
    column-gap: 8px;
  }
`

const StoreButton = styled(Button)`
  background-color: transparent;
  height: auto;
`

const CloseBtn = styled(CloseButton)`
  height: 12px;
  width: 12px;
`

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface PureAppButtonsProps {
  onClick: Function
  isHorizontal?: boolean
}

export const PureAppButtons: React.FunctionComponent<PureAppButtonsProps> = ({
  onClick,
  isHorizontal,
}) => {
  const openAppStore = () => {
    // if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
    onClick()
    window.location.replace(settings.appStoreLink)
    // }
  }

  const openGooglePlay = () => {
    // if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
    onClick()
    window.location.replace(settings.googlePlayLink)
    // }
  }
  const { settings } = React.useContext(SettingsContext)
  return (
    <ActionContainer className={isHorizontal ? "horizontal" : ""}>
      {settings.appStoreActive && (
        <StoreButton onClick={openAppStore}>
          <AppStoreIcon />
        </StoreButton>
      )}
      {settings.googlePlayActive && (
        <StoreButton onClick={openGooglePlay}>
          <GooglePlay />
        </StoreButton>
      )}
    </ActionContainer>
  )
}

export const MobileAvailableDialog = () => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const isAppNotiOver = useAppSelector(selectAppNotification)
  const isAgeConfirmed = useAppSelector(selectAgeConfirmed)
  const appIsActive = useAppSelector(selectAppIsAvilable)
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(setAppNotification(true))
    setAppNotificationIsOver(true)
    setOpen(false)
  }

  React.useEffect(() => {
    appIsActive &&
      isAgeConfirmed &&
      !getAppNotificationIsOver() &&
      setOpen(!isAppNotiOver)
  }, [isAppNotiOver, isAgeConfirmed, appIsActive])

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onBackdropClick={() => onClose()}
      onClose={() => onClose()}
    >
      <DialogContent>
        <ContentTitle>
          <Trans>Use our mobile app</Trans>
          <CloseBtn onClick={() => onClose()}>
            <Close />
          </CloseBtn>
        </ContentTitle>
        <FlexColumnBox>
          <ContentSubTitle>
            <Trans>And enjoy the benefits and features</Trans>
          </ContentSubTitle>
          <PureAppButtons onClick={onClose} />
        </FlexColumnBox>
      </DialogContent>
    </Dialog>
  )
}
