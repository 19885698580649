export enum AlertType {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
}

export interface Alert {
  type: AlertType
  message: string
  id: string
}
