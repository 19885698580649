import React from "react"
import Skeleton from "@mui/material/Skeleton"

export const ImageLoadingSkeleton = () => (
  <>
    <Skeleton
      animation="pulse"
      variant="rectangular"
      sx={{ width: "150px", height: "60%", margin: "0 auto" }}
    />
    <Skeleton
      animation="pulse"
      variant="text"
      sx={{ width: "150px", height: "10%", margin: "0 auto" }}
    />
    <Skeleton
      animation="pulse"
      variant="rectangular"
      sx={{ width: "150px", height: "30%", margin: "0 auto" }}
    />
  </>
)
