import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState } from "./store"
import {
  Order,
  emptyOrder,
  Payment,
  emptyVatAddress,
  ItemPreview,
  Item,
  OrderedItem,
  OrderProcessStatus,
  Address,
  VatAddress,
  Registration,
  emptyRegistration,
} from "./../types"
import { lowestPrice } from "./../services"

interface OrderState {
  order: Order
  itemsInOrder: number
  processStatus: OrderProcessStatus
  registration: Registration
}

const initialState: OrderState = {
  order: emptyOrder as Order,
  itemsInOrder: 0,
  processStatus: OrderProcessStatus.NOT_STARTED,
  registration: emptyRegistration,
}

const itemIsInOrder = (
  orderedItems: OrderedItem[],
  newItem: ItemPreview
): boolean => {
  const itemExists: boolean =
    orderedItems.findIndex(
      (orderedItem: OrderedItem) => orderedItem.item.id === newItem.id
    ) > -1
  return itemExists
}

const addItem = (state: OrderState, item: ItemPreview): OrderState => {
  const order: Order = { ...state.order }
  const orderedItems: OrderedItem[] = [...order.items]
  let itemsInOrder = state.itemsInOrder
  if (itemIsInOrder(order.items, item)) {
    let orderedItem: OrderedItem | undefined = orderedItems.find(
      (orderedItem: OrderedItem) => orderedItem.item.id === item.id
    )
    const index: number = orderedItems.findIndex(
      (orderedItem: OrderedItem) => orderedItem.item.id === item.id
    )
    if (orderedItem && orderedItem.orderedAmount < orderedItem.item.amount) {
      orderedItem = { ...orderedItem }
      orderedItem.orderedAmount = orderedItem.orderedAmount + 1
      orderedItem.totalPrice =
        orderedItem.totalPrice + lowestPrice(item.price, item.discount)
      orderedItems.splice(index, 1, orderedItem)
      itemsInOrder = itemsInOrder + 1
      order.totalPrice = order.totalPrice + orderedItem.totalPrice
    }
  } else {
    const newOrderedItem: OrderedItem = {
      item: {
        id: item.id,
        amount: item.amount,
        price: item.price,
        discount: item.discount,
        name: item.name,
        manufacturerName: item.manufacturer?.name || "",
        images: [...item.images],
      } as Item,
      orderedAmount: 1,
      totalPrice: lowestPrice(item.price, item.discount),
    } as OrderedItem
    orderedItems.push(newOrderedItem)
    itemsInOrder = itemsInOrder + 1
    order.totalPrice = order.totalPrice + newOrderedItem.totalPrice
  }
  return {
    ...state,
    order: { ...order, items: orderedItems },
    itemsInOrder,
  } as OrderState
}

const updateOrder = (state: OrderState, order: OrderedItem): OrderState => {
  const orderState: Order = { ...state.order }
  const index = orderState.items.findIndex(
    (orderedItem: OrderedItem) => orderedItem.item.id === order.item.id
  )
  const orderedItems = [...orderState.items]
  let itemsInOrder = state.itemsInOrder
  if (index > -1) {
    const orderedItem = { ...orderedItems[index] }
    const increased: boolean = orderedItem.orderedAmount < order.orderedAmount
    const price = lowestPrice(orderedItem.item.price, orderedItem.item.discount)
    orderedItem.orderedAmount = order.orderedAmount
    orderedItem.totalPrice = increased
      ? orderedItem.totalPrice + price
      : orderedItem.totalPrice - price
    itemsInOrder = increased ? itemsInOrder + 1 : itemsInOrder - 1
    orderedItems.splice(index, 1, orderedItem)
    orderState.totalPrice = increased
      ? orderState.totalPrice + price
      : orderState.totalPrice - price
  }
  return {
    ...state,
    order: { ...orderState, items: orderedItems },
    itemsInOrder,
  }
}

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addItemToOrder: (state, action: PayloadAction<ItemPreview>) => {
      return addItem(state, action.payload)
    },
    updateItemInOrder: (state, action: PayloadAction<OrderedItem>) => {
      return updateOrder(state, action.payload) as OrderState
    },
    updateOrderWithItem: (state, action: PayloadAction<ItemPreview>) => {
      const orderedItem: OrderedItem | undefined = state.order.items.find(
        (orderedItem: OrderedItem) => orderedItem.item.id === action.payload.id
      )
      if (orderedItem) return updateOrder(state, orderedItem) as OrderState
      else return state
    },
    removeItemFromOrder: (state, action: PayloadAction<number>) => {
      const id = action.payload
      const order = { ...state.order }
      const orderedItems = [...order.items]
      const index = orderedItems.findIndex(
        (orderedItem: OrderedItem) => orderedItem.item.id === id
      )
      let itemsInOrder = state.itemsInOrder
      if (index > -1) {
        itemsInOrder = itemsInOrder - orderedItems[index].orderedAmount
        order.totalPrice = order.totalPrice - orderedItems[index].totalPrice
        orderedItems.splice(index, 1)
      }
      return {
        ...state,
        order: { ...order, items: orderedItems },
        itemsInOrder,
      }
    },
    updateOrderAtCheckout: (state, action: PayloadAction<Partial<Order>>) => {
      const items = state.order.items
      return { ...state, order: { ...state.order, ...action.payload, items } }
    },
    confirmConditions: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        order: { ...state.order, conditionsConfirmed: action.payload },
      }
    },
    updatePayment: (state, action: PayloadAction<Payment>) => {
      return {
        ...state,
        order: { ...state.order, payment: action.payload },
      }
    },
    updateInvoiceShippingRelation: (state, action: PayloadAction<boolean>) => {
      let invoice = state.order.invoice
      if (action.payload) {
        invoice = { ...emptyVatAddress }
      }

      return {
        ...state,
        order: {
          ...state.order,
          invoice: invoice,
          invoiceSameAsShipping: action.payload,
        },
      }
    },
    clearOrder: () => {
      return { ...initialState }
    },
    startOrderProcess: (state) => {
      return { ...state, processStatus: OrderProcessStatus.IN_PROGRESS }
    },
    successOrderProcess: (state) => {
      return { ...state, processStatus: OrderProcessStatus.SUCCESS }
    },
    failOrderProcess: (state) => {
      return { ...state, processStatus: OrderProcessStatus.FAILED }
    },
    resetOrderProcess: (state) => {
      return { ...state, processStatus: OrderProcessStatus.NOT_STARTED }
    },
    updateShipping: (state, action: PayloadAction<Address>) => {
      return {
        ...state,
        order: {
          ...state.order,
          shipping: action.payload,
        },
      }
    },
    updateInvoice: (state, action: PayloadAction<VatAddress>) => {
      return {
        ...state,
        order: {
          ...state.order,
          invoice: action.payload,
        },
      }
    },
    updateRegistration: (state, action: PayloadAction<Registration>) => {
      return {
        ...state,
        registration: action.payload,
      }
    },
    resetRegistration: (state) => {
      return {
        ...state,
        registration: emptyRegistration,
      }
    },
  },
})

export const {
  updateOrderAtCheckout,
  confirmConditions,
  updatePayment,
  updateInvoiceShippingRelation,
  addItemToOrder,
  removeItemFromOrder,
  updateItemInOrder,
  clearOrder,

  startOrderProcess,
  successOrderProcess,
  failOrderProcess,
  resetOrderProcess,

  updateShipping,
  updateInvoice,

  updateRegistration,
  resetRegistration,
} = orderSlice.actions

export const selectOrder = (state: AppState) => state.order.order
export const selectForCheckout = (state: AppState): Partial<Order> => {
  return { ...state.order.order, items: undefined }
}
export const selectNoItemsInOrder = (state: AppState) =>
  state.order.itemsInOrder
export const selectOrderProcessState = (state: AppState) =>
  state.order.processStatus
export const selectOrderRegistration = (state: AppState) =>
  state.order.registration

export default orderSlice.reducer
