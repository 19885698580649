export const getZoneOffset = () => {
  const offset = new Date().getTimezoneOffset()
  return -offset
}

export const getTimeZoneOffset = () => {
  const offset = getZoneOffset()
  const sign = offset < 0 ? -1 : 1
  const offsetHour = Math.floor(offset / 60) * sign
  const offsetMinutes = offset * sign - offsetHour * 60

  return `${sign < 0 ? "+" : "-"}${
    offsetHour < 10 ? "0" + offsetHour : offsetHour
  }${offsetMinutes < 10 ? "0" + offsetMinutes : offsetMinutes}`
}

export const getIANATimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone
