// @ts-nocheck
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import itemReducer from "./itemSlice"
import settingsReducer from "./settingsSlice"
import orderReducer from "./orderSlice"
import alertReducer from "./alertSlice"
import userReducer from "./userSlice"

export const store = configureStore({
  reducer: {
    items: itemReducer,
    settings: settingsReducer,
    order: orderReducer,
    alerts: alertReducer,
    user: userReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>
