import { AxiosInstance } from "axios"
import {
  UserRegistration,
  AlertType,
  ResetPassword as ResetPasswordType,
  PasswordChange,
  CheckoutFormProps as OrderRegistration,
  OrderedItem,
} from "./../types"
import { notifyUser } from "./../state"

const hashPassword = async (password: string) => {
  const encoder = new TextEncoder()
  const passwordUint8 = encoder.encode(password)
  const passwordBuffer = await crypto.subtle.digest("SHA-256", passwordUint8)
  const passwordArray = Array.from(new Uint8Array(passwordBuffer))
  const passwordHex = passwordArray
    .map((bit) => bit.toString(16).padStart(2, "0"))
    .join("")

  return passwordHex
}

export const createProfileFetch = (
  axios: AxiosInstance,
  dispatch: Function
) => {
  return {
    register: async (user: UserRegistration) => {
      const hashedUser: UserRegistration = {
        ...user,
        password: await hashPassword(user.password),
        confirmPassword: await hashPassword(user.confirmPassword),
      }

      return axios
        .post<number>("/register/", hashedUser)
        .then((response: any) => {
          return response.data as number
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during user registration. Please try it later.",
            })
          )
          console.error("User registration error.", e)
          throw e
        })
    },
    registerAndOrder: async (order: OrderRegistration) => {
      const hashedOrder: OrderRegistration = {
        ...order,
        password: await hashPassword(order.password),
        confirmPassword: await hashPassword(order.confirmPassword),
        items: order.items.map((orderedItem: OrderedItem) => {
          const newOrderedItem = {
            ...orderedItem,
            item: { ...orderedItem.item, images: [] },
          }
          return newOrderedItem
        }),
      }

      return axios.post("/register/order/", hashedOrder).catch((e: any) => {
        throw e
      })
    },
    login: async (email: string, password: string) =>
      axios
        .post("/login", {
          email,
          password: await hashPassword(password),
        })
        .then((response: any) => {
          dispatch(
            notifyUser({
              type: AlertType.SUCCESS,
              message: "Login succeed",
            })
          )
          return response.data
        })
        .catch((e: any) => {
          //TODO
          throw e
        }),
    logout: async () =>
      axios
        .get("/logout/")
        .then((response: any) => {
          dispatch(
            notifyUser({
              type: AlertType.SUCCESS,
              message: "Logout succeed",
            })
          )
          return response.data
        })
        .catch((e: any) => {
          //TODO
          throw e
        }),
    passwordReset: (email: string, regionId: number) =>
      axios
        .get<boolean>(
          `/reset-password/email?email=${email}&regionId=${regionId}`
        )
        .then((response: any) => {
          return response.data as boolean
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during requesting password reset email. Please try it later.",
            })
          )
          console.error("Password reset request error.", e)
          throw e
        }),
    resetPasswordWithToken: async (passwordReset: ResetPasswordType) => {
      const passwordObject = {
        ...passwordReset,
        newPassword: await hashPassword(passwordReset.newPassword),
        confirmPassword: await hashPassword(passwordReset.confirmPassword),
      } as ResetPasswordType
      return axios
        .post<boolean>(`/reset-password/reset`, passwordObject)
        .then((response: any) => {
          return response.data as boolean
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during requesting password reset email. Please try it later.",
            })
          )
          console.error("Password reset request error.", e)
          throw e
        })
    },
    changePassword: async (password: PasswordChange) => {
      const passwordObject = {
        currentPassword: await hashPassword(password.currentPassword),
        newPassword: await hashPassword(password.newPassword),
        confirmPassword: await hashPassword(password.confirmPassword),
      } as PasswordChange
      return axios
        .put<boolean>(`/reset-password/`, passwordObject)
        .then((response: any) => {
          return response.data as boolean
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during password change. Please try it later.",
            })
          )
          console.error("Password change error.", e)
          throw e
        })
    },
    resendConfirmation: async (email: string) =>
      axios
        .get(`/register/resend-confirmation?email=${email}`)
        .then((response: any) => {
          dispatch(
            notifyUser({
              type: AlertType.SUCCESS,
              message: "Verification email has been sent.",
            })
          )
          return response.data
        })
        .catch((e: any) => {
          //TODO
          throw e
        }),
  }
}
