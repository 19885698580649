import { AxiosInstance } from "axios"
import { AlertType, VatAddress, emptyVatAddress } from "./../types"
import { notifyUser } from "./../state"

export const createInvoiceFetch = (
  axios: AxiosInstance,
  dispatch: Function
) => {
  return {
    save: async (invoice: VatAddress) => {
      return await axios.post(`/invoice/`, invoice).catch((e: any) => {
        dispatch(
          notifyUser({
            type: AlertType.ERROR,
            message:
              "Error happened during saving invoice. Please try it later.",
          })
        )
        console.error("Invoice saving error.", e)
        throw e
      })
    },
    update: async (invoice: VatAddress) => {
      return await axios.put(`/invoice/`, invoice).catch((e: any) => {
        dispatch(
          notifyUser({
            type: AlertType.ERROR,
            message:
              "Error happened during updating invoice. Please try it later.",
          })
        )
        console.error("Invoice updating error.", e)
        throw e
      })
    },
    get: (id: number) =>
      axios
        .get<VatAddress>(`/invoice/${id}`)
        .then((response: any) => {
          return response.data as VatAddress
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading invoice. Please try it later.",
            })
          )
          console.error("Invoice loading error.", e)
          return emptyVatAddress as VatAddress
        }),
    list: () =>
      axios
        .get<VatAddress[]>(`/invoice/`)
        .then((response: any) => {
          return response.data as VatAddress[]
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading invoices. Please try it later.",
            })
          )
          console.error("Invoices loading error.", e)
          return []
        }),
    delete: (id: number) =>
      axios
        .delete(`/invoice/${id}`)
        .then((response: any) => {
          return response.data
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during deleting invoice. Please try it later.",
            })
          )
          console.error("Invoice deleting error.", e)
          return {}
        }),
  }
}
