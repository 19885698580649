import React, { FunctionComponent } from "react"
import { Button, size } from "./../styles"
import styled from "styled-components"

interface ButtonProps {
  onClick: Function
  children?: React.ReactNode
  disabled?: boolean | null | undefined
  type?: "button" | "submit"
  style?: React.CSSProperties
  className?: string
}

const StyledButton = styled(Button)`
  width: 100%;
  max-width: unset;
  height: 34px;
  text-transform: uppercase;
  background: ${(props) => props.theme.gradients.GOLD};
  color: ${(props) => props.theme.colors.DARK_BLACK};
  font-family: ${(props) => props.theme.fonts.family.bold};
  border: none;
  border-radius: 2px;

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.extraSmall};
    height: 30px;
  }
`

export const GoldButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <StyledButton
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        props.onClick(event)
      }
      disabled={props.disabled || false}
      type={props.type ? props.type : "button"}
      style={props.style || {}}
      className={props.className || ""}
    >
      {props.children}
    </StyledButton>
  )
}

const StyledSmallButton = styled(Button)`
  padding-left: 12px;
  padding-right: 12px;
  max-width: unset;
  height: 20px;
  text-transform: uppercase;
  background: ${(props) => props.theme.gradients.GOLD};
  color: ${(props) => props.theme.colors.DARK_BLACK};
  font-family: ${(props) => props.theme.fonts.family.bold};
  border: none;
  border-radius: 2px;
`

export const SmallGoldButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <StyledSmallButton
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        props.onClick(event)
      }
    >
      {props.children}
    </StyledSmallButton>
  )
}

const StyledGradientButton = styled(StyledButton)`
  background: ${(props) => props.theme.gradients.GREY_BUTTON};
  color: ${(props) => props.theme.colors.DARK_GOLD};
  font-family: ${(props) => props.theme.fonts.family.regular};

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.extraSmall};
    height: 30px;
  }
`

export const GradientButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <StyledGradientButton
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        props.onClick(event)
      }
      disabled={props.disabled || false}
      type={props.type ? props.type : "button"}
      style={props.style || {}}
      className={props.className || ""}
    >
      {props.children}
    </StyledGradientButton>
  )
}
