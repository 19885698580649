import React from "react"
import { FlexBox } from "./../styles"
import styled from "styled-components"
import { ReactComponent as BackIcon } from "./../assets/icons/nyil.svg"
import { useNavigate } from "react-router-dom"
import { Trans } from "@lingui/macro"

const BackFlexBox = styled(FlexBox)`
  &.absolute {
    position: absolute;
    left: 5%;
  }
`

const BackBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${(props) => props.theme.fonts.size.extraSmall};
  font-size: ${(props) => props.theme.fonts.size.extraSmall};
  color: ${(props) => props.theme.colors.DARK_GOLD};
  position: relative;
  left: -16px;
`
const Back = styled(BackIcon)`
  height: ${(props) => props.theme.fonts.size.extraSmall};
  width: ${(props) => props.theme.fonts.size.extraSmall};
  fill: ${(props) => props.theme.colors.DARK_GOLD};
`

interface BackButtonProps {
  className?: string
}

export const BackButton: React.FunctionComponent<BackButtonProps> = ({
  className,
}) => {
  const navigate = useNavigate()
  return (
    <BackFlexBox className={className}>
      <BackBox onClick={() => navigate(-1)}>
        <Back /> <Trans>Back</Trans>
      </BackBox>
    </BackFlexBox>
  )
}
