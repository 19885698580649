import { AxiosInstance } from "axios"
import {
  MarketingImage,
  AlertType,
  MarketingWelcomeMessage,
  MarketingMessage,
  MarketingMessageByLanguage,
  PaymentType,
  MarketingMediaMessages,
} from "./../types"
import { notifyUser } from "./../state"

export const createMarketingFetch = (
  axios: AxiosInstance,
  dispatch: Function
) => {
  return {
    listImages: (isDesktop: boolean) =>
      axios
        .get<MarketingImage[]>(
          `/marketing-image/${isDesktop ? "desktop" : "mobile"}`
        )
        .then((response: any) => {
          return response.data as MarketingImage[]
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing images. Please try it later.",
            })
          )
          console.error("Marketing images loading error.", e)
          throw e
        }),
    listMediaMessages: () =>
      axios
        .get<MarketingMediaMessages[]>(`/marketing-image/messages`)
        .then((response: any) => {
          return response.data as MarketingMediaMessages[]
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing media messages. Please try it later.",
            })
          )
          console.error("Marketing media messages loading error.", e)
          return []
        }),
    getWelcome: () =>
      axios
        .get<MarketingWelcomeMessage>("/marketing/welcome")
        .then((response: any) => {
          return response.data as MarketingWelcomeMessage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing welcome. Please try it later.",
            })
          )
          console.error("Marketing welcome loading error.", e)
          throw e
        }),
    getPaymentForOrder: (type: PaymentType) =>
      axios
        .get<MarketingMessage>("/marketing/" + type)
        .then((response: any) => {
          return response.data as MarketingMessage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message: `Error happened during loading marketing ${type}. Please try it later.`,
            })
          )
          console.error(`Marketing ${type} loading error.`, e)
          throw e
        }),
    getPayment: () =>
      axios
        .get<MarketingMessageByLanguage>("/marketing/payment")
        .then((response: any) => {
          return response.data as MarketingMessageByLanguage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading payment. Please try it later.",
            })
          )
          console.error("Payment loading error.", e)
          throw e
        }),
    getContacts: () =>
      axios
        .get<MarketingMessageByLanguage>("/marketing/contacts")
        .then((response: any) => {
          return response.data as MarketingMessageByLanguage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing contacts. Please try it later.",
            })
          )
          console.error("Marketing contacts loading error.", e)
          throw e
        }),
    getTerms: () =>
      axios
        .get<MarketingMessageByLanguage>("/marketing/terms")
        .then((response: any) => {
          return response.data as MarketingMessageByLanguage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing terms and conditions. Please try it later.",
            })
          )
          console.error("Marketing terms loading error.", e)
          throw e
        }),
    getPolicy: () =>
      axios
        .get<MarketingMessageByLanguage>("/marketing/policy")
        .then((response: any) => {
          return response.data as MarketingMessageByLanguage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing policy. Please try it later.",
            })
          )
          console.error("Marketing policy loading error.", e)
          throw e
        }),
    getAboutUs: () =>
      axios
        .get<MarketingMessageByLanguage>("/marketing/about-us")
        .then((response: any) => {
          return response.data as MarketingMessageByLanguage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading marketing about us. Please try it later.",
            })
          )
          console.error("Marketing about us loading error.", e)
          throw e
        }),
  }
}
