import React from "react"
import { createHttpService, HttpContext, createServices } from "."
import { useAppDispatch, signOut } from "./../state"
import { useNavigate, useLocation } from "react-router-dom"

export const HttpProvider: React.FunctionComponent = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const axios = createHttpService(
    process?.env?.REACT_APP_BASE_URL || "http://localhost:8080",
    navigate,
    location,
    () => dispatch(signOut())
  )

  return (
    <HttpContext.Provider value={createServices(axios, dispatch)}>
      {children}
    </HttpContext.Provider>
  )
}
