import React from "react"
import TextField from "@mui/material/TextField"
import styled from "styled-components"
import { size } from "../styles"
import { OutlinedInputProps } from "@mui/material/OutlinedInput"

const InputWrapper = styled.div`
  width: 100%;

  .MuiTextField-root {
    width: 100%;

    .MuiInputBase-root {
      color: ${(props) => props.theme.colors.DARK_GOLD};
      background: ${(props) => props.theme.gradients.GREY};
      font-family: ${(props) => props.theme.fonts.family.regular};

      input {
        color: ${(props) => props.theme.colors.DARK_GOLD};

        @media only screen and (max-width: ${size.tablet}px) {
          padding: 6px 14px;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }
`

interface GoldInputProps {
  placeholder?: string
  value?: string | number
  onBlur: Function
  error?: string | undefined | null
  type?: React.HTMLInputTypeAttribute
  InputProps?: Partial<OutlinedInputProps>
}

export const GoldInput: React.FC<GoldInputProps> = ({
  placeholder,
  value,
  onBlur,
  error,
  type = "text",
  InputProps,
}) => {
  const [state, setState] = React.useState(value)
  const input = React.useRef(null)

  React.useEffect(() => {
    setState(value)
  }, [value])

  return (
    <InputWrapper>
      <TextField
        placeholder={placeholder}
        size="small"
        type={type}
        variant="outlined"
        error={Boolean(error)}
        helperText={error || ""}
        value={state}
        onChange={(e) => {
          setState(e.target.value)
          document.activeElement !==
            (input.current as unknown as HTMLElement).querySelector(
              "input.MuiOutlinedInput-input"
            ) && onBlur(e.target.value)
        }}
        onBlur={() => onBlur(state)}
        ref={input}
        InputProps={InputProps}
      />
    </InputWrapper>
  )
}

export default GoldInput
