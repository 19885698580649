export enum PaymentType {
  CARD = "card",
  ON_DELIVERY = "on-delivery",
  QR = "qr",
  BANK_TRANSFER = "bank-transfer",
}

export interface Payment {
  id: number
  defaultName: string
  secondaryName: string
  defaultDescription: string
  secondaryDescription: string
  deliveryFee: number
  active: boolean
  isArchived: boolean
  isDefault: boolean
  type: PaymentType
}

export const emptyPayment: Partial<Payment> = {
  defaultName: "Card",
  secondaryName: "Card",
  defaultDescription: "",
  secondaryDescription: "",
  deliveryFee: 0,
  isDefault: true,
  type: PaymentType.ON_DELIVERY,
}
