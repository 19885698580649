import { Payment, Delivery } from "."

export interface General {
  id: number
  defaultLanguage: string
  secondaryLanguage: string
  valuta: string
  valutaVisibility: boolean
  valutaPrefix: boolean
  defaultCountry: string
  secondaryCountry: string
  vat: number
  vatActive: boolean
  appStoreLink: string
  appStoreActive: boolean
  googlePlayLink: string
  googlePlayActive: boolean
}

export interface Settings extends General {
  payments: Payment[]
  delivery: Delivery[]
}

export const emptySettings: Settings = {
  id: 0,
  defaultLanguage: "hu",
  secondaryLanguage: "en",
  valuta: "",
  valutaVisibility: false,
  valutaPrefix: false,
  defaultCountry: "",
  secondaryCountry: "",
  payments: [],
  delivery: [],
  vat: 0,
  vatActive: false,
  appStoreLink: "",
  appStoreActive: false,
  googlePlayLink: "",
  googlePlayActive: false,
}

export interface PriceSettings {
  valuta: string
  prefix: boolean
}

export interface QRSettings {
  defaultMessage: string
  secondaryMessage: string
}
