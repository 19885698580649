import React from "react"
import { FlexColumnBox, SpacedFlexBox } from "../styles"
import "react-gallery-carousel/dist/index.css"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import {
  selectRegions,
  useAppDispatch,
  useAppSelector,
  initActiveRegion,
  clearOrder,
  selectUserSignIn,
} from "../state"
import { RegionPreview } from "../types"
import { v4 as uuid } from "uuid"
import { Trans } from "@lingui/macro"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import { HttpContext } from "../services"

const HomeContainer = styled(FlexColumnBox)`
  width: 100%;
  height: calc(100% - ${(props) => props.theme.menu.height.mobile});
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: 16px;
  }
`

const Box = styled.div`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  padding: 16px;
  border-radius: 4px;
  margin: 4px 8px;
  width: 250px;
  min-height: 250px;
`

const BoxHeader = styled(SpacedFlexBox)`
  margin-bottom: 8px;
  align-items: center;
  > div {
    margin: 0;
  }
`

const Header = styled.div`
  font-weight: bold;
  font-size: ${(props) => props.theme.fonts.size.great};
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.lightGrey};
`

export const Region = () => {
  const navigate = useNavigate()
  const regions = useAppSelector(selectRegions)
  const dispatch = useAppDispatch()
  const fetch = React.useContext(HttpContext)
  const isSignedIn = useAppSelector(selectUserSignIn)

  const pressRegion = (region: RegionPreview) => {
    isSignedIn && fetch?.customer.setPreferredRegion(region.id)
    if (region.url) {
      window.location.replace(region.url)
    }
    dispatch(initActiveRegion(region))
    dispatch(clearOrder())
    navigate.length === 0 ? navigate("Home") : navigate(-1)
  }

  return (
    <HomeContainer>
      <Box>
        <BoxHeader style={{ justifyContent: "center" }}>
          <Header>
            <Trans>Regions</Trans>
          </Header>
        </BoxHeader>
        <Stack spacing={1} direction="column">
          {regions.map((region: RegionPreview) => (
            <Button
              variant="text"
              key={uuid()}
              onClick={() => pressRegion(region)}
            >
              {region.region}
            </Button>
          ))}
        </Stack>
      </Box>
    </HomeContainer>
  )
}
