import { Address, VatAddress, emptyAddress, emptyVatAddress } from "."

export interface CustomerCategory {
  id: number
  name: string
  promotion?: number
}

export interface UserRegistration {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
  regionId: number
}

export interface QuantityDiscount {
  id: number
  name: string
  discount: number
  applyFrom: number
}

export interface CustomerDetails {
  id: number
  firstName: string
  lastName: string
  email: string
  phone: string
  category?: CustomerCategory
  enabled: boolean
}

export interface Customer extends CustomerDetails {
  shipping: Address[]
  invoice?: VatAddress[]
}

export const emptyCustomer: Partial<Customer> = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  shipping: [emptyAddress],
  invoice: [emptyVatAddress],
  category: { id: 1, name: "Visitor" },
}

export const emptyCustomerDetails: Partial<CustomerDetails> = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
}

export interface Registration {
  isSignUp: boolean
  password: string
  confirmPassword: string
}

export const emptyRegistration: Registration = {
  isSignUp: false,
  password: "",
  confirmPassword: "",
}
