import React, { FunctionComponent } from "react"
import { OrderedItem, ItemImage } from "./../types"
import styled from "styled-components"
import {
  FlexBox,
  FlexColumnBox,
  SpacedFlexBox,
  CloseButton,
  Close,
  size,
  DefaultImage,
  GoldDividerStyle,
} from "./../styles"
import { CounterButton, ImageLoadingSkeleton } from "."
import {
  SettingsContext,
  CACHED_QUERY_PARAMS,
  lowestPrice,
  getFormattedPrice,
  HttpContext,
} from "./../services"
import MediaQuery from "react-responsive"
import { useQuery } from "react-query"

const CartCardComponent = styled(FlexColumnBox)`
  padding: 8px 2px;
  width: 100%;
  margin-bottom: -1px;

  @media only screen and (min-width: ${size.tablet}px) {
    row-gap: unset;

    .cart-card-desktop-information {
      flex-grow: 1;
      justify-content: space-between;
    }
  }
`

const CartCardInformation = styled(FlexBox)`
  column-gap: 32px;

  &.cart-card-mobile-information {
    justify-content: space-between;

    > button {
      align-self: flex-start;
    }
  }

  @media only screen and (min-width: ${size.tablet}px) {
    .cart-card-devider {
      margin: 4px 0;
    }
  }
`
const CartCardAction = styled(FlexBox)`
  column-gap: 16px;
  align-items: center;
  justify-content: space-between;
`
const Price = styled.div`
  font-weight: 900;
  font-size: ${(props) => props.theme.fonts.size.small};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.family.regular};

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.small};
  }
`
const ImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 116px;
  min-width: 116px;
  height: 50px;

  @media only screen and (min-width: ${size.tablet}px) {
    height: 90px;
    min-width: unset;
  }
`
const PreviewImage = styled.img`
  max-height: 116px;
  @media only screen and (max-width: ${size.tablet}px) {
    transform: translatex(calc(58px - 50%)) rotate(90deg) translateX(25px);
  }
  @media only screen and (min-width: ${size.tablet}px) {
    max-height: 90px;
    margin: 0 auto;
  }
`
const DefaultCartImage = styled(DefaultImage)`
  max-height: 116px;
  @media only screen and (max-width: ${size.tablet}px) {
    transform: translatex(calc(58px - 50%)) rotate(90deg) translateX(25px);
  }
  @media only screen and (min-width: ${size.tablet}px) {
    max-height: 90px;
    margin: 0 auto;
  }
`
const Manufacturer = styled.div`
  font-size: 8px;
  overflow: hidden;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.family.regular};
  color: ${(props) => props.theme.colors.white};

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.small};
  }
`
const Name = styled.div`
  font-size: ${(props) => props.theme.fonts.size.extraSmall};
  line-height: ${(props) => props.theme.fonts.size.medium};
  font-weight: 600;
  font-family: ${(props) => props.theme.fonts.family.regular};
  color: ${(props) => props.theme.colors.white};

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.medium};
  }
`
const CounterButtonContainer = styled.div`
  flex-grow: 2;
`
const PriceContainer = styled(SpacedFlexBox)`
  flex-grow: 1;
  min-width
  align-items: center;
  justify-content: flex-end;
  position: relative;
  right: 15px;
`

const CloseBtn = styled(CloseButton)`
  height: 10px;
  width: 10px;
  align-self: flex-end;
  right: 4px;
  position: relative;

  @media only screen and (min-width: ${size.tablet}px) {
    height: 12px;
    width: 12px;
  }

  svg {
    fill: ${(props) => props.theme.colors.lightGrey};
  }
`

interface CardProps {
  orderedItem: OrderedItem
  onRemoveClick: Function
  onAmountChange: Function
}

export const CartCard: FunctionComponent<CardProps> = ({
  orderedItem,
  onRemoveClick,
  onAmountChange,
}) => {
  const { settings } = React.useContext(SettingsContext)
  const discount = orderedItem.item.discount
  const price = orderedItem.item.price
  const fetch = React.useContext(HttpContext)

  const imageQuery = useQuery(
    "item-image-" + orderedItem.item.id,
    () => fetch?.item.getDefaultImage(orderedItem.item.id),
    CACHED_QUERY_PARAMS
  )

  const [image, setImage] = React.useState<ItemImage | null>(
    imageQuery.data?.id ? imageQuery.data : null
  )

  React.useEffect(() => {
    if (imageQuery.data && imageQuery.data.id) {
      setImage(imageQuery.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageQuery.data])

  return (
    <>
      <MediaQuery maxWidth={size.tablet}>
        <CartCardComponent>
          <CartCardInformation className="cart-card-mobile-information">
            <ImageContainer>
              {image ? (
                <PreviewImage src={image.image} />
              ) : imageQuery.isFetching ? (
                <ImageLoadingSkeleton />
              ) : (
                <DefaultCartImage />
              )}
            </ImageContainer>
            <CloseBtn onClick={() => onRemoveClick(orderedItem.item.id)}>
              <Close />
            </CloseBtn>
          </CartCardInformation>
          <FlexColumnBox className="cart-card-desktop-information">
            <CartCardInformation>
              <FlexColumnBox style={{ rowGap: 4 }}>
                <Manufacturer>{orderedItem.item.manufacturerName}</Manufacturer>
                <Name className="cart-title">{orderedItem.item.name}</Name>
                <GoldDividerStyle className="cart-card-devider" />
              </FlexColumnBox>
              <PriceContainer>
                <Price>
                  {getFormattedPrice(lowestPrice(price, discount), settings)}
                </Price>
              </PriceContainer>
            </CartCardInformation>
          </FlexColumnBox>
          <CartCardAction>
            <CounterButtonContainer>
              <CounterButton
                val={orderedItem.orderedAmount}
                min={1}
                limit={orderedItem.item.amount}
                onChange={(amount: number) => onAmountChange(amount)}
              />
            </CounterButtonContainer>
          </CartCardAction>
        </CartCardComponent>
      </MediaQuery>
      <MediaQuery minWidth={size.tablet}>
        <CartCardComponent>
          <CloseBtn onClick={() => onRemoveClick(orderedItem.item.id)}>
            <Close />
          </CloseBtn>
          <FlexBox>
            <ImageContainer>
              {image ? (
                <PreviewImage src={image.image} />
              ) : imageQuery.isFetching ? (
                <ImageLoadingSkeleton />
              ) : (
                <DefaultCartImage />
              )}
            </ImageContainer>
            <FlexColumnBox className="cart-card-desktop-information">
              <CartCardInformation>
                <FlexColumnBox style={{ rowGap: 4 }}>
                  <Manufacturer>
                    {orderedItem.item.manufacturerName}
                  </Manufacturer>
                  <Name className="cart-title">{orderedItem.item.name}</Name>
                  {/* <GoldDividerStyle className="cart-card-devider" /> */}
                </FlexColumnBox>
                <PriceContainer>
                  <Price>
                    {getFormattedPrice(lowestPrice(price, discount), settings)}
                  </Price>
                </PriceContainer>
              </CartCardInformation>
              <CartCardAction>
                <CounterButtonContainer>
                  <CounterButton
                    val={orderedItem.orderedAmount}
                    min={1}
                    limit={orderedItem.item.amount}
                    onChange={(amount: number) => onAmountChange(amount)}
                  />
                </CounterButtonContainer>
              </CartCardAction>
            </FlexColumnBox>
          </FlexBox>
        </CartCardComponent>
      </MediaQuery>
    </>
  )
}
