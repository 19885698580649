import React from "react"
import styled from "styled-components"
import { size } from "../styles/device"
import { Trans } from "@lingui/macro"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${size.tablet}px) {
    min-height: calc(100% - ${(props) => props.theme.menu.height.mobile});
  }

  @media only screen and (min-width: ${size.tablet}px) {
    min-height: calc(100% - ${(props) => props.theme.menu.height.desktop});
  }
`

const Text = styled.div`
  margin-top: 8px;
`

interface ErrorBoundaryProps {
  children?: React.ReactElement | React.ReactElement[]
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Text>
            <Trans>
              We can't load the page right now. Please try to go back and open
              this page again.
            </Trans>
          </Text>
        </Container>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
