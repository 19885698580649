import React, { useEffect } from "react"
import {
  useLocation,
  Routes as RouterRoutes,
  Route,
  Navigate,
} from "react-router-dom"
import { Shop, Home, Cart, QRPayment, ResetPassword, Region } from "./pages"
import { LoadingFallback } from "./components"
import {
  useAppSelector,
  selectAgeConfirmed,
  useAppDispatch,
  dissmissAlert,
  selectAlert,
  selectUserSignIn,
} from "./state"
import { Alert } from "./types"
import { Trans } from "@lingui/macro"
import ErrorBoundary from "./components/ErrorBundary"

// Common pages
const ItemPage = React.lazy(() => import("./pages/Item"))
const Checkout = React.lazy(() => import("./pages/Checkout"))
const Confirmation = React.lazy(() => import("./pages/Confirmation"))
const ChangeAddress = React.lazy(() => import("./pages/ChangeAddress"))
const OrderCompleted = React.lazy(() => import("./pages/OrderCompleted"))
// Login related
const Login = React.lazy(() => import("./pages/login/Login"))
const Register = React.lazy(() => import("./pages/login/Register"))
const ForgotPassword = React.lazy(() => import("./pages/login/ForgotPassword"))
// Profile related
const Profile = React.lazy(() => import("./pages/profile/Profile"))
const ProfileEdit = React.lazy(() => import("./pages/profile/ProfileEdit"))
const ProfileChangePassword = React.lazy(
  () => import("./pages/profile/ProfileChangePassword")
)
const ProfileAddress = React.lazy(
  () => import("./pages/profile/ProfileAddress")
)
const ProfileInvoice = React.lazy(
  () => import("./pages/profile/ProfileInvoice")
)
const ProfileOrders = React.lazy(() => import("./pages/profile/ProfileOrders"))
const ProfileOrder = React.lazy(() => import("./pages/profile/ProfileOrder"))
// Marketing related
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"))
const GeneralTerms = React.lazy(() => import("./pages/GeneralTerms"))
const Payment = React.lazy(() => import("./pages/Payment"))
const AboutUs = React.lazy(() => import("./pages/AboutUs"))
const Contacts = React.lazy(() => import("./pages/Contacts"))
const Delivery = React.lazy(() => import("./pages/Delivery"))

export interface routePath {
  path: string
  menu?: string | React.ReactElement
}

interface routesPathInterface {
  [props: string]: routePath
}

export const routes: routesPathInterface = {
  list: {
    path: "/shop",
    menu: <Trans>Shop</Trans>,
  },
  cart: {
    path: "/cart",
    menu: <Trans>Cart</Trans>,
  },
  profile: {
    path: "/profile",
  },
  editProfile: {
    path: "/profile/edit",
  },
  changePassword: {
    path: "/profile/password",
  },
  editAddress: {
    path: "/profile/address",
  },
  editInvoice: {
    path: "/profile/invoice",
  },
  selectAddress: {
    path: "/select-address/:page",
  },
  orders: {
    path: "/profile/orders/:pageId",
  },
  order: {
    path: "/profile/order/:id",
  },
  item: {
    path: "/item/:id",
  },
  checkout: {
    path: "/checkout",
  },
  orderCompleted: {
    path: "/order-completed",
  },
  confirmation: {
    path: "/confirmation",
  },
  generalTerms: {
    path: "/general-terms-conditions",
  },
  privacyPolicy: {
    path: "/privacy-policy",
  },
  payment: {
    path: "/payment",
  },
  delivery: {
    path: "/delivery",
  },
  qrPayment: {
    path: "/payment-qr",
  },
  aboutUs: {
    path: "/about-us",
  },
  contacts: {
    path: "/contacts",
  },
  login: {
    path: "/login",
  },
  register: {
    path: "/register",
  },
  forgotPassword: {
    path: "/forgot-password",
  },
  resetPassword: {
    path: "/reset-password/:token/:email",
  },
  region: {
    path: "/regions",
  },
  home: {
    path: "/",
    menu: <Trans>Home</Trans>,
  },
  redirect: {
    path: "/*",
    menu: <Trans>Home</Trans>,
  },
}

export const Routes = () => {
  const isAgeConfirmed = useAppSelector(selectAgeConfirmed)
  const isSignedIn = useAppSelector(selectUserSignIn)
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<LoadingFallback />}>
        <RouterRoutes>
          <Route path={routes.region.path} element={<Region />} />
          <Route
            path={routes.list.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <Shop />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.cart.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <Cart />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.profile.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <SignedInRouterGuard isSignedIn={isSignedIn}>
                  <Profile />
                </SignedInRouterGuard>
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.editProfile.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <SignedInRouterGuard isSignedIn={isSignedIn}>
                  <ProfileEdit />
                </SignedInRouterGuard>
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.changePassword.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <SignedInRouterGuard isSignedIn={isSignedIn}>
                  <ProfileChangePassword />
                </SignedInRouterGuard>
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.editAddress.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <SignedInRouterGuard isSignedIn={isSignedIn}>
                  <ProfileAddress />
                </SignedInRouterGuard>
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.editInvoice.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <SignedInRouterGuard isSignedIn={isSignedIn}>
                  <ProfileInvoice />
                </SignedInRouterGuard>
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.selectAddress.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <SignedInRouterGuard isSignedIn={isSignedIn}>
                  <ChangeAddress />
                </SignedInRouterGuard>
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.orders.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <SignedInRouterGuard isSignedIn={isSignedIn}>
                  <ProfileOrders />
                </SignedInRouterGuard>
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.order.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <SignedInRouterGuard isSignedIn={isSignedIn}>
                  <ProfileOrder />
                </SignedInRouterGuard>
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.item.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <ItemPage />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.checkout.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <Checkout />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.orderCompleted.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <OrderCompleted />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.confirmation.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <Confirmation />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.generalTerms.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <GeneralTerms />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.privacyPolicy.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <PrivacyPolicy />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.payment.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <Payment />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.delivery.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <Delivery />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.qrPayment.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <QRPayment />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.aboutUs.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <AboutUs />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.contacts.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <Contacts />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.login.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <Login />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.register.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <Register />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.forgotPassword.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <ForgotPassword />
              </AgeConfirmRouterGuard>
            }
          />
          <Route
            path={routes.resetPassword.path}
            element={
              <AgeConfirmRouterGuard isAgeConfirmed={isAgeConfirmed}>
                <ResetPassword />
              </AgeConfirmRouterGuard>
            }
          />
          <Route path={routes.home.path} element={<Home />} />
          <Route path="*" element={<Home />} />
        </RouterRoutes>
      </React.Suspense>
    </ErrorBoundary>
  )
}

export const Menus = Object.values(routes).filter((route) => route.menu)

interface AgeConfirmRouterGuardProps {
  isAgeConfirmed: boolean
  children?: any
}

export const AgeConfirmRouterGuard: React.FunctionComponent<
  AgeConfirmRouterGuardProps
> = ({ isAgeConfirmed, children }) => {
  if (!isAgeConfirmed) {
    return <Navigate to="/" replace />
  }

  return children || <></>
}

interface SignedInRouterGuardProps {
  isSignedIn: boolean
  children?: any
}

export const SignedInRouterGuard: React.FunctionComponent<
  SignedInRouterGuardProps
> = ({ isSignedIn, children }) => {
  if (!isSignedIn) {
    return <Navigate to="/" replace />
  }

  return children || <></>
}

export const ScrollToTop = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const alert: Alert | null = useAppSelector(selectAlert)

  useEffect(() => {
    // Auto scroll to top
    location.pathname !== routes.list.path && window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    // Clear alerts on page change
    alert !== null && dispatch(dissmissAlert())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return <></>
}
