import React, { FunctionComponent, useState, useEffect } from "react"
import styled from "styled-components"
import { ReactComponent as MinusIcon } from "./../assets/icons/minus.svg"
import { ReactComponent as PlusIcon } from "./../assets/icons/plus.svg"
import { FlexBox, Button, size, FlexColumnBox } from "../styles"
import { GoldDivider } from "./GoldDivider"

const Container = styled(FlexColumnBox)`
  width: 100%;
  row-gap: 4px;
`

const CounterContainer = styled(FlexBox)`
  width: 100%;
  max-width: 68px;
  height: 14px;

  @media only screen and (min-width: ${size.tablet}px) {
    height: 16px;
  }
`
const CounterInput = styled.input`
  border: none;
  text-align: center;
  width: 100%;
  min-width: 20px;
  background: transparent;
  height: 14px;
  color: ${(props) => props.theme.colors.DARK_GOLD};

  @media only screen and (min-width: ${size.tablet}px) {
    height: 16px;
    font-size: ${(props) => props.theme.fonts.size.medium};
    min-width: 40px;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Minus = styled(MinusIcon)`
  height: 10px;
  width: 10px;

  @media only screen and (min-width: ${size.tablet}px) {
    width: 12px;
    height: 12px;
  }
`
const Plus = styled(PlusIcon)`
  height: 10px;
  width: 10px;

  @media only screen and (min-width: ${size.tablet}px) {
    width: 12px;
    height: 12px;
  }
`
const IconButton = styled(Button)`
  border: none;
  background: transparent;
  height: 14px;
  padding: 0;

  @media only screen and (min-width: ${size.tablet}px) {
    height: 20px;
  }
`

interface CounterButtonProps {
  onChange: Function
  val?: number
  limit?: number
  min?: number
}

export const CounterButton: FunctionComponent<CounterButtonProps> = ({
  onChange,
  limit,
  val,
  min,
}) => {
  const minValue = min || 0
  const [value, setValue] = useState(val || 0)
  const decrement = () => {
    value !== minValue && setValue(value - 1)
  }
  const increment = () => {
    value !== limit && setValue(value + 1)
  }

  const handleChange = (order: number) => {
    setValue(order)
  }

  useEffect(() => {
    onChange(value)
  }, [onChange, value])

  return (
    <Container>
      <GoldDivider />
      <CounterContainer>
        <IconButton onClick={() => decrement()}>
          <Minus />
        </IconButton>
        <CounterInput
          type="number"
          value={value}
          min={minValue}
          max={limit}
          onChange={(event) => handleChange(parseInt(event.target.value))}
        />
        <IconButton onClick={() => increment()}>
          <Plus />
        </IconButton>
      </CounterContainer>
      <GoldDivider />
    </Container>
  )
}
