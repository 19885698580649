import { AxiosInstance } from "axios"
import {
  CustomerDetails,
  AlertType,
  emptyCustomerDetails,
  Address,
  VatAddress,
} from "./../types"
import { notifyUser } from "./../state"

export const createCustomerFetch = (
  axios: AxiosInstance,
  dispatch: Function
) => {
  return {
    save: async (customer: CustomerDetails) => {
      return await axios
        .put(`/customer/${customer.id}`, customer)
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during saving customer. Please try it later.",
            })
          )
          console.error("Customer saving error.", e)
          throw e
        })
    },
    get: (id: number) =>
      axios
        .get<CustomerDetails>(`/customer/${id}`)
        .then((response: any) => {
          return response.data as CustomerDetails
        })
        .catch((e: any) => {
          if (e?.response?.status === 500) {
            //TODO
          } else {
            dispatch(
              notifyUser({
                type: AlertType.ERROR,
                message:
                  "Error happened during loading customer. Please try it later.",
              })
            )
          }
          console.error("Customer loading error.", e)
          return emptyCustomerDetails as CustomerDetails
        }),
    getAddress: (userId: number) =>
      axios
        .get<Address>(`/customer/${userId}/default-address`)
        .then((response: any) => {
          return response.data as Address
        })
        .catch((e: any) => {
          if (e.response.status === 500) {
            //TODO
          } else {
            dispatch(
              notifyUser({
                type: AlertType.ERROR,
                message: "Error happened during loading default address.",
              })
            )
          }
          return null
        }),
    getInvoice: (userId: number) =>
      axios
        .get<VatAddress>(`/customer/${userId}/default-invoice`)
        .then((response: any) => {
          return response.data as VatAddress
        })
        .catch((e: any) => {
          if (e?.response?.status === 500) {
            //TODO
          } else {
            dispatch(
              notifyUser({
                type: AlertType.ERROR,
                message: "Error happened during loading default invoice.",
              })
            )
          }
          return null
        }),
    setPreferredRegion: (regionId: number) =>
      axios.get(`/customer/preferred-region/${regionId}/`),
  }
}
