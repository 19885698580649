import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { Footer, Menu, Notification } from "./components"
import styled, { ThemeProvider } from "styled-components"
import { Routes, ScrollToTop } from "./Routes"
import { theme, size, formattedText } from "./styles"
import { HttpProvider, LoadDefaultData, SettingsProvider } from "./services"
import { Construction } from "./pages"
import { i18n } from "@lingui/core"
import { I18nProvider } from "@lingui/react"
import { messages as enMessages } from "./locales/en/messages"
import { messages as huMessages } from "./locales/hu/messages"
import { messages as zhMessages } from "./locales/zh/messages"
import { en, hu, zh } from "make-plural/plurals"
import { QueryClient, QueryClientProvider } from "react-query"
import { persistQueryClient } from "react-query/persistQueryClient-experimental"
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental"
import { ReactQueryDevtools } from "react-query/devtools"

const PlaceHolder = styled.div`
  height: ${(props) => props.theme.menu.height.mobile};

  @media only screen and (min-width: ${size.tablet}px) {
    height: ${(props) => props.theme.menu.height.desktop};
  }
`

const MobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  @media only screen and (min-width: ${size.tablet}px) {
    display: ${process?.env?.REACT_APP_DESKTOP_AVAILABLE ? "block" : "none"};
  }
  ${formattedText}
`

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
})
persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
})

const App = () => {
  React.useEffect(() => {
    i18n.loadLocaleData({
      en: { plurals: en },
      hu: { plurals: hu },
      cn: { plurals: zh },
      zh: { plurals: zh },
      hk: { plurals: zh },
    })
    i18n.load({
      en: enMessages,
      hu: huMessages,
      cn: zhMessages,
      zh: zhMessages,
      hk: zhMessages,
    })
    i18n.activate("en")
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={theme}>
        <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
          <MobileWrapper>
            <Router>
              <HttpProvider>
                <SettingsProvider>
                  <PlaceHolder />
                  <ScrollToTop />
                  <LoadDefaultData />
                  <Notification />
                  <Routes />
                  <Footer />
                  <Menu />
                </SettingsProvider>
              </HttpProvider>
            </Router>
          </MobileWrapper>
          <Construction />
        </I18nProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
