import React, { useState, FunctionComponent } from "react"
import styled from "styled-components"
import { FlexColumnBox, FlexBox } from "../styles"
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
} from "@mui/material"
import { Trans } from "@lingui/macro"
import { GoldButton } from "./GoldButton"

const AgeWarning = styled.div`
  color: ${(props) => props.theme.colors.red};
  font-weight: 600;
  text-align: center;
`

const Dialog = styled(MuiDialog)`
  .MuiDialog-paper {
    background-color: ${(props) => props.theme.colors.DARK_GREY};
  }
`
const DialogTitle = styled(MuiDialogTitle)`
  text-align: center;
  color: ${(props) => props.theme.colors.DARK_GOLD};
`
const ContentTitle = styled.div`
  font-size: ${(props) => props.theme.fonts.size.extraGreat};
  text-align: center;
`
const ContentSubTitle = styled.div`
  font-size: ${(props) => props.theme.fonts.size.small};
  text-align: center;
`
const DialogContent = styled(MuiDialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  color: ${(props) => props.theme.colors.DARK_GOLD};
`
const ButtonBox = styled(FlexBox)`
  column-gap: 8px;
  justify-content: center;
`

interface AgeConfirmationDialogProps {
  onConfirm: Function
}

export const AgeConfirmationDialog: FunctionComponent<
  AgeConfirmationDialogProps
> = ({ onConfirm }) => {
  const [isOpen, setOpen] = useState(true)
  const [showAgeWarning, setShowAgeWarning] = useState(false)

  const handleConfirm = () => {
    onConfirm()
    setOpen(false)
  }

  const handleDecline = () => {
    setShowAgeWarning(true)
  }

  return (
    <Dialog open={isOpen} disableEscapeKeyDown={true}>
      <DialogTitle>
        <Trans>WineLab Webshop</Trans>
      </DialogTitle>
      <DialogContent>
        <div>
          <ContentTitle>
            <Trans>This website is for visitors older than 18 years.</Trans>
          </ContentTitle>
          {showAgeWarning && (
            <AgeWarning>
              <Trans>Unfortunately you are under the allowed age.</Trans>
            </AgeWarning>
          )}
        </div>
        <FlexColumnBox>
          <ContentSubTitle>
            <Trans>Are you older than 18?</Trans>
          </ContentSubTitle>
          <ButtonBox>
            <GoldButton onClick={() => handleConfirm()}>
              <Trans>Yes</Trans>
            </GoldButton>
            <GoldButton onClick={() => handleDecline()}>
              <Trans>No</Trans>
            </GoldButton>
          </ButtonBox>
        </FlexColumnBox>
      </DialogContent>
    </Dialog>
  )
}
