import React from "react"
import { AgeConfirmationDialog } from "../components"
import styled from "styled-components"
import { FlexColumnBox, size } from "../styles"
import Carousel from "react-gallery-carousel"
import "react-gallery-carousel/dist/index.css"
import { MarketingImage } from "../types"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"
import "swiper/swiper-bundle.css"
import { Parallax, Autoplay, Navigation } from "swiper"
import "swiper/modules/parallax/parallax.min.css"
import "swiper/modules/autoplay/autoplay.min.css"
import "swiper/modules/navigation/navigation.min.css"
import { v4 as uuid } from "uuid"

const fadeAnimation = `
  > p {
    display: block;
    // animation: fadein 6s;

    // &:nth-child(1) {
    //   animation: fadein 0.9s;
    // }
    // &:nth-child(2) {
    //   animation: fadein 5s;
    // }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const HomeContainer = styled(FlexColumnBox)`
  width: 100%;
  height: calc(100% - ${(props) => props.theme.menu.height.mobile});
  justify-content: flex-start;
  position: relative;
  background-color: ${(props) => props.theme.colors.DARK_GREY};

  @media only screen and (min-width: ${size.tablet}px) {
    height: calc(100% - ${(props) => props.theme.menu.height.desktop});
  }

  .gallery {
    position: relative;
    background: transparent;

    > div:nth-child(1) ul > li img {
      object-fit: contain;
    }

    > div:nth-child(2) {
      position: absolute;
      bottom: 66px;
      width: 100%;

      > ul {
        justify-content: center;
        column-gap: 8px;
        > li[role="button"] {
          height: 14px;
          width: 14px;
          min-width: 14px;
          border-radius: 50%;
          background: ${(props) => props.theme.colors.DARK_GOLD};
          > img {
            display: none;
          }
        }
      }
    }
  }

  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      color: ${(props) => props.theme.colors.DARK_GOLD};
      transform: scale(0.75);
    }
  }
`

const Welcome = styled.div`
  font-size: ${(props) => props.theme.fonts.size.great};
  max-height: unset;
  color: ${(props) => props.theme.colors.DARK_GOLD};
  font-family: ${(props) => props.theme.fonts.family.regular};
  margin: 0 16px;
  width: calc(100% - 32px);

  img {
    width: 100% !important;
  }
`

const WelcomeDesktop = styled(Welcome)`
  margin: 80px 74px;
  font-size: ${(props) => props.theme.fonts.size.extraGreat};
  line-height: 30px;
  width: calc(100% - 148px);

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.medium};
    line-height: 24px;
  }
`

const GalleryMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 72px;
  font-size: 40px;
  font-family: ${(props) => props.theme.fonts.family.regular};
  color: ${(props) => props.theme.colors.DARK_GOLD};
  text-transform: uppercase;
`

const DesktopGallery = styled(Swiper)`
  width: 100%;
  height: 100%;
  max-height: 992px;

  .swiper-slide-active .winelab-gallery-message {
    ${fadeAnimation}
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: ${(props) => props.theme.colors.DARK_GOLD};
  }
`

const DesktopSlide = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

interface GalleryMessageProps {
  content: any
}

export const GalleryMessage: React.FunctionComponent<GalleryMessageProps> = ({
  content,
}) => {
  return (
    <GalleryMessageBox
      className="winelab-gallery-message"
      dangerouslySetInnerHTML={{
        __html: content || "",
      }}
    ></GalleryMessageBox>
  )
}

interface MobileHomeProps {
  navigate: Function
  images: MarketingImage[]
  message: string
  isAgeConfirmed: boolean
  onConfirm: Function
}

export const MobileHome: React.FunctionComponent<MobileHomeProps> = ({
  navigate,
  images,
  message,
  isAgeConfirmed,
  onConfirm,
}) => {
  return (
    <>
      <HomeContainer onClick={() => navigate("/shop")}>
        <Carousel
          images={images}
          style={{ maxHeight: "100%", maxWidth: "100%" }}
          hasLeftButton={false}
          hasRightButton={false}
          hasSizeButton={false}
          hasMediaButton={false}
          hasIndexBoard={false}
          hasThumbnails={true}
          isAutoPlaying={true}
          autoPlayInterval={5000}
          isLoop={true}
          className="gallery"
          onTap={() => navigate("/shop")}
        />
      </HomeContainer>
      <Welcome
        className="formatted-text"
        dangerouslySetInnerHTML={{
          __html: message || "",
        }}
      />
      {!isAgeConfirmed && (
        <AgeConfirmationDialog onConfirm={() => onConfirm()} />
      )}
    </>
  )
}

interface DesktopHomeProps {
  images: MarketingImage[]
  defaultLanguage: boolean
  message: string
  navigate: Function
  isAgeConfirmed: boolean
  onConfirm: Function
}

export const DesktopHome: React.FunctionComponent<DesktopHomeProps> = ({
  images,
  defaultLanguage,
  message,
  navigate,
  isAgeConfirmed,
  onConfirm,
}) => {
  return (
    <>
      <HomeContainer>
        <DesktopGallery
          spaceBetween={0}
          slidesPerView={1}
          modules={[Parallax, Autoplay, Navigation]}
          loop={true}
          navigation={true}
          parallax={true}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
        >
          {images.map((image) => (
            <SwiperSlide key={uuid()}>
              <DesktopSlide
                style={{ backgroundImage: `url(${image.src})` }}
                onClick={() => navigate("/shop")}
              >
                <GalleryMessage
                  content={
                    defaultLanguage
                      ? image?.defaultMessage
                      : image?.secondaryMessage
                  }
                />
              </DesktopSlide>
            </SwiperSlide>
          ))}
        </DesktopGallery>
      </HomeContainer>
      <WelcomeDesktop
        className="formatted-text"
        dangerouslySetInnerHTML={{
          __html: message || "",
        }}
      />
      {!isAgeConfirmed && (
        <AgeConfirmationDialog onConfirm={() => onConfirm()} />
      )}
    </>
  )
}
