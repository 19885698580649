import React from "react"
import { SettingsContext, setDefaultLanguage } from "../services"
import {
  useAppDispatch,
  setDefaultLanguage as setDefaultLanguageStore,
  selectLanguages,
  useAppSelector,
  selectActiveLanguage,
  initActiveLanguage,
} from "./../state"
import styled from "styled-components"
import { useLingui } from "@lingui/react"
import { size } from "./../styles"
import MediaQuery from "react-responsive"
import { Language as LanguageType } from "../types"

const StyledLanguage = styled.div`
  width: ${(props) => props.theme.menu.iconSize};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.DARK_GOLD};
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.family.regular};
  padding-top: 2px;
  padding-left: 0;

  @media only screen and (min-width: ${size.tablet}px) {
    color: ${(props) => props.theme.colors.DARK_GOLD};
  }
`

const StyledLanguageDesktop = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;

  > .desktop {
    padding: 0px 4px;
    justify-content: center;
    cursor: pointer;
    font-size: ${(props) => props.theme.fonts.size.extraSmall};
  }

  > .desktop-devider {
    cursor: pointer;
    color: ${(props) => props.theme.colors.DARK_GOLD};
  }
`

export const Language = () => {
  const { settings } = React.useContext(SettingsContext)
  const dispatch = useAppDispatch()
  const { i18n } = useLingui()
  const languages = useAppSelector(selectLanguages)
  const activeLanguage = useAppSelector(selectActiveLanguage)
  const [language, setLanguage] = React.useState<LanguageType | null>(
    activeLanguage
  )

  React.useEffect(() => {
    if (language && language.id !== activeLanguage?.id) {
      const usingDefault =
        language.languageCode.toLocaleLowerCase() ===
        settings.defaultLanguage.toLocaleLowerCase()
      dispatch(setDefaultLanguageStore(usingDefault))
      setDefaultLanguage(usingDefault)
      dispatch(initActiveLanguage(language))
      i18n.activate(language.languageCode.toLocaleLowerCase())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <>
      <MediaQuery maxWidth={size.tablet}>
        {languages.length === 2 ? (
          language?.languageCode === languages[0].languageCode ? (
            <StyledLanguage onClick={() => setLanguage(languages[1])}>
              {languages[0].languageCode.toLocaleUpperCase()}
            </StyledLanguage>
          ) : (
            <StyledLanguage onClick={() => setLanguage(languages[0])}>
              {languages[1].languageCode.toLocaleUpperCase()}
            </StyledLanguage>
          )
        ) : (
          <></>
        )}
      </MediaQuery>
      <MediaQuery minWidth={size.tablet}>
        <StyledLanguageDesktop>
          {languages.length === 2 ? (
            <>
              <StyledLanguage
                className="desktop"
                onClick={() => setLanguage(languages[0])}
              >
                {languages[0].languageCode.toLocaleUpperCase()}
              </StyledLanguage>
              <div className="desktop-devider">|</div>
              <StyledLanguage
                className="desktop"
                onClick={() => setLanguage(languages[1])}
              >
                {languages[1].languageCode.toLocaleUpperCase()}
              </StyledLanguage>
            </>
          ) : (
            <></>
          )}
        </StyledLanguageDesktop>
      </MediaQuery>
    </>
  )
}
