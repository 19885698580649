import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CustomerDetails, Address, VatAddress } from "./../types"
import { AppState } from "./store"

export interface UserState {
  signedIn: boolean
  user: CustomerDetails | null
  address: Address | null
  invoice: VatAddress | null
}

export const initialUserState: UserState = {
  signedIn: false,
  user: null,
  address: null,
  invoice: null,
}

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    signIn: (state, action: PayloadAction<CustomerDetails>) => {
      return { ...state, signedIn: true, user: action.payload }
    },
    signOut: (state) => {
      return { ...state, signedIn: false, user: null }
    },
    registerAddress: (state, action: PayloadAction<Address>) => {
      return { ...state, address: action.payload }
    },
    registerInvoice: (state, action: PayloadAction<VatAddress>) => {
      return { ...state, invoice: action.payload }
    },
  },
})

export const { signIn, signOut, registerAddress, registerInvoice } =
  userSlice.actions

export const selectUserSlice = (state: AppState) => state.user
export const selectUserSignIn = (state: AppState) => state.user.signedIn
export const selectUser = (state: AppState) => state.user.user
export const selectAddress = (state: AppState) => state.user.address
export const selectInvoice = (state: AppState) => state.user.invoice
export const selectCanApplyCategoryPromotion = (state: AppState) =>
  state.user.signedIn &&
  state.user.user &&
  state.user.user.category &&
  state.user.user.category.promotion &&
  state.user.user.category?.promotion > 0

export default userSlice.reducer
