export enum DeliveryType {
  SHIP = "ship",
  PICK_UP = "pickup",
  PERSONAL = "personal",
}

export interface Delivery {
  id: number
  defaultName: string
  secondaryName: string
  defaultDescription: string
  secondaryDescription: string
  deliveryFee: number
  active: boolean
  isArchived: boolean
  isDefault: boolean
  type: DeliveryType
  freeAbove: number
}

export const emptyDelivery: Partial<Delivery> = {
  defaultName: "Personal",
  secondaryName: "Personal",
  defaultDescription: "",
  secondaryDescription: "",
  deliveryFee: 0,
  isDefault: true,
  type: DeliveryType.PERSONAL,
}
