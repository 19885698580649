import React, { FunctionComponent, useState, useCallback } from "react"
import { header, SpacedFlexBox } from "./../styles"
import styled from "styled-components"
import { ReactComponent as LogoIcon } from "./../assets/logo.svg"
import { useNavigate } from "react-router-dom"
import { routes, Menus } from "./../Routes"
import { Hamburger, HamburgerMenu } from "."
import { size } from "./../styles"
import { useAppSelector, selectNoItemsInOrder } from "./../state"

const HeaderContainer = styled(SpacedFlexBox)`
  ${header}
  align-items: center;
  z-index: 1;
  &.fixed {
    position: fixed;
    top: 0;
    width: 100%;
  }
  display: none;
  @media only screen and (min-width: ${size.tablet}px) {
    display: block;
  }
`

const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: ${(props) => props.theme.fonts.size.extraGreat};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white};
  position: absolute;
  left: 50%;
`

const Logo = styled(LogoIcon)`
  height: inherit;
`

const HeaderActions = styled.div`
  position: relative;
  right: 8px;
`

interface HeaderProps {
  title?: string
  onClick?: Function
  buttonVisible?: boolean
  className?: string
}

interface HeaderComponentProps extends HeaderProps {
  onLogoClick?: Function
  itemsInCart: number
}

export const HeaderComponent: FunctionComponent<HeaderComponentProps> = ({
  title,
  onClick,
  buttonVisible,
  className,
  onLogoClick,
  itemsInCart,
}) => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)
  const handleClick = useCallback(
    (isOpened: boolean) => {
      setIsMobileMenuOpened(isOpened)
      onClick && onClick()
    },
    [setIsMobileMenuOpened, onClick]
  )
  return (
    <HeaderContainer className={className}>
      <Logo
        onClick={() => {
          onLogoClick && onLogoClick()
        }}
      />
      <HeaderTitle>{title}</HeaderTitle>
      <HeaderActions>
        {buttonVisible && (
          <>
            <Hamburger
              isOpened={isMobileMenuOpened}
              onClick={(isOpened: boolean) => handleClick(isOpened)}
            />
            <HamburgerMenu
              isOpened={isMobileMenuOpened}
              items={Menus}
              onClick={() => {
                handleClick(false)
              }}
              itemsInCart={itemsInCart}
            />
          </>
        )}
      </HeaderActions>
    </HeaderContainer>
  )
}

export const Header: FunctionComponent<HeaderProps> = ({
  title,
  className,
}) => {
  const navigate = useNavigate()
  const itemsInCart = useAppSelector(selectNoItemsInOrder)

  const handleClick = () => {}

  return (
    <HeaderComponent
      className={"fixed " + className}
      onClick={() => handleClick()}
      title={title}
      buttonVisible={window.innerWidth < size.tablet}
      onLogoClick={() => navigate(routes.home.path)}
      itemsInCart={itemsInCart}
    />
  )
}
