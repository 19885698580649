import styled, { keyframes } from "styled-components"
import { size } from "."

export const pulse = keyframes`
    0% {
        transform: scale(1, 1);
    }
    25% {
        opacity: 0.75;
    }
    50% {
        transform: scale(1.1);
        opacity: 1;
    }
    75% {
        opacity: 0.75;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
`

export const Button = styled.button`
  background: ${(props) => props.theme.colors.yellow};
  text-transform: uppercase;
  height: 34px;
  max-width: ${(props) => props.theme.form.buttonMaxWidth};
  font-size: ${(props) => props.theme.fonts.size.small};
  color: ${(props) => props.theme.colors.white};
  border: none;

  &:not(:disabled),
  &:not([disabled]) {
    cursor: pointer;
  }

  :active:not(:disabled):not([disabled]) {
    animation: ${pulse} 0.5s ease 1;
  }

  &:disabled,
  &[disabled] {
    background: ${(props) => props.theme.colors.lightGrey};
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  &.full-width {
    width: 100%;
    max-width: 100%;
  }

  &.margin {
    margin: 16px 0;
  }

  &.default {
    background: ${(props) => props.theme.colors.solidBlack};
    border: 1px solid ${(props) => props.theme.colors.DARK_GOLD};
    border-radius: 2px;
    color: ${(props) => props.theme.colors.white};
  }

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.extraSmall};
    height: 30px;
  }

  &.padding {
    padding: 0 16px;
  }

  &.min-width {
    min-width: 200px;
  }
`

export const buttonIcon = `
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const CloseButton = styled(Button)`
  ${buttonIcon}
  border: none;
  background: transparent;
`
