import { PriceSettings, Settings } from "../types"

export const lowestPrice = (price: number, discount: number): number =>
  Math.round((discount && discount < price ? discount : price) * 100) / 100

const reverseString = (input: string | String): string =>
  input.split("").reverse().join("")

export const formatPrice = (
  price: string | number,
  priceSettings: PriceSettings | string,
  prefix?: boolean,
  valutaVisible?: boolean
): string => {
  // eslint-disable-next-line no-new-wrappers
  const priceParts = new String(price).split(".")
  const reversedPrice: string = reverseString(priceParts[0])
  const formattedReversedPriceArray: RegExpMatchArray | null =
    reversedPrice.match(/.{1,3}/g)
  const formattedReversedPrice: string = formattedReversedPriceArray
    ? formattedReversedPriceArray.join(" ")
    : ""
  let formattedPrice: string = reverseString(formattedReversedPrice)
  if (priceParts.length === 2) {
    formattedPrice = formattedPrice + "." + priceParts[1]
  }
  let finalPrefix = false
  let finalValuta = ""
  if (typeof prefix === "boolean" && typeof priceSettings === "string") {
    finalPrefix = prefix
    finalValuta = priceSettings
  } else {
    finalPrefix = (priceSettings as PriceSettings).prefix
    finalValuta = (priceSettings as PriceSettings).valuta
  }
  const isValutaVisible = valutaVisible === true || valutaVisible === undefined
  return finalPrefix
    ? isValutaVisible
      ? `${finalValuta} ${formattedPrice}`
      : `${formattedPrice}`
    : isValutaVisible
    ? `${formattedPrice} ${finalValuta}`
    : `${formattedPrice}`
}

export const roundPrice = (price: number): number =>
  Math.round(price * 100) / 100

export const floorPrice = (price: number): number =>
  Math.floor(price * 100) / 100

export const getFormattedPrice = (
  price: number,
  settings: Settings
): string => {
  return formatPrice(
    price,
    settings.valuta,
    settings.valutaPrefix,
    settings.valutaVisibility
  )
}
