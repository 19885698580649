import React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { Payment as PaymentType, Delivery } from "./../types"
import styled from "styled-components"
import { v4 as uuid } from "uuid"
import { SettingsContext } from "./../services"
import { device } from "./../styles"
import { ReactComponent as ArrowIcon } from "../assets/icons/nyil_le.svg"
import { ReactComponent as SelectedIcon } from "../assets/icons/chb_k2.svg"
import { ReactComponent as UnSelectedIcon } from "../assets/icons/chb_k1.svg"

const Selected = styled(SelectedIcon)`
  height: 18px;
  width: 18px;
  padding-right: 12px;
`
const UnSelected = styled(UnSelectedIcon)`
  height: 18px;
  width: 18px;
  padding-right: 12px;
`
const Arrow = styled(ArrowIcon)`
  height: 18px;
  width: 18px;
  transform: rotate(180deg);
`

const PaymentContainer = styled.div`
  .MuiPaper-root.MuiPaper-elevation {
    margin: 0;
    background-color: transparent;
    box-shadow: unset;
    color: ${(props) => props.theme.colors.DARK_GOLD};
  }
  .MuiButtonBase-root.MuiAccordionSummary-root {
    min-height: 32px;
    padding: 0;

    .MuiAccordionSummary-content {
      margin: 10px 0;
      align-items: center;
      p {
        font-size: ${(props) => props.theme.fonts.size.small};
        margin: 0;
      }

      @media ${device.tablet} {
        margin: 5px 0;
        font-size: ${(props) => props.theme.fonts.size.small};
      }
    }
  }
  .MuiCollapse-wrapperInner div {
    font-size: ${(props) => props.theme.fonts.size.small};

    @media ${device.tablet} {
      font-size: ${(props) => props.theme.fonts.size.extraSmall};
    }
  }
`

const PaymentDescription = styled.div`
  padding: 2px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.DARK_GOLD};

  @media ${device.tablet} {
    line-height: 18px;
  }
`

const PaymentTitle = styled(AccordionSummary)`
  color: ${(props) => props.theme.colors.DARK_GOLD};

  .MuiAccordionSummary-content {
    color: ${(props) => props.theme.colors.DARK_GOLD};
  }
`

interface PaymentProps {
  payment: PaymentType | Delivery
  payments: PaymentType[] | Delivery[]
  onPaymentChange: Function
}

export const Payment: React.FunctionComponent<PaymentProps> = ({
  payment,
  payments,
  onPaymentChange,
}) => {
  const [paymentMethod, setPaymentMethod] = React.useState(payment)
  const { defaultLanguage } = React.useContext(SettingsContext)

  React.useEffect(() => {
    if (!payment.id) {
      payments.forEach((payment: PaymentType | Delivery) => {
        if (payment.isDefault) {
          setPaymentMethod(payment)
          onPaymentChange(payment)
          return
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments])

  return (
    <PaymentContainer>
      {payments.map((paymentOption: PaymentType | Delivery) => {
        return (
          <Accordion
            expanded={paymentMethod.id === paymentOption.id}
            onChange={() => {
              setPaymentMethod(paymentOption)
              onPaymentChange(paymentOption)
            }}
            key={uuid()}
          >
            <PaymentTitle
              expandIcon={
                paymentMethod.id === paymentOption.id ? <Arrow /> : <></>
              }
            >
              {paymentMethod.id === paymentOption.id ? (
                <Selected />
              ) : (
                <UnSelected />
              )}
              <p>
                {defaultLanguage
                  ? paymentOption.defaultName
                  : paymentOption.secondaryName}
              </p>
            </PaymentTitle>
            <AccordionDetails>
              <PaymentDescription
                className="formatted-text"
                dangerouslySetInnerHTML={{
                  __html: defaultLanguage
                    ? paymentOption.defaultDescription
                    : paymentOption.secondaryDescription || "",
                }}
              />
            </AccordionDetails>
          </Accordion>
        )
      })}
    </PaymentContainer>
  )
}
