export const theme = {
  colors: {
    red: "#bf0d0d",
    lightRed: "#ee1f36",
    green: "#3c9700",
    blue: "",
    white: "#fff",
    iconGrey: "#B4B2B3",
    lightGrey: "#737171",
    summaryGrey: "#FAFAFA",
    grey: "#747270",
    solidBlack: "#231f20",
    black: "#1e2022",
    yellow: "#CCCD41",
    darkYellow: "#A7A94D",
    DARK_BLACK: "#060404",
    DARK_GREY: "#252020",
    DARK_GOLD: "#EECC6D",
    GOLD: "#FCE590",
    LINK_BLUE: "#3388C5",
  },
  gradients: {
    GOLD: "linear-gradient(to right, #C3993C, #F0D178, #FDE690, #F0D178, #C3993C)",
    GREY: "linear-gradient(to right, #696667, #555154, #423D3D)",
    GREY_BUTTON:
      "linear-gradient(to right,#676567,#676567,#615E62,#433E3F,#393431,#312D2B,#312D2B)",
  },
  backgrounds: {},
  paddings: {},
  margins: {},
  borders: {
    border: {
      default: "1px solid #77838f",
      green: "1px solid #3c9700",
      black: "1px solid #1e2022",
      grey: "1px solid #BBBBBB",
    },
    radius: "4px",
  },
  fonts: {
    size: {
      extraSmall: "12px",
      small: "14px",
      medium: "16px",
      great: "18px",
      extraGreat: "20px",
      huge: "24px",
    },
    family: {
      roboto: `"Roboto", "Arial", sans-serif`,
      regular: `"RobotoRegular", "Arial", sans-serif`,
      bold: `"RobotoBold", "Arial", sans-serif`,
    },
  },
  form: {
    inputMaxWidth: "215px",
    buttonMaxWidth: "215px",
  },
  menu: {
    height: {
      mobile: "68px",
      desktop: "50px",
    },
    iconSize: "28px",
    drawerIcon: "12px",
  },
  window: {
    desktop: "1245px",
  },
}
