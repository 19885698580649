import { AxiosInstance } from "axios"
import { QuantityDiscount } from "./../types"

export const createQuantityDiscountFetch = (
  axios: AxiosInstance,
  dispatch: Function
) => {
  return {
    list: () =>
      axios
        .get<QuantityDiscount[]>("/quantity-discount/")
        .then((response: any) => {
          return response.data as QuantityDiscount[]
        }),
  }
}
