import { useState, useEffect } from "react"
import { LoginUser } from "./../types"
import CryptoJS from "crypto-js"

const STORAGE_LOGIN_EMAIL = "winelab-customer-login-email"
const STORAGE_LOGIN_PASSWORD = "winelab-customer-login-remember"
const STORAGE_LOGIN_REMEMBER = "winelab-customer-login-password"

const TOP_MARGIN_66 = "margin-top: 66px;"

const getKey = (): string => {
  const key = CryptoJS.SHA256(TOP_MARGIN_66).toString()
  return key
}

const encryptData = (data: string): string => {
  return CryptoJS.AES.encrypt(data, getKey()).toString()
}

const decryptData = (data: string): string => {
  return CryptoJS.AES.decrypt(data, getKey()).toString(CryptoJS.enc.Utf8)
}

export const useRememberUser = () => {
  const [loginUser, setLoginUser] = useState<LoginUser | null>(null)

  useEffect(() => {
    const email = localStorage.getItem(STORAGE_LOGIN_EMAIL)
    const remember = localStorage.getItem(STORAGE_LOGIN_PASSWORD)
    const password = localStorage.getItem(STORAGE_LOGIN_REMEMBER)

    if (remember === "true" && email && password) {
      setLoginUser({
        email: decryptData(email),
        password: decryptData(password),
        remember: true,
      } as LoginUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return loginUser
}

export const setRememberUser = async (rememberUser: LoginUser) => {
  if (rememberUser.remember) {
    localStorage.setItem(STORAGE_LOGIN_EMAIL, encryptData(rememberUser.email))
    localStorage.setItem(
      STORAGE_LOGIN_PASSWORD,
      rememberUser.remember.toString()
    )
    localStorage.setItem(
      STORAGE_LOGIN_REMEMBER,
      encryptData(rememberUser.password)
    )
  }
}

export const clearRememberUser = () => {
  localStorage.removeItem(STORAGE_LOGIN_EMAIL)
  localStorage.removeItem(STORAGE_LOGIN_PASSWORD)
  localStorage.removeItem(STORAGE_LOGIN_REMEMBER)
}
