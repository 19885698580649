export interface PasswordChange {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

export const emptyPasswordChange = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
}
