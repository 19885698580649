export const size = {
  mobile: 375,
  tablet: 768,
  laptop: 1024,
}

export const device = {
  mobile: `(max-width: ${size.tablet}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  laptop: `(min-width: ${size.laptop}px)`,
}
