import React from "react"
import styled from "styled-components"
import { ResponsiveFlexBox, FlexBox, size } from "../styles"
import { ReactComponent as LogoIcon } from "./../assets/profile-icons/2_menu.svg"
import { ReactComponent as FacebookIcon } from "./../assets/facebook.svg"
import { ReactComponent as InstagramIcon } from "./../assets/instagram.svg"
import PaymentIcon from "./../assets/payment.png"
import { Link } from "react-router-dom"
import { routes } from "./../Routes"
import { Trans } from "@lingui/macro"
import {
  useAppSelector,
  selectPayments,
  selectSelectedRegion,
} from "./../state"
import { Payment } from "./../types"
import MediaQuery from "react-responsive"
import { PureAppButtons } from "./MobileAvailableDialog"
import { GoldDivider } from "./GoldDivider"

const FooterContainer = styled(ResponsiveFlexBox)`
  background-color: ${(props) => props.theme.colors.DARK_BLACK};
  padding: 8px 24px;
  row-gap: 16px;

  * {
    text-align: center;
  }
`
const FooterContainerDesktop = styled(FooterContainer)`
  flex-direction: column;
  row-gap: 16px;
`
const Logo = styled(LogoIcon)`
  height: 25px;
  width: 200px;

  &.large {
    height: 40px;
    align-self: center;
  }
`
const MenuContainer = styled(FlexBox)`
  column-gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
  color: ${(props) => props.theme.colors.DARK_GOLD};
  font-size: ${(props) => props.theme.fonts.size.extraSmall};

  &.center {
    align-items: center;
    column-gap: 16px;
  }

  &.large {
    column-gap: 16px;
  }
`
const Menu = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.DARK_GOLD};
  font-size: ${(props) => props.theme.fonts.size.extraSmall};
  font-weight: bold;
  flex-grow: 1;

  &.large {
    font-size: ${(props) => props.theme.fonts.size.small};
    flex-grow: unset;

    > svg {
      width: 30px;
      height: 30px;
    }
  }
`
const PaymentContainer = styled.div``
const CopyRightsContainer = styled.div`
  color: ${(props) => props.theme.colors.DARK_GOLD};
  font-size: ${(props) => props.theme.fonts.size.extraSmall};
  line-height: 20px;
  font-weight: 900;

  &.large {
    font-size: ${(props) => props.theme.fonts.size.small};
  }
`

const Divider = styled(GoldDivider)`
  margin-top: -9px;

  @media only screen and (min-width: ${size.tablet}px) {
    margin-top: -12px;
  }
`

export const Footer = () => {
  const [cardPaymentAvailabe, setCardPaymentAvailable] = React.useState(false)
  const payments: Payment[] = useAppSelector(selectPayments)
  const selectedRegion = useAppSelector(selectSelectedRegion)

  React.useEffect(() => {
    if (!payments) {
      return
    } else if (payments.length === 0) {
      setCardPaymentAvailable(true)
    } else {
      payments.forEach((payment: Payment) => {
        if (payment.type === "card" && payment.active && !payment.isArchived) {
          setCardPaymentAvailable(true)
          return
        }
      })
    }
  }, [payments])

  return (
    <>
      <MediaQuery maxWidth={size.tablet}>
        <FooterContainer>
          <Divider />
          <Logo />
          <MenuContainer className="center">
            <Menu
              to={routes.home.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <FacebookIcon />
            </Menu>
            <Menu
              to={routes.home.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <InstagramIcon />
            </Menu>
          </MenuContainer>
          <MenuContainer>
            <Menu
              to={routes.aboutUs.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>About us</Trans>
            </Menu>
            |
            <Menu
              to={routes.contacts.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>Contacts</Trans>
            </Menu>
            |
            <Menu
              to={routes.payment.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>Payment methods</Trans>
            </Menu>
          </MenuContainer>
          <MenuContainer>
            <Menu
              to={routes.delivery.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>Delivery</Trans>
            </Menu>
            |
            <Menu
              to={routes.generalTerms.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>General terms and conditions</Trans>
            </Menu>
            |
            <Menu
              to={routes.privacyPolicy.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>Privacy Policy</Trans>
            </Menu>
          </MenuContainer>
          <PureAppButtons onClick={() => {}} />
          <PaymentContainer>
            {cardPaymentAvailabe && (
              <img src={PaymentIcon} alt="Payment options" />
            )}
          </PaymentContainer>
          <CopyRightsContainer>
            © {new Date().getFullYear()} – <Trans>All Right Reserved.</Trans>
          </CopyRightsContainer>
        </FooterContainer>
      </MediaQuery>
      <MediaQuery minWidth={size.tablet}>
        <FooterContainerDesktop>
          <Divider />
          <Logo className="large" />
          <MenuContainer className="center">
            <Menu
              className="large"
              to={routes.home.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <FacebookIcon />
            </Menu>
            <Menu
              className="large"
              to={routes.home.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <InstagramIcon />
            </Menu>
          </MenuContainer>
          <MenuContainer className="large">
            <Menu
              className="large"
              to={routes.aboutUs.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>About us</Trans>
            </Menu>
            <Menu
              className="large"
              to={routes.contacts.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>Contacts</Trans>
            </Menu>
            <Menu
              className="large"
              to={routes.payment.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>Payment methods</Trans>
            </Menu>
            <Menu
              className="large"
              to={routes.delivery.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>Delivery</Trans>
            </Menu>
            <Menu
              className="large"
              to={routes.generalTerms.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>General terms and conditions</Trans>
            </Menu>
            <Menu
              className="large"
              to={routes.privacyPolicy.path}
              onClick={(e) => !selectedRegion && e.preventDefault()}
            >
              <Trans>Privacy Policy</Trans>
            </Menu>
          </MenuContainer>
          <PureAppButtons onClick={() => {}} isHorizontal={true} />
          <PaymentContainer>
            {cardPaymentAvailabe && (
              <img src={PaymentIcon} alt="Payment options" />
            )}
          </PaymentContainer>

          <CopyRightsContainer className="large">
            © {new Date().getFullYear()} – <Trans>All Right Reserved.</Trans>
          </CopyRightsContainer>
        </FooterContainerDesktop>
      </MediaQuery>
    </>
  )
}
