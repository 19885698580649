import React from "react"
import { Trans } from "@lingui/macro"
import { Order as OrderType, MarketingMessage } from "./../types"
import { FlexColumnBox, FlexBox, menuHeight, size } from "./../styles"
import { SettingsContext, getFormattedPrice, HttpContext } from "./../services"
import {
  useAppSelector,
  selectOrder,
  useAppDispatch,
  saveOrderThunk,
  selectOrderRegistration,
} from "../state"
import { AddButton } from "./../components"
import styled from "styled-components"
import { useNavigate } from "react-router"
import { routes } from "./../Routes"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"

const PageContainer = styled(FlexColumnBox)`
  padding: 8px 27px;
  min-height: calc(100% - ${(props) => props.theme.menu.height.mobile});
  color: ${(props) => props.theme.colors.lightGrey};
  position: relative;

  .MuiTypography-h6 {
    font-family: "RobotoRegular", "sans-serif";
  }

  @media only screen and (min-width: ${size.tablet}px) {
    max-width: 1000px;
    width: 80%;
    margin: 0 auto;
    min-height: calc(100% - ${(props) => props.theme.menu.height.desktop});
  }
`

const Footer = styled(FlexBox)`
  ${menuHeight}
  position: absolute;
  bottom: 0;
  width: calc(100% - 54px);
  justify-content: space-evenly;
  align-items: center;
`

export const QRPayment: React.FunctionComponent = () => {
  const { settings, defaultLanguage } = React.useContext(SettingsContext)
  const fetch = React.useContext(HttpContext)
  const navigate = useNavigate()
  const [qrContent, setQrContent] = React.useState<MarketingMessage | null>(
    null
  )
  const order: OrderType = useAppSelector(selectOrder)
  const dispatch = useAppDispatch()
  const registration = useAppSelector(selectOrderRegistration)

  React.useEffect(() => {
    fetch?.marketing
      .getPaymentForOrder(order.payment.type)
      .then((message: MarketingMessage) => {
        setQrContent(message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch])

  const onFinish = () => {
    if (fetch?.order) {
      const orderMethod = registration.isSignUp
        ? fetch.profile.registerAndOrder
        : fetch.order.save
      const finalOrder = registration.isSignUp
        ? { ...order, ...registration }
        : order

      dispatch(saveOrderThunk(orderMethod, finalOrder))
      setTimeout(() => navigate(routes.orderCompleted.path), 800)
    }
  }

  return (
    <PageContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" gutterBottom>
            <Trans>Total amount: </Trans>{" "}
            {getFormattedPrice(
              order.delivery.deliveryFee + order.totalPrice,
              settings
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          className="formatted-text"
          dangerouslySetInnerHTML={{
            __html:
              (defaultLanguage
                ? qrContent?.defaultMessage
                : qrContent?.secondaryMessage) || "",
          }}
        ></Grid>
      </Grid>
      <Footer>
        <AddButton onClick={() => onFinish()}>
          <Trans>Finish</Trans>
        </AddButton>
      </Footer>
    </PageContainer>
  )
}
