import React from "react"
import Alert from "@mui/material/Alert"
import styled from "styled-components"
import { size } from "./../styles"
import {
  useAppSelector,
  selectAlert,
  useAppDispatch,
  dissmissAlert,
} from "./../state"
import { Alert as AlertType } from "./../types"

const CustomAlert = styled(Alert)`
  margin: 0 auto;

  @media only screen and (min-width: ${size.tablet}px) {
    width: calc(${(props) => props.theme.window.desktop} - 92px);
  }
  @media only screen and (max-width: ${size.tablet}px) {
    width: ${(props) => props.theme.window.mobile};
  }
`

export const Notification = () => {
  const alert: AlertType | null = useAppSelector(selectAlert)
  const dispatch = useAppDispatch()

  return alert !== null ? (
    <CustomAlert
      icon={false}
      severity={alert?.type}
      onClose={() => dispatch(dissmissAlert())}
    >
      {alert.message}
    </CustomAlert>
  ) : (
    <></>
  )
}
