import { AxiosInstance } from "axios"
import { AlertType, Address, emptyAddress } from "./../types"
import { notifyUser } from "./../state"

export const createAddressFetch = (
  axios: AxiosInstance,
  dispatch: Function
) => {
  return {
    save: async (address: Address) => {
      return await axios.post(`/address/`, address).catch((e: any) => {
        dispatch(
          notifyUser({
            type: AlertType.ERROR,
            message:
              "Error happened during saving address. Please try it later.",
          })
        )
        console.error("Address saving error.", e)
        throw e
      })
    },
    update: async (address: Address) => {
      return await axios.put(`/address/`, address).catch((e: any) => {
        dispatch(
          notifyUser({
            type: AlertType.ERROR,
            message:
              "Error happened during updating address. Please try it later.",
          })
        )
        console.error("Address updating error.", e)
        throw e
      })
    },
    get: (id: number) =>
      axios
        .get<Address>(`/address/${id}`)
        .then((response: any) => {
          return response.data as Address
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading address. Please try it later.",
            })
          )
          console.error("Address loading error.", e)
          return emptyAddress as Address
        }),
    list: () =>
      axios
        .get<Address[]>("/address/")
        .then((response: any) => {
          return response.data as Address[]
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading addresses. Please try it later.",
            })
          )
          console.error("Addresses loading error.", e)
          return []
        }),
    delete: (id: number) =>
      axios
        .delete(`/address/${id}`)
        .then((response: any) => {
          return response.data
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during deleting address. Please try it later.",
            })
          )
          console.error("Addresses deleting error.", e)
          return {}
        }),
  }
}
