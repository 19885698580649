import React from "react"
import {
  useAppSelector,
  useAppDispatch,
  selectItemForPreview,
  addItemToOrder,
  selectBadges,
  selectUserSignIn,
  selectSelectedRegion,
} from "./../state"
import styled from "styled-components"
import { v4 as uuid } from "uuid"
import { Card } from "../components"
import {
  ResponsiveFlexBox,
  device,
  FlexColumnBox,
  size,
  theme,
} from "./../styles"
import { HttpContext } from "./../services"
import { ItemPreview } from "../types"
import CircularProgress from "@mui/material/CircularProgress"
import { useNavigate } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { useQuery } from "react-query"

const ItemsContainer = styled(ResponsiveFlexBox)`
  column-gap: 24px;
  row-gap: 24px;
  flex-wrap: wrap;
  padding: 12px 38px;
  min-height: calc(100% - ${(props) => props.theme.menu.height.mobile});

  @media ${device.tablet} {
    justify-content: flex-start;
    margin: 0 auto;
    width: ${(props) => props.theme.window.desktop};
    column-gap: 16px;
    row-gap: 16px;
    min-height: calc(100% - ${(props) => props.theme.menu.height.desktop});
  }
`

const EmptyPage = styled(FlexColumnBox)`
  min-height: calc(100% - ${(props) => props.theme.menu.height.mobile});
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.DARK_GOLD};

  @media only screen and (min-width: ${size.tablet}px) {
    min-height: calc(100% - ${(props) => props.theme.menu.height.desktop});
  }
`

export const Shop: React.FunctionComponent<{ className?: string }> = (
  props
) => {
  const fetch = React.useContext(HttpContext)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const activeRegion = useAppSelector(selectSelectedRegion)
  const badges = useAppSelector(selectBadges)
  const isSignedIn = useAppSelector(selectUserSignIn)

  const { isLoading, data, refetch } = useQuery(
    ["items", activeRegion?.id || 1],
    () => fetch?.region.listItems(activeRegion?.id || 1),
    { enabled: false }
  )
  const [items, setItems] = React.useState<ItemPreview[]>(data || [])

  const addToCard = (id: number) => {
    const item = items.find((item) => item.id === id)
    item && dispatch(addItemToOrder(item))
  }

  const onItemClick = (item: ItemPreview) => {
    dispatch(selectItemForPreview(item))
    navigate("/item/" + item.id)
  }

  React.useEffect(() => {
    data && JSON.stringify(data) !== JSON.stringify(items) && setItems(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  React.useEffect(() => {
    activeRegion?.id && refetch()
  }, [refetch, isSignedIn, activeRegion?.id])

  return (
    <>
      {items.length > 0 ? (
        <ItemsContainer className={props.className}>
          {items.map((item) => (
            <Card
              key={uuid()}
              item={item}
              onAddToCard={addToCard}
              badges={badges}
              onClick={() => onItemClick(item)}
            />
          ))}
        </ItemsContainer>
      ) : isLoading ? (
        <EmptyPage>
          <CircularProgress sx={{ color: theme.colors.DARK_GOLD }} />
        </EmptyPage>
      ) : (
        <EmptyPage>
          <p>
            <Trans>At the moment, we are out of products.</Trans>
          </p>
          <p>
            <Trans>Please come back later!</Trans>
          </p>
        </EmptyPage>
      )}
    </>
  )
}
