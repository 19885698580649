import styled from "styled-components"
import { FlexColumnBox, size, FlexBox, Devider } from "."

export const Container = styled(FlexColumnBox)`
  position: relative;

  @media only screen and (max-width: ${size.tablet}px) {
    min-height: calc(100% - ${(props) => props.theme.menu.height.mobile});
    padding: 12px 38px;
  }

  @media only screen and (min-width: ${size.tablet}px) {
    min-height: calc(100% - ${(props) => props.theme.menu.height.desktop});
    width: ${(props) => props.theme.window.desktop};
    margin: 0 auto;
    padding: 16px 30px;
  }

  .MuiTypography-root {
    font-family: ${(props) => props.theme.fonts.family.regular};
    color: ${(props) => props.theme.colors.white};

    &.title {
      text-transform: uppercase;
      font-size: ${(props) => props.theme.fonts.size.medium};
    }

    &.subtitle {
      font-size: ${(props) => props.theme.fonts.size.extraSmall};
      line-height: ${(props) => props.theme.fonts.size.medium};
    }
  }
`

export const Row = styled(FlexBox)`
  column-gap: 16px;

  @media only screen and (max-width: ${size.tablet}px) {
    flex-direction: column;
  }
`

export const TitleRow = styled(Row)`
  margin: 16px 0 0;
`

export const Divider = styled(Devider)`
  margin: 0 0 16px;
`

export const ProfileEditRow = styled(Row)`
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${size.tablet}px) {
    .line {
      flex-direction: column;
      width: 100%;
    }
  }

  @media only screen and (min-width: ${size.tablet}px) {
    min-width: 500px;
    max-width: 800px;
  }
`
