import React from "react"
import styled from "styled-components"
import { FlexColumnBox, SpacedFlexBox, size } from "./../styles"
import { SettingsContext, lowestPrice, getFormattedPrice } from "./../services"
import { Trans } from "@lingui/macro"

const Price = styled.div`
  font-weight: 900;
  font-size: ${(props) => props.theme.fonts.size.great};
  font-family: ${(props) => props.theme.fonts.family.regular};
  color: ${(props) => props.theme.colors.white};

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.great};
  }
`
const StrikedPrice = styled(Price)`
  text-align: end;
  color: ${(props) => props.theme.colors.DARK_GOLD};
  font-weight: normal;
  position: relative;
  padding: 0 2px;
  font-size: ${(props) => props.theme.fonts.size.small};

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.great};
  }

  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: ${(props) => props.theme.colors.DARK_GOLD};
    transform: rotate(0deg);
  }
`
const PrimaryAmount = styled.div`
  width: 100%;
  text-align: start;
  color: ${(props) => props.theme.colors.GOLD};
  font-size: ${(props) => props.theme.fonts.size.extraSmall};
  font-family: ${(props) => props.theme.fonts.family.regular};
  height: 14px;

  @media only screen and (min-width: ${size.tablet}px) {
    text-align: center;
    font-size: ${(props) => props.theme.fonts.size.small};
  }
`
const PriceBox = styled(FlexColumnBox)`
  row-gap: 4px;
  @media only screen and (min-width: ${size.tablet}px) {
    flex-direction: column-reverse;
  }
`

const ResponsiveSpacedFlexBox = styled(SpacedFlexBox)`
  @media only screen and (min-width: ${size.tablet}px) {
    justify-content: center;
    column-gap: 40px;
  }

  align-items: flex-end;
`

interface PriceProps {
  price: number
  discount: number
  amount: number
}

export const PriceComponent: React.FunctionComponent<PriceProps> = ({
  price,
  discount,
  amount,
}) => {
  const { settings } = React.useContext(SettingsContext)
  const sellingPrice = lowestPrice(price, discount)
  const amountWarningVisible = amount <= 50
  return (
    <PriceBox className="price">
      <ResponsiveSpacedFlexBox className="price-row">
        <Price>{getFormattedPrice(sellingPrice, settings)}</Price>
        {discount && discount < price ? (
          <StrikedPrice>{getFormattedPrice(price, settings)}</StrikedPrice>
        ) : (
          <></>
        )}
      </ResponsiveSpacedFlexBox>
      <ResponsiveSpacedFlexBox>
        <PrimaryAmount className="price-amount">
          {amountWarningVisible && (
            <>
              {amount} <Trans>Bottles left</Trans>
            </>
          )}
        </PrimaryAmount>
      </ResponsiveSpacedFlexBox>
    </PriceBox>
  )
}
