import styled from "styled-components"
import { device } from "."

export const flex = `
    display: flex;
    row-gap: 8px;
    box-sizing: border-box;
`

export const FlexBox = styled.div`
  ${flex}
`

export const SpacedFlexBox = styled(FlexBox)`
  justify-content: space-between;
`

export const EvenlyFlexBox = styled(FlexBox)`
  justify-content: space-evenly;
`

export const FlexColumnBox = styled(FlexBox)`
  flex-direction: column;
`

export const RelativeFlexColumnBox = styled(FlexColumnBox)`
  position: relative;
`

export const FlexInverseColumnBox = styled(FlexColumnBox)`
  justify-content: flex-end;
`

export const ResponsiveFlexBox = styled(FlexBox)`
  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
  }
`
