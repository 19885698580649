export interface LoginUser {
  email: string
  password: string
  remember: boolean
}

export const emptyLoginUser = {
  email: "",
  password: "",
  remember: false,
} as LoginUser

export interface ResetPassword {
  newPassword: string
  confirmPassword: string
  token: string
}

export const emptyResetPassword = {
  newPassword: "",
  confirmPassword: "",
  token: "",
} as ResetPassword
