import { AxiosInstance } from "axios"
import { ItemPreview, AlertType, ItemImage } from "./../types"
import { notifyUser } from "./../state"

export const createItemFetch = (axios: AxiosInstance, dispatch: Function) => {
  return {
    list: () =>
      axios
        .get<ItemPreview[]>("/item/")
        .then((response: any) => {
          return response.data as ItemPreview[]
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading items. Please try it later.",
            })
          )
          console.error("Item loading error.", e)
          throw e
        }),
    get: (id: number) =>
      axios
        .get<ItemPreview>("/item/" + id)
        .then((response: any) => {
          return response.data as ItemPreview
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading item. Please try it later.",
            })
          )
          console.error("Item loading error.", e)
          throw e
        }),
    getDefaultImage: (id: number) =>
      axios
        .get<ItemImage>(`/item/${id}/image`)
        .then((response: any) => {
          return response.data as ItemImage
        })
        .catch((e: any) => {
          dispatch(
            notifyUser({
              type: AlertType.ERROR,
              message:
                "Error happened during loading images for item. Please try it later.",
            })
          )
          console.error("Item image loading error.", e)
          throw e
        }),
  }
}
