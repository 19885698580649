import React, { FunctionComponent } from "react"
import styled from "styled-components"

const WineContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  background: linear-gradient(
    54deg,
    rgba(111, 16, 16, 0.9) 0%,
    rgba(255, 97, 97, 0.9) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  display: flex;
  align-items: center;
`

const SVG = styled.svg`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 250px;

  .parallax {
    > use {
      animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
      &:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
      }
      &:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
      }
      &:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
      }
      &:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
      }
    }
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
`

export const Wine: FunctionComponent<{ className?: string }> = (props) => {
  return (
    <WineContainer className={props.className}>
      <SVG
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="parallax">
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(191, 13, 13, 0.7)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(191, 13, 13, 0.5)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(191, 13, 13, 0.4)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="7"
            fill="rgba(207, 13, 13, 0.5)"
          />
        </g>
      </SVG>
      {props.children}
    </WineContainer>
  )
}
