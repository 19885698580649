export interface Language {
  id: number
  language: string
  languageCode: string
  archived: boolean
}

export const emptyLanguage: Partial<Language> = {
  language: "",
  languageCode: "",
  archived: false,
}

export interface RegionPreview {
  id: number
  region: string
  regionEnglish: string
  regionCode: string
  active: boolean
  default: boolean
  url: string
}

export const emptyRegionPreview: RegionPreview = {
  id: 0,
  region: "",
  regionEnglish: "",
  regionCode: "",
  active: false,
  default: false,
  url: "",
}

export interface Region extends RegionPreview {
  languages: Language[]
  url: string
}

export const emptyRegion: Region = {
  ...emptyRegionPreview,
  languages: [],
  url: "",
}
