import styled from "styled-components"
import { FlexColumnBox, SpacedFlexBox, size } from "."
import FormHelperText from "@mui/material/FormHelperText"

const menuContainer = `
    position: fixed;
    bottom: 0;
    justify-content: space-around;
    width: 100%;
    background: white;
`

export const menuHeight = `
    height: 48px;
`
export const header = `
    border-bottom: 1px solid #231f20;
    background-color: #231f20;
    ${menuHeight}
`
export const footer = `
    border-top: 1px solid grey;
    ${menuHeight}
`
export const footerContainer = `
    ${menuContainer}
    ${footer}
    box-shadow: rgb(0 0 0 / 30%) 0px -25px 36px -28px inset;
`

export const StaticContainer = styled(FlexColumnBox)`
  row-gap: unset;
  min-height: calc(100% - ${(props) => props.theme.menu.height.desktop});
  width: 1245px;
  margin: 0 auto;

  @media only screen and (max-width: ${size.tablet}px) {
    padding: 12px 38px;
    min-height: calc(100% - ${(props) => props.theme.menu.height.mobile});
    width: unset;
    max-width: 100%;
  }

  @media only screen and (min-width: ${size.tablet}px) {
    padding: 8px 20px;
  }

  h1,
  h2,
  h3 {
    margin: 8px 0;
  }

  h1 {
    color: ${(props) => props.theme.colors.white};
  }

  h3 {
    margin-bottom: 10px;
  }

  p {
    margin: 2px 0 8px 0;
    text-align: justify;
    line-height: 20px;
  }
`

export const Devider = styled.div`
  width: 28px;
  height: 1px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};

  @media only screen and (min-width: ${size.tablet}px) {
    width: 60px;
    margin: 4px auto;
  }
`

export const GoldDividerStyle = styled.div`
  width: 28px;
  height: 1px;
  background: ${(props) => props.theme.gradients.GOLD};

  @media only screen and (min-width: ${size.tablet}px) {
    width: 60px;
    margin: 4px auto;
  }
`

export const Line = styled(SpacedFlexBox)`
  width: 100%;
  column-gap: 4px;
  > div {
    flex-grow: 1;
  }
`

export const Message = styled.div`
  color: ${(props) => props.theme.colors.DARK_GOLD};

  @media only screen and (min-width: ${size.tablet}px) {
    font-size: ${(props) => props.theme.fonts.size.extraSmall};
  }
`

export const ErrorText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    color: ${(props) => props.theme.colors.red};
  }
`

export const FlexibleDekstopContainer = styled(SpacedFlexBox)`
  width: 100%;
  max-width: ${(props) => props.theme.window.desktop};
  margin: 0 auto;
`
