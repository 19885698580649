import React, { FunctionComponent } from "react"
import { ItemPreview, Badge, ItemImage } from "./../types"
import styled from "styled-components"
import {
  FlexBox,
  FlexColumnBox,
  FlexInverseColumnBox,
  GoldDividerStyle,
  device,
  size,
  RelativeFlexColumnBox,
  DefaultImage,
  PreviewImage,
} from "./../styles"
import { PriceComponent, Badges, ImageLoadingSkeleton, GoldButton } from "."
import { Trans } from "@lingui/macro"
import MediaQuery from "react-responsive"
import { useQuery } from "react-query"
import { HttpContext, CACHED_QUERY_PARAMS } from "./../services"

const ImageContainer = styled(FlexInverseColumnBox)`
  height: 200px;
  width: 35%;
  @media only screen and (min-width: ${size.tablet}px) {
    height: 230px;
    width: 100%;
    margin-bottom: 8px;

    transition: transform 0.3s ease-out;

    &:hover {
      transform: scale(1.08);
    }
  }
`

const CommonScript = styled.div`
  color: ${(props) => props.theme.colors.white};
  overflow: hidden;
`
const Manufacturer = styled(CommonScript)`
  font-size: ${(props) => props.theme.fonts.size.extraSmall};
  font-family: ${(props) => props.theme.fonts.family.regular};
  text-transform: uppercase;

  @media only screen and (min-width: ${size.tablet}px) {
    text-align: center;
    font-size: ${(props) => props.theme.fonts.size.small};
    font-weight: unset;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
const Name = styled(CommonScript)`
  font-size: ${(props) => props.theme.fonts.size.medium};
  font-family: ${(props) => props.theme.fonts.family.regular};
  font-weight: 600;

  @media only screen and (min-width: ${size.tablet}px) {
    text-align: center;
    font-size: ${(props) => props.theme.fonts.size.medium};
    font-weight: unset;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
const Description = styled(Name)`
  margin-top: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 10px;
  max-height: 40px;
  font-size: 10px;
  color: ${(props) => props.theme.colors.DARK_GOLD};
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-weight: unset;
`
const PriceBox = styled.div`
  row-gap: 4px;
`
const CardInformation = styled(FlexColumnBox)`
  justify-content: space-between;
  width: 65%;

  @media only screen and (max-width: ${size.tablet}px) {
    .item-information-badges {
      margin-top: 14px;
    }
  }

  @media only screen and (min-width: ${size.tablet}px) {
    width: 100%;
    height: 115px;
    row-gap: 4px;
  }
`
const CardDescription = styled(FlexColumnBox)`
  row-gap: 2px;

  @media only screen and (min-width: ${size.tablet}px) {
    row-gap: 4px;
  }
`
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
  max-width: 300px;
  color: ${(props) => props.theme.colors.grey};
  padding: 12px;
  row-gap: 30px;

  @media ${device.tablet} {
    flex: 1 0 21%;
    width: 260px;
    max-width: 260px;
    height: 400px;
    justify-content: space-between;
    row-gap: unset;
  }
`
const CardActions = styled(FlexBox)`
  width: 100%;
  height: 40px;

  @media ${device.tablet} {
    height: 30px;
  }
`

const DesktopCardInformationBox = styled(RelativeFlexColumnBox)`
  height: 400px;
`

interface CardInformationProps {
  name: string
  description?: string | null
  itemBadges: Badge[]
  price: number
  discount: number
  amount: number
  manufacturer?: string
  children?: React.ReactNode | React.ReactNode[]
  badges: Badge[]
  postBadge?: boolean
}

export const CardInformationComponent: React.FunctionComponent<
  CardInformationProps
> = ({
  name,
  description,
  itemBadges,
  price,
  discount,
  amount,
  manufacturer,
  children,
  badges,
  postBadge,
}) => {
  return (
    <CardInformation className="item-information">
      <CardDescription>
        <Manufacturer className="item-information-manufacturer">
          {manufacturer}
        </Manufacturer>
        <Name className="item-information-name">{name}</Name>
        <GoldDividerStyle className="item-information-devider" />
        <Badges
          className="item-information-badges"
          itemBadges={itemBadges}
          badges={badges}
        />
        {description && (
          <Description
            dangerouslySetInnerHTML={{
              __html: description || "",
            }}
            className="formatted-text item-information-description"
          />
        )}
        {children}
      </CardDescription>
      <FlexInverseColumnBox className="item-information-price">
        <PriceBox>
          <PriceComponent price={price} discount={discount} amount={amount} />
        </PriceBox>
      </FlexInverseColumnBox>
    </CardInformation>
  )
}

interface CardProps {
  item: ItemPreview
  onAddToCard: Function
  badges: Badge[]
  onClick: Function
}

export const CardComponent: FunctionComponent<CardProps> = ({
  item,
  onAddToCard,
  badges,
  onClick,
}) => {
  const fetch = React.useContext(HttpContext)

  const imageQuery = useQuery(
    "item-image-" + item.id,
    () => fetch?.item.getDefaultImage(item.id),
    CACHED_QUERY_PARAMS
  )
  const [image, setImage] = React.useState<ItemImage | null>(
    imageQuery.data?.id ? imageQuery.data : null
  )

  React.useEffect(() => {
    if (imageQuery.data && imageQuery.data.id) {
      setImage(imageQuery.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageQuery.data])

  return (
    <CardContainer onClick={() => onClick()}>
      <MediaQuery maxWidth={size.tablet}>
        <FlexBox>
          <ImageContainer>
            {image ? (
              <PreviewImage src={image.image} />
            ) : imageQuery.isFetching ? (
              <ImageLoadingSkeleton />
            ) : (
              <DefaultImage />
            )}
          </ImageContainer>
          <CardInformationComponent
            name={item.name}
            description={null}
            manufacturer={item.manufacturer?.name}
            itemBadges={item.badges}
            price={item.price}
            discount={item.discount}
            amount={item.amount}
            badges={badges}
          />
        </FlexBox>
      </MediaQuery>
      <MediaQuery minWidth={size.tablet}>
        <DesktopCardInformationBox>
          <ImageContainer>
            {image ? (
              <PreviewImage src={image.image} />
            ) : imageQuery.isFetching ? (
              <ImageLoadingSkeleton />
            ) : (
              <DefaultImage />
            )}
          </ImageContainer>
          <CardInformationComponent
            name={item.name}
            description={null}
            manufacturer={item.manufacturer?.name}
            itemBadges={item.badges}
            price={item.price}
            discount={item.discount}
            amount={item.amount}
            badges={badges}
          />
        </DesktopCardInformationBox>
      </MediaQuery>
      <CardActions>
        <GoldButton
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation()
            onAddToCard(item.id)
          }}
        >
          <Trans>Add to cart</Trans>
        </GoldButton>
      </CardActions>
    </CardContainer>
  )
}

export const Card = React.memo(CardComponent)
