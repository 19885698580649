import React from "react"
import Popper from "@mui/material/Popper"
import Fade from "@mui/material/Fade"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import { routes } from "./../../Routes"
import Card from "@mui/material/Card"
import styled from "styled-components"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import PersonIcon from "@mui/icons-material/Person"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import LoginIcon from "@mui/icons-material/Login"
import CreateIcon from "@mui/icons-material/Create"
import LogoutIcon from "@mui/icons-material/Logout"
import HouseIcon from "@mui/icons-material/House"
import BusinessIcon from "@mui/icons-material/Business"
import LanguageIcon from "@mui/icons-material/Language"
import { Trans } from "@lingui/macro"
import { GoldDivider } from "../GoldDivider"
import { useLingui } from "@lingui/react"

const Box = styled.div`
  font-family: ${(props) => props.theme.fonts.family.regular};
  color: ${(props) => props.theme.colors.DARK_GOLD};
  font-size: ${(props) => props.theme.fonts.size.small};
  background-color: ${(props) => props.theme.colors.DARK_BLACK};

  .MuiPaper-root {
    background-color: transparent;

    hr.MuiDivider-root {
      border-color: ${(props) => props.theme.colors.DARK_GOLD};
    }
  }

  .MuiListItemText-root span,
  .MuiListItemIcon-root svg {
    font-family: ${(props) => props.theme.fonts.family.regular};
    color: ${(props) => props.theme.colors.DARK_GOLD};
    font-size: ${(props) => props.theme.fonts.size.small};
  }

  .MuiListItemIcon-root svg {
    font-size: ${(props) => props.theme.fonts.size.medium};
  }
`

const Divider = styled(GoldDivider)`
  max-width: 170px;
  margin-top: 8px;
  margin-bottom: 8px;
`

interface WineMenuItemProps {
  onClick: Function
  icon: any
  text: string
}

const WineMenuItem: React.FunctionComponent<WineMenuItemProps> = ({
  onClick,
  icon,
  text,
}) => (
  <MenuItem onClick={() => onClick()}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText>
      <Trans>{text}</Trans>
    </ListItemText>
  </MenuItem>
)

interface ProfilePopupProps {
  open: boolean
  anchorEl: any
  isLogin: boolean
  navigate: Function
  onMouseEnter: Function
  onMouseLeave: Function
  onClose: Function
  onSignOut: Function
}

export const ProfilePopup: React.FunctionComponent<ProfilePopupProps> = ({
  open,
  anchorEl,
  isLogin,
  navigate,
  onMouseEnter,
  onMouseLeave,
  onClose,
  onSignOut,
}) => {
  const { i18n } = useLingui()
  return (
    <Popper
      placement="bottom-end"
      anchorEl={anchorEl}
      transition
      open={open}
      style={{ zIndex: 1300 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box
            onMouseEnter={() => onMouseEnter()}
            onMouseLeave={() => onMouseLeave()}
          >
            <Card>
              <MenuList
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                onKeyDown={() => {}}
              >
                {!isLogin && (
                  <div>
                    <WineMenuItem
                      onClick={() => {
                        navigate(routes.login.path)
                        onClose()
                      }}
                      icon={<LoginIcon />}
                      text={i18n._("Sign in")}
                    />
                    <Divider />
                    <WineMenuItem
                      onClick={() => {
                        navigate(routes.register.path)
                        onClose()
                      }}
                      icon={<CreateIcon />}
                      text={i18n._("Sign up")}
                    />
                    <Divider />
                    <WineMenuItem
                      onClick={() => {
                        navigate(routes.region.path)
                        onClose()
                      }}
                      icon={<LanguageIcon />}
                      text={i18n._("Regions")}
                    />
                  </div>
                )}
                {isLogin && (
                  <div>
                    <WineMenuItem
                      onClick={() => {
                        navigate(routes.profile.path)
                        onClose()
                      }}
                      icon={<PersonIcon />}
                      text={i18n._("Profile")}
                    />
                    <Divider />
                    <WineMenuItem
                      onClick={() => {
                        navigate(routes.editAddress.path)
                        onClose()
                      }}
                      icon={<HouseIcon />}
                      text={i18n._("Addresses")}
                    />
                    <WineMenuItem
                      onClick={() => {
                        navigate(routes.editInvoice.path)
                        onClose()
                      }}
                      icon={<BusinessIcon />}
                      text={i18n._("Invoices")}
                    />
                    <WineMenuItem
                      onClick={() => {
                        navigate("/profile/orders/active")
                        onClose()
                      }}
                      icon={<LocalShippingIcon />}
                      text={i18n._("Orders")}
                    />
                    <Divider />
                    <WineMenuItem
                      onClick={() => {
                        onClose()
                        onSignOut()
                      }}
                      icon={<LogoutIcon />}
                      text={i18n._("Sign out")}
                    />
                    <Divider />
                    <WineMenuItem
                      onClick={() => {
                        navigate(routes.region.path)
                        onClose()
                      }}
                      icon={<LanguageIcon />}
                      text={i18n._("Regions")}
                    />
                  </div>
                )}
              </MenuList>
            </Card>
          </Box>
        </Fade>
      )}
    </Popper>
  )
}

export default ProfilePopup
