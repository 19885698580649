import styled from "styled-components"
import { ReactComponent as WineIcon } from "./../assets/wine.svg"
import { size } from "./../styles"

const ImageStyle = `
  max-width: 100px;
  max-height: 200px;
  margin: 0 auto 0 0;
  
  @media only screen and (min-width: ${size.tablet}px) {
    max-height: 230px;
    margin: 0 auto;
    max-width: unset;
  }
`

export const PreviewImage = styled.img`
  ${ImageStyle}
`

export const DefaultImage = styled(WineIcon)`
  ${ImageStyle}
`
